export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';
export const MESSAGE = 'MESSAGE';
export const OPEN_LOADING = 'OPEN_LOADING';
export const CLOSE_LOADING = 'CLOSE_LOADING';
export const MOVE = 'MOVE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export function modalOpen(title, content, buttons) {
  return {
      type: OPEN, 
      title: title,
      content: content,
      buttons: buttons
  };
}

export function modalClose() {
  return {
      type: CLOSE
  };
}

export function modalMessage(msg) {
  return {
      type: MESSAGE,
      message: msg
  };
}

export function loadingOpen() {
  return {
      type: OPEN_LOADING
  };
}

export function loadingClose() {
  return {
      type: CLOSE_LOADING
  };
}

export function move(item) {
    return {
        type: MOVE, 
        item: item
    };
}

export function login(loginInfo) {
    return {
        type: LOGIN,
        loginInfo: loginInfo
    };
}

export function logout() {
    return {
        type: LOGOUT
    };
}