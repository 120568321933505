import { Form, Input, Tooltip, Icon, Cascader, Select, Row, Col, Checkbox, Button, AutoComplete } from 'antd';
import React from 'react';
import string from '../String';

class AgreementSub2 extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="agreement-wrapper">

      <div className='agreement-title'>{string.agreementSub2Content01}</div>
      <div className='agreement-content'>{string.agreementSub2Content02}</div>
      <div className='agreement-content'>{string.agreementSub2Content03}</div>
      <div className='agreement-content'>{string.agreementSub2Content04}</div>
      <div className='agreement-title'>{string.agreementSub2Content05}</div>
      <div className='agreement-content'>{string.agreementSub2Content06}</div>
      <div className='agreement-content level1'>{string.agreementSub2Content07}</div>
      <div className='agreement-content level1-sub'>{string.agreementSub2Content08}</div>
      <div className='agreement-content level1-sub'>{string.agreementSub2Content09}</div>
      <div className='agreement-content level1-sub'>{string.agreementSub2Content10}</div>
      <div className='agreement-content level1-sub'>{string.agreementSub2Content11}</div>
      <div className='agreement-content level1'>{string.agreementSub2Content12}</div>
      <div className='agreement-title'>{string.agreementSub2Content13}</div>
      <div className='agreement-content'>{string.agreementSub2Content14}</div>
      <div className='agreement-content'>{string.agreementSub2Content15}</div>
      <div className='agreement-content level1'>{string.agreementSub2Content16}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content17}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content18}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content19}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content20}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content21}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content22}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content23}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content24}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content25}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content26}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content27}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content28}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content29}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content30}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content31}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content32}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content33}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content34}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content35}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content36}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub2Content37}</div>
      <div className='agreement-content level1'>{string.agreementSub2Content38}</div>
      <div className='agreement-title'>{string.agreementSub2Content39}</div>
      <div className='agreement-content level1'>{string.agreementSub2Content40}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content41}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content42}</div>
      <div className='agreement-content level1'>{string.agreementSub2Content43}</div>
      <div className='agreement-content level2'>{string.agreementSub2Content44}</div>
      <div className='agreement-content'>{string.agreementSub2Content45}</div>

      </div>
    );
  }
}
export default AgreementSub2;