import React, { Component } from "react";
import string from "../../String";
import { formatNumber } from "../../util";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
export default class Support extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="support-container">
        <div className="container">
          <div className="support-category-title">
            {string.support.mainTitle}
          </div>
          <div className="support-category-title-sub">
            {string.support.mainTitleEng}
          </div>
          <div>
            {/* <Link to="/notice">
              <div className="support-category-link">
                <div className="support-category-link-image">
                  <img src={require("../../img/support/ico_notice.png")} />
                </div>
                <div className="support-category-link-name">
                  {string.support.categoryNotice}
                </div>
                <div className="support-category-link-detail01">
                  {string.support.categoryNoticeEng}
                </div>
              </div>
            </Link> */}
            <Link to="/faq">
              <div className="support-category-link">
                <div className="support-category-link-image">
                  <img src={require("../../img/support/ico_faq.png")} />
                </div>
                <div className="support-category-link-name">
                  {string.support.categoryFaq}
                </div>
                <div className="support-category-link-detail01">
                  {string.support.categoryFaqEng}
                </div>
              </div>
            </Link>
            {/* <Link to="/contact"> */}
            <div
              className="support-category-link"
              onClick={() => {
                global.location.href = "mailto:support@blobs.kr";
              }}>
              <div className="support-category-link-image">
                <img src={require("../../img/support/ico_contact.png")} />
              </div>
              <div className="support-category-link-name">
                {string.support.categoryContact}
              </div>
              <div className="support-category-link-detail01">
                {string.support.categoryContactEmail}
              </div>
            </div>
            {/* </Link> */}
            <div
              className="support-category-link"
              onClick={() => {
                global.location.href = "tel:02-423-4261";
              }}>
              <div className="support-category-link-image">
                <img src={require("../../img/support/ico_tel.png")} />
              </div>
              <div className="support-category-link-name">
                {string.support.categoryTel}
              </div>
              <div className="support-category-link-detail02">
                {string.support.categoryTelExplain01}
              </div>
              <div className="support-category-link-detail01">
                {string.support.categoryTelExplain02}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
