import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { move, modalClose } from '../actions';

import { Spin } from 'antd';
import '../css/index.scss';
import '../css/index_m.scss';
import "../css/common.scss";
import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";

class Main extends Component {
  render() {
    let modalButtonWidth = Math.floor(100 / this.props.modal.buttons.length) + '%';
    return (
      <div className="app">
        <div
          id="loadingSpinner"
          style={{
            display: 'none',
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.3)',
            top: 0,
            left: 0,
            textAlign: 'center',
            zIndex: 99999,
          }}>
          <Spin style={{ position: 'absolute', top: '50%', zIndex: 99999 }} />
        </div>
          <Header />
          <Content />
          <Footer />
          <Modal
            isOpen={this.props.modal.isOpen}
            onRequestClose={this.props.onCloseModal}
            style={modalStyles}
            onKeyPress={this.handleKeyPress}
          >
            <div className="modal-wrapper">
              <div style={{ padding: '15px' }}>
                <div style={{ fontSize: '18px', letterSpacing: '-1px', color: '#000' }}>
                  {this.props.modal.title}
                </div>
                <div style={{ width: '23px', height: '2px', backgroundColor: '#00aab6', marginTop: '20px', marginBottom: '15px' }}></div>
                <div style={{ fontSize: '14px', letterSpacing: '-1px', color: '#686868', lineHeight: '19px' }}>
                  {this.props.modal.content}
                </div>
                <div>{this.props.modal.message}</div>
              </div>
              <div style={{ height: '1px', backgroundColor: '#dadada', marginTop: '30px' }}></div>

              <div>
                {
                  this.props.modal.buttons.map((value, idx) => {
                    return (<div key={idx} onClick={() => { value.callback(); }} style={{ borderLeft: idx == 0 ? '' : '1px solid #dadada', cursor: 'pointer', fontSize: '17px', padding: '10px', color: value.color, textAlign: 'center', float: 'left', width: modalButtonWidth }}>{value.text}</div>);
                  })
                }
              </div>
            </div>
          </Modal>
          {(this.props.loading.openCount > 0) && (
            <div style={loadingStyles.container}>
              <div style={loadingStyles.content}>
                <img src={require('../img/common/loading.gif')} style={{ width: '40px' }} />
              </div>
            </div>
          )}
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    modal: state.modal,
    loading: state.loading,
  };
}

let mapDispatchToProps = (dispatch) => {
  return {
    onCloseModal: () => dispatch(modalClose()),
    onMoveMenu: (name) => dispatch(move(name))
  }
}
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderColor: '#ffffff',
    borderRadius: '5px',
    padding: 0
  }
};
const loadingStyles = {
  container: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    zIndex: '2000'
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: '0px',
    padding: 0
  }
};

Main = connect(mapStateToProps, mapDispatchToProps)(Main);
export default Main;
