import React from 'react';
import { Route, Redirect } from 'react-router'
import queryString from 'query-string';
import httpClient from '../api/httpclient'
import { modalOpen, modalClose, loadingOpen, loadingClose, login } from '../actions';
import { connect } from 'react-redux';
import string from '../String';
class NaverCallBack extends React.Component {
  constructor(props) {
    super(props);
    this.query = queryString.parse(global.location.search);
  }
  render() {
    httpClient.httpRequest(
      httpClient.target.naverLogin,
      { code: this.query.code },
      httpClient.method.post
    ).then((result) => {
      if (result.result) {
        this.props.onLogin(result.user);
      }
      else {
        this.props.modalOpen(string.logIn,
          (<div><div style={{ color: '#00aab6', paddingBottom: '10px' }}>{string.logInFailMsg01} </div><div>{string.logInFailMsg02}<br/>{string.logInFailMsg03}</div></div>),
          [
            { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
          ]);
      }
    }).catch(e=>{
    });
  
    return <Redirect to="/" />;
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    modalOpen: (title, content, buttons) => dispatch(modalOpen(title, content, buttons)),
    modalClose: () => dispatch(modalClose()),
    onLogin: (userinfo) => dispatch(login(userinfo)),
  }
}
NaverCallBack = connect(undefined, mapDispatchToProps)(NaverCallBack);
export default NaverCallBack;
