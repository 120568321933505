import React, { Component } from "react";
import string from "../String";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Layout, Menu, Icon, Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { modalOpen, modalClose, login, logout } from "../actions";
import httpClient from "../api/httpclient";
import { reactLocalStorage } from "reactjs-localstorage";

class Header extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    let current = "";
    if (
      location.pathname === "/" ||
      location.pathname === "/blobscard" ||
      location.pathname === "/blobscardpurchase"
    )
      current = "item_0";
    // if (location.pathname === '/ethereumcard' || location.pathname === '/ethereumcardpurchase') current='item_1';
    if (
      location.pathname === "/notice" ||
      location.pathname === "/faq" ||
      location.pathname === "/contact"
    )
      current = "item_2";

    this.state = {
      drawerOpen: false,
      current: current,
      collapsed: true,
    };
  }

  componentDidMount() {
    global.logout = this.props.onLogout;
    this.initializeUserInfo();
    console.log(this);
  }
  initializeUserInfo = () => {
    const userInfo = reactLocalStorage.getObject("blobsuser");
    if (!userInfo || !userInfo.id) return;
    httpClient
      .httpRequest(httpClient.target.validate)
      .then((result) => {
        if (result == true) {
          this.props.onLogin(userInfo);
        }
      })
      .catch((e) => {});
  };
  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  logout = () => {
    this.props.modalOpen(string.logOut, string.logOutMsg, [
      {
        text: string.confirm,
        callback: () => {
          this.props.onLogout();
          this.props.modalClose();
          httpClient
            .httpRequest(httpClient.target.logout)
            .then(() => {
              global.location.href = "/";
            })
            .catch((e) => {
              global.location.href = "/";
            });
        },
        color: "#00aab6",
      },
      { text: string.cancle, callback: this.props.modalClose, color: "#000" },
    ]);
  };
  render() {
    return (
      <div>
        {this.props.isLogin && (
          <div className="container">
            <div className="top-link">
              <div className="top-link-item" onClick={this.logout}>
                로그아웃
              </div>
              <div className="top-link-divider">|</div>
              <Link to="/purchaselist">
                <div className="top-link-item">장바구니</div>
              </Link>
              <div className="top-link-divider">|</div>
              <div className="top-link-item" style={{ cursor: "auto" }}>
                {this.props.loginInfo.name}
                {string.sir}
              </div>
            </div>
          </div>
        )}
        {!this.props.isLogin && (
          <div className="container">
            <div className="top-link">
              <Link to="/login">
                <div className="top-link-item">로그인</div>
              </Link>
              <div className="top-link-divider">|</div>
              <Link to="/signup">
                <div className="top-link-item">회원가입</div>
              </Link>
            </div>
          </div>
        )}
        <div className="menu-line"></div>
        <div className="container">
          <div className="header desk">
            <div className="header-logo">
              <Link to="/blobscard">
                <img src={require("../img/common/logo.png")} />
              </Link>
            </div>
            <div className="header-menu">
              <Menu
                mode="horizontal"
                style={{
                  backgroundColor: "transparent",
                }}
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}>
                <Menu.Item>
                  <Link to="/blobscard">{string.common.menu01}</Link>
                </Menu.Item>
                {/* <Menu.Item>
                  <Link to="/ethereumcard">{string.common.menu02}</Link>
                </Menu.Item> */}
                <Menu.Item>
                  <Link to="/faq">{string.common.menu03}</Link>
                </Menu.Item>
              </Menu>
            </div>
          </div>
          <div className="header mobile">
            <div className="header-logo">
              <img src={require("../img/common/logo.png")} />
            </div>
            <div
              className="menu-burger-btn"
              style={{
                float: "right",
                paddingTop: "10px",
                marginBottom: "10px",
              }}>
              <Button
                onClick={this.toggleCollapsed}
                style={{ backgroundColor: "transparent", borderWidth: "0px" }}>
                <Icon
                  className="menu-burger-btn-icon"
                  type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                  style={{ color: "black" }}
                />
              </Button>
            </div>

            <div style={{ clear: "both" }} />
            {this.state.collapsed ? null : (
              <div
                className="burger-menu-wrapper"
                onClick={this.toggleCollapsed}>
                <div className="burger-menu">
                  <Menu
                    theme="light"
                    mode="inline"
                    onClick={this.handleClick}
                    selectedKeys={[this.state.current]}>
                    <Menu.Item>
                      <Link to="/blobscard">{string.common.menu01}</Link>
                    </Menu.Item>
                    {/* <Menu.Item>
                    <Link to="/ethereumcard">{string.common.menu02}</Link>
                  </Menu.Item> */}
                    <Menu.Item>
                      <Link to="/notice">{string.common.menu03}</Link>
                    </Menu.Item>
                    {/*
                  <Menu.Item key="/home" className='menu-burger-content' style={{marginTop: '30px'}}>
                    <Link exact to="/home" className='menu-burger-content'>
                    <Icon type="info-circle" /><span className="nav-text">{string.headerMenu04}</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/product" className='menu-burger-content'>
                    <Link exact to="/product" className='menu-burger-content'>
                    <Icon type="inbox" style={{fontSize: '16px'}}/><span className="nav-text">{string.headerMenu05}</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/manual" className='menu-burger-content'>
                    <Link exact to="/manual" className='menu-burger-content'>
                    <Icon type="profile" /><span className="nav-text">{string.headerMenu06}</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/manual" className='menu-burger-content'>
                    <Icon type="user" /><span className="nav-text">{string.headerMenu07}</span>
                  </Menu.Item>
                  <Menu.Item key="/support/notice" className='menu-burger-content-sub'>
                    <Link exact to="/support/notice" className='menu-burger-content-sub'>
                    <div style={{paddingLeft:'15px'}}>
                      <img src={require('../img/common/bullet.png')} style={{width:'3px', marginRight: '10px'}} />
                      <span className="nav-text">{string.noticeTitle}</span>
                    </div>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/support/faq" className='menu-burger-content-sub'>
                    <Link exact to="/support/faq" className='menu-burger-content-sub'>
                    <div style={{paddingLeft:'15px'}}>
                      <img src={require('../img/common/bullet.png')} style={{width:'3px', marginRight: '10px'}} />
                      <span className="nav-text">FAQ</span>
                    </div>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/support/qna" className='menu-burger-content-sub' style={{marginBottom: '30px'}}>
                    <Link exact to="/support/qna" className='menu-burger-content-sub'>
                    <div style={{paddingLeft:'15px'}}>
                      <img src={require('../img/common/bullet.png')} style={{width:'3px', marginRight: '10px'}} />
                      <span className="nav-text">1:1{string.noticeTitleSub}</span>
                    </div>
                    </Link>
                  </Menu.Item> */}
                  </Menu>
                </div>
              </div>
            )}

            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo,
    selectedItem: state.menu.selectedItem,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    modalOpen: (title, content, buttons) =>
      dispatch(modalOpen(title, content, buttons)),
    modalClose: () => dispatch(modalClose()),
    onLogin: (userinfo) => dispatch(login(userinfo)),
    onLogout: () => dispatch(logout()),
  };
};
Header = connect(mapStateToProps, mapDispatchToProps)(Header);
export default withRouter(Header);
