import { Form, Input, Tooltip, Icon, Cascader, Select, Row, Col, Checkbox, Button, AutoComplete } from 'antd';
import React from 'react';
import string from '../String';
class AgreementApp extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div style={{ margin: 'auto', width: '100%', alignItems: 'center' }}>
        <div style={{ fontSize: 24 }}>{string.blobs} {string.agreement}</div>
        <div style={{ fontSize: 14, paddingTop: 10 }}>
          {string.agreementApp01}<br />
          <br />
          {string.agreementApp02}<br />
          {string.agreementApp03}<br />
          <br />
          {string.agreementApp04}<br />
          {string.agreementApp05}<br />
          {string.agreementApp06}<br />
          {string.agreementApp07}<br />
          {string.agreementApp08}<br />
          {string.agreementApp09}<br />
          <br />
          {string.agreementApp10}<br />
          {string.agreementApp11}<br />
          {string.agreementApp12}<br />
          <br />
          {string.agreementApp13}<br />
          {string.agreementApp14}<br />
          <br />
          {string.agreementApp15}<br />
          {string.agreementApp16}<br />
          {string.agreementApp17}<br />
          {string.agreementApp18}<br />
          {string.agreementApp19}<br />
          {string.agreementApp20}<br />
          {string.agreementApp21}<br />
          <br />
          <br />
          <br />
          <br />
          {string.agreementApp22}<br />
          <br />
          {string.agreementApp23}<br />
          {string.agreementApp24}<br />
          {string.agreementApp25}<br />
          <br />
          {string.agreementApp26}<br />
          {string.agreementApp27}<br />
          <br />
          {string.agreementApp28}<br />
          {string.agreementApp29}<br />
          {string.agreementApp30}<br />
          {string.agreementApp31}<br />
          {string.agreementApp32}<br />
          {string.agreementApp33}<br />
          <br />
          {string.agreementApp34}<br />
          {string.agreementApp35}<br />
          <br />
          {string.agreementApp36}<br />
          {string.agreementApp37}<br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {string.agreementApp38}<br />
          <br />
          {string.agreementApp39}<br />
          {string.agreementApp40}<br />
          {string.agreementApp41}<br />
          <br />
          {string.agreementApp42}<br />
          <br />
          {string.agreementApp43}<br />
          {string.agreementApp44}<br />
          {string.agreementApp45}<br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {string.agreementApp46}<br />
          <br />
          {string.agreementApp47}<br />
          {string.agreementApp48}<br />
          {string.agreementApp49}<br />
          {string.agreementApp50}<br />
          <br />
          {string.agreementApp51}<br />
          {string.agreementApp52}<br />
          {string.agreementApp53}<br />
          <br />
          {string.agreementApp54}<br />
          {string.agreementApp55}<br />
          {string.agreementApp56}<br />
          {string.agreementApp57}<br />
          {string.agreementApp58}<br />
          {string.agreementApp59}<br />
          {string.agreementApp60}<br />
          {string.agreementApp61}<br />
          {string.agreementApp62}<br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {string.agreementApp63}<br />
          <br />
          {string.agreementApp64}<br />
          <br />
          {string.agreementApp65}<br />
          <br />
          {string.agreementApp66}<br />
          <br />
          {string.agreementApp67}<br />
          {string.agreementApp68}<br />
          {string.agreementApp69}<br />
          {string.agreementApp70}<br />
          {string.agreementApp71}<br />
          {string.agreementApp72}<br />
          {string.agreementApp73}<br />
          {string.agreementApp74}<br />
          {string.agreementApp75}<br />
          <br />
          <br />
          {string.agreementApp76}<br />
          {string.agreementApp77}<br />
          {string.agreementApp78}<br />
          <br />
          {string.agreementApp79}<br />
          {string.agreementApp80}<br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}
export default AgreementApp;