import React from 'react';
import { modalOpen, modalClose, loadingOpen, loadingClose } from '../actions';
import { connect } from 'react-redux';
import { Button, Table, Col, Radio, Checkbox, Breadcrumb, Form, Input, InputNumber, Select, AutoComplete } from 'antd';
import { Link, Route } from 'react-router-dom';
import httpClient from '../api/httpclient';
import queryString from 'query-string';
import string from '../String';
class PurchaseResult extends React.Component {
  constructor(props) {
    super(props);
    this.query = queryString.parse(this.props.location.search);
    this.state = {
      result: {
        orderId: "",
        orderStatus: "",
        orderStatusString: "",
        targetName: "",
        targetAddress: "",
        targetAddressDetail: "",
        targetTel: "",
        targetMemo: '',
        createDate: "",
        products: [
          {
            orderId: "",
            productIdx: 1,
            productName: '',
            quantity: 0,
            discount: 0,
            price: 0
          }
        ]
      }
    }
  }

  componentDidMount() {
    let orderId = this.query.ordno;
    let token = this.query.token;

    httpClient.httpRequest(httpClient.target.orderResult, [orderId,token]).then((result) => {
      if (result.orderStatus == 'REQUEST') result.orderStatusString = string.purchaseListStatus01;
          else if (result.orderStatus == 'PAYING') result.orderStatusString = string.purchaseListStatus02;
          else if (result.orderStatus == 'PAID') result.orderStatusString = string.purchaseListStatus03;
          else if (result.orderStatus == 'DELIVER') result.orderStatusString = string.purchaseListStatus04;
          else if (result.orderStatus == 'DONE') result.orderStatusString = string.purchaseListStatus05;
          else if (result.orderStatus == 'CANCEL') result.orderStatusString = string.cancle;
      this.setState({result: result})
    }).catch(e => {
    });
    global.mobConv()
  }
  goPurchaseList = () => {
    this.props.history.push({ pathname: '/purchaseList' });
  }
  render() {
    let trno = this.query.trno;
    let cbtrno = this.query.cbtrno;

    return (
      <div>
        <div className="menu-line"></div>
        <div className="container" style={{textAlign: 'center'}}>
          <div className="login-title-wrapper">
            <div className="login-title-text">
            {string.purchaseResultComplete} 
            </div>
          </div>
          <div className="menu-line" />

          <div className="login-form-wrapper">
            <div>
              <div className="login-text-login">
                {string.purchaseResultOrderList}
              </div>
              <div className="login-text-sub">
                {string.purchaseResultThankyou}
              </div>

              <ul className='purchase-result-item-wrapper'>
                <li>
                  <div className='purchase-result-item-left'>{string.purchaseResultOrderNumber}</div>
                  <div className='purchase-result-item-right'>{this.state.result.orderId}</div>
                </li>
                <li>
                  <div className='purchase-result-item-left'>{string.status}</div>
                  <div className='purchase-result-item-right'>
                    {this.state.result.orderStatusString}
                    {this.state.result.orderStatus == "PAYING" && (
                      <div style={{fontWeight: 'bold', color: 'rgb(0,170,182)'}}>{this.state.result.paymentEtc}</div>
                    )} 
                  </div>
                </li>
                <li>
                  <div className='purchase-result-item-left'>{string.date}</div>
                  <div className='purchase-result-item-right'>{this.state.result.createDate}</div>
                </li>
                <li>
                  <div className='purchase-result-item-left'>{string.productInfo}</div>
                  <div className='purchase-result-item-right'>{string.blobs} {string.hybridColdWallet}</div>
                </li>
                <li>
                  <div className='purchase-result-item-left'>{string.quantity}</div>
                  <div className='purchase-result-item-right'>{this.state.result.products[0].quantity}개</div>
                </li>
                <li>
                  <div className='purchase-result-item-left'>{string.deliveryAddress}</div>
                  <div className='purchase-result-item-right'>{this.state.result.targetAddress} {this.state.result.targetAddressDetail}</div>
                </li>
                <li>
                  <div className='purchase-result-item-left'>{string.reciever}</div>
                  <div className='purchase-result-item-right'>{this.state.result.targetName}</div>
                </li>
                <li>
                  <div className='purchase-result-item-left'>{string.contact}</div>
                  <div className='purchase-result-item-right'>{this.state.result.targetTel}</div>
                </li>
                <li>
                  <div className='purchase-result-item-left'>{string.memo}</div>
                  <div className='purchase-result-item-right'>{this.state.result.targetMemo}</div>
                </li>
              </ul>
              <div className='mt-40'>
                {trno != null && trno.length > 0 && (
                <Button className='purchase-result-button' onClick={()=>{global.receiptView(trno)}} style={{ background: '#00aab6' }}>
                  {string.purchaseResultPrintReceipt}
                </Button>
                )}
                {cbtrno != null && cbtrno.length > 0 && (
                <Button className='purchase-result-button' onClick={()=>{global.CashreceiptView(cbtrno)}} style={{ background: '#00aab6' }}>
                  {string.purchaseResultPrintReceipt}
                </Button>
                )}
                {this.props.isLogin && (
                <Button className='purchase-result-button' onClick={this.goPurchaseList} style={{ background: 'rgb(137,137,137)' }}>
                  {string.purchaseResultMyShoppingList}
                </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        
      </div>

    );
  }
}
let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo,
  };
}
PurchaseResult = connect(mapStateToProps)(PurchaseResult);
export default PurchaseResult;


