import React, { Component } from 'react';
import Header from '../components/Header';
import { Table, Input, Button, Icon, Breadcrumb } from 'antd';
import string from '../String';
import { Link, withRouter } from "react-router-dom";
import Support from './sub/Support';
import httpClient from '../api/httpclient';
export default class Notice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {},
      loading: false,
    };
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  }

  fetch = (params = {}) => {
    console.log('params:', params);
    this.setState({ loading: true });

    let page = params.page || 1;

    httpClient.httpRequest(
      httpClient.target.notices,
      [10,page]
    ).then((result) => {
      console.log('result:', JSON.stringify(result));
      const pagination = { ...this.state.pagination };
      pagination.total = result.totalCount;
      this.setState({
        loading: false,
        data: result.data.map(value=>{ 
          value['key'] = value.idx;
          value.content = value.content || "";
          value.content = value.content.split('\n').map(line => {
            return (<span>{line}<br/></span>)
          });
          return value;
        }),
        pagination,
      });
    }).catch(e => {
      this.setState({ loading: false });
    });
  }

  componentDidMount() {
    this.fetch();
  }
  
  render() {
    const 
    noticeCategory = [
      '',
      string.support.noticeCategory01,
      string.support.noticeCategory02,
      string.support.noticeCategory03,
    ];
    const columns = [{
      title: string.support.noticeTableCol01,
      dataIndex: 'idx',
      className: 'support-column-number desk'
    }, {
      title: string.support.noticeTableCol02,
      dataIndex: 'title',
      key: 'title',
      className: 'support-column-title',
      render: (text, record) => <span>[{noticeCategory[record.category]}] {text}</span>,
    }, {
      title: string.support.noticeTableCol04,
      dataIndex: 'read',
      className: 'support-column-number desk'
    }, {
      title: string.support.noticeTableCol03,
      dataIndex: 'createDateString',
      className: 'support-column'
    }];
    return (
      <div className="">
        <Support/>
        <div className="container-support" style={{marginBottom: '50px'}}>
          <div className="support-sub-title">
          {string.support.categoryNotice}
          </div>
          <div className="support-table-top-line"></div>
          <Table
            expandRowByClick="true" 
            expandedRowRender={record => <p style={{ margin: 0 }}>{record.content}</p>}
            columns={columns}
            rowKey={record => record.key}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}
