import React, { Component } from 'react';
import string from '../../String';
import { Link, Route } from 'react-router-dom';
import {formatNumber} from '../../util';
import { InputNumber } from 'antd';
export default class PurchaseSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainImage: this.props.goods.images[0],
      quantity: 1,
      totalPrice: this.props.goods.price,
    }
  }
  onProductImageOver = (i) => {
    this.setState({mainImage: this.props.goods.images[i]})
  };
  changeQuantity = (value) => {
    this.setState({
      quantity: value,
      totalPrice: value * this.props.goods.price
    });
  }
  render() {
    return (
      <div className="purchase-container">
        <div className="purchase-container-left">
          <div className="purchase-image-main-wrapper">
            <img src={this.state.mainImage} className="purchase-image-main"/>
          </div>
          <div className="purchase-image-sub-list">
            {this.props.goods.images.map((v, i) => {
              return (
                <div className="purchase-image-sub-wrapper">
                  <img src={v} className="purchase-image-sub" onMouseOver={()=>{this.onProductImageOver(i)}} onClick={()=>{this.onProductImageOver(i)}}/>
                </div>
              );
            })}
          </div>
        </div>
        <div className="purchase-container-right">
          <div className="purchase-content-title-wrapper">
            <div className="purchase-content-title-name">
            {this.props.goods.name}
            </div>
            <div className="purchase-content-title-engname">
            {this.props.goods.nameEng}
            </div>
          </div>
          <div className="purchase-content-title-explain">
            {this.props.goods.nameExplain}
          </div>
          <div className="purchase-content-line"></div>
          <div className="purchase-content-price">
            {formatNumber(this.props.goods.price) + string.common.purchaseDetail08}
          </div>
          <div className="purchase-content-line"></div>
          <div className="purchase-content-detail-wrapper">
            <div>
              <div className="purchase-content-detail-item">
              {string.common.purchaseDetail01}
              </div>
              <div className="purchase-content-detail-item-explain">
              {this.props.goods.detail01}
              </div>
            </div>
            <div>
              <div className="purchase-content-detail-item">
              {string.common.purchaseDetail02}
              </div>
              <div className="purchase-content-detail-item-explain">
              {this.props.goods.detail02}
              </div>
            </div>
            <div>
              <div className="purchase-content-detail-item">
              {string.common.purchaseDetail03}
              </div>
              <div className="purchase-content-detail-item-explain">
              {this.props.goods.detail03}
              </div>
            </div>
            <div>
              <div className="purchase-content-detail-item">
              {string.common.purchaseDetail04}
              </div>
              <div className="purchase-content-detail-item-explain">
              {this.props.goods.detail04}
              </div>
            </div>
          </div>
          <div className="purchase-content-line"></div>
            <div className="purchase-content-detail-wrapper">
              <div className="purchase-content-detail-item">
              {string.common.purchaseDetail05}
              </div>
              <div className="purchase-content-detail-item-explain">
              <InputNumber className="purchase-content-detail-quantity" min={1} max={10000} defaultValue={1} onChange={this.changeQuantity} />
              </div>
            </div>
          <div className="purchase-content-line"></div>
          <div className="purchase-content-totalprice-wrapper">
            <div className="purchase-content-totalprice-01">
              {string.common.purchaseDetail06}
            </div>
            <div className="purchase-content-totalprice-02">
              {string.common.purchaseDetail07}
            </div>
            <div className="purchase-content-totalprice-03" style={{marginLeft: '20px'}}>
            {formatNumber(this.state.totalPrice) + string.common.purchaseDetail08}
            </div>
            <div className="purchase-content-totalprice-02">
            ({this.state.quantity+string.common.purchaseDetail09})
            </div>
          </div>
          <Link to={{
            // pathname: '/purchase',
            // state: {
            //   goods: this.props.goods,
            //   quantity: this.state.quantity,
            //   totalPrice: this.state.totalPrice
            // }
          }}><div onClick= {()=> alert("상품 준비중입니다. ") }className="purchase-content-button">{string.common.purchaseDetailButton}</div></Link>
        </div>
      </div>
    );
  }
}
