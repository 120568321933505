import { Form, Input, Tooltip, Icon, Cascader, Select, Row, Col, Checkbox, Button, AutoComplete, Link } from 'antd';
import React from 'react';
import { modalOpen, modalClose, loadingOpen, loadingClose, login } from '../actions';
import { connect } from 'react-redux';
import httpClient from '../api/httpclient'
import AgreementSub1 from './AgreementSub1';
import AgreementSub2 from './AgreementSub2';
import LoginNaver from '../components/LoginNaver';
import string from '../String';
const Captcha = require('react-captcha');
const FormItem = Form.Item;
const Option = Select.Option;
const AutoCompleteOption = AutoComplete.Option;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    modalIsOpen: false,
    address: null
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const eTarget = e.target;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.loadingOpen();
        httpClient.httpRequest(httpClient.target.signUp, values, httpClient.method.post).then((result) => {
          this.props.loadingClose();
          if (result.result) {
            //가입성공
            eTarget.reset();
            this.props.modalOpen(string.signUp,
              (<div><div style={{ color: '#00aab6', paddingBottom: '10px' }}>{string.signUpMsg01} </div><div>{string.signUpMsg02} <br />{string.signUpMsg03}</div></div>),
              [
                { text: string.confirm, callback: () => { global.location.href = '/login'; }, color: '#00aab6' },
              ]);
          }
          else {
            let reasonText = '';
            if (result.reason == 'DUPLICATE') {
              reasonText = string.signUpRuleMsg01;
            }
            else if (result.reason == 'WRONG_EMAIL_TYPE') {
              reasonText = string.signUpRuleMsg02;
            }
            else if (result.reason == 'UNSATISFY_PASSWORD_STRENGTH') {
              reasonText = string.signUpRuleMsg03;
            }
            else {
              reasonText = string.signUpRuleMsg04;
            }
            this.props.modalOpen(string.signUp,
              (<div><div style={{ color: '#00aab6', paddingBottom: '10px' }}>{string.signUpFail} </div><div>{reasonText}</div></div>),
              [
                { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
              ]);
          }
        }).catch(e => {
          this.props.loadingClose();
        });
        console.log('Received values of form: ', values);
      }
    });
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    console.log("bb:" + form.getFieldValue('password'))
    if (value && value !== form.getFieldValue('password')) {
      callback(string.signUpRuleMsg05);
    } else {
      callback();
    }
  }
  validateChecked = (rule, value, callback) => {
    const form = this.props.form;
    if (value == undefined || value) {
      callback();
    } else {
      callback(string.signUpRuleMsg06);
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }

    //let includeRegex = new RegExp("^([a-zA-Z0-9!@#\$%\^&\*]{8,15})$");
    let strongRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$");

    if (value != null && value != '' && !strongRegex.test(value)) {
      callback(string.signUpRuleMsg07);
    }
    else {
      callback();
    }
  }

  onClickFindAddress = () => {
    const form = this.props.form;
    new global.daum.Postcode({
      oncomplete: (data) => {
        form.setFieldsValue({ address: data.roadAddress });
        this.setState({
          address: data.roadAddress
        });
      }
    }).open();
  }

  cancel = () => {
    this.props.modalOpen(string.signUp, string.signUpCancleMsg, [
      { text: string.confirm, callback: () => { global.location.href = '/'; }, color: '#00aab6' },
      { text: string.cancle, callback: this.props.modalClose, color: '#000' },
    ]);
  }
  onChangeAgree1 = (e) => {
    if (e.target.checked)
      this.agreement1();
  }
  onChangeAgree2 = (e) => {
    if (e.target.checked)
      this.agreement2();
  }
  agreement1 = () => {
    this.props.modalOpen(string.blobs+' '+string.agreement,
      (<div style={{height: '300px', overflow: 'auto'}}>
          <AgreementSub1/>
      </div>),
      [
        { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
      ]);
  }
  agreement2 = () => {
    this.props.modalOpen(string.signUpAgreement,
      (<div style={{height: '300px', overflow: 'auto'}}>
          <AgreementSub2/>
      </div>),
      [
        { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
      ]);
  }
  
  loginWithKakao = () => {
    global.Kakao.Auth.login({
      success: (authObj) => {
 
        httpClient.httpRequest(
          httpClient.target.kakaoLogin,
          { code: authObj.access_token },
          httpClient.method.post
        ).then((result) => {
          if (result.result) {
            this.props.onLogin(result.user);
            this.props.history.push('/');
          }
          else {
            this.props.modalOpen(string.logIn,
              (<div><div style={{ color: '#00aab6', paddingBottom: '10px' }}>{string.logInFailMsg01} </div><div>{string.logInFailMsg02}<br />{string.logInFailMsg03}</div></div>),
              [
                { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
              ]);
          }
        }).catch(e => {
        });

        // alert(authObj.access_token);

        // alert(JSON.stringify(authObj));
        // httpClient.httpRequest(httpClient.target.otherauth, { other: 'kakao', access_token: authObj.access_token }, httpClient.method.post).then((result) => {
        // });
      },
      fail: (err) => {
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const formItemLayout = {
    };
    const tailFormItemLayout = {
    };

    return (
      <div>
        <div className="menu-line"></div>
        <div className="container" style={{textAlign: 'center'}}>

          <div className="login-title-wrapper">
            <div className="login-title-text">
              <b>{string.blobs}</b> {string.membership}
            </div>
          </div>
          <div className="menu-line" />
          <div className="login-form-wrapper">
            <div style={{ maxWidth: '500px', margin: 'auto', textAlign: 'center' }}>
              <div className="login-text-login">
                {string.signUp}
              </div>
              <div className="login-text-sub">
                {string.welcome}
              </div>
            </div>
            <div className="signup-form">
              <Form onSubmit={this.handleSubmit} className="form">
                <FormItem
                  {...formItemLayout}
                  className="login-item-wrapper"
                >
                  {getFieldDecorator('id', {
                    rules: [{
                      type: 'email', message: string.emailRule03,
                    }, {
                      required: true, message: string.emailRule02,
                    }],
                  })(
                    <div className="login-field-wrapper">
                      <div className="login-field-icon">
                        <img src={require('../img/login/id.png')} />
                      </div>
                      <div className="login-field-input">
                        <Input placeholder={string.id} />
                      </div>
                    </div>
                  )}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  className="login-item-wrapper"
                >
                  {getFieldDecorator('password', {
                    rules: [{
                      required: true, message: string.passWordRule,
                    }, {
                      validator: this.validateToNextPassword,
                    }],
                  })(
                    <div className="login-field-wrapper">
                      <div className="login-field-icon">
                        <img src={require('../img/login/password.png')} />
                      </div>
                      <div className="login-field-input">
                        <Input type="password" placeholder={string.passWord} />
                      </div>
                    </div>
                  )}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  className="login-item-wrapper"
                >
                  {getFieldDecorator('confirm', {
                    rules: [{
                      required: true, message: string.passWordRule,
                    }, {
                      validator: this.compareToFirstPassword,
                    }],
                  })(
                    <div className="login-field-wrapper">
                      <div className="login-field-icon">
                        <img src={require('../img/login/password_confirm.png')} />
                      </div>
                      <div className="login-field-input">
                        <Input type="password" onBlur={this.handleConfirmBlur} placeholder={string.passWordConfirm} />
                      </div>
                    </div>
                  )}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  className="login-item-wrapper"
                >
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: string.nameRule, whitespace: true }],
                  })(
                    <div className="login-field-wrapper">
                      <div className="login-field-icon">
                        <img src={require('../img/login/name.png')} />
                      </div>
                      <div className="login-field-input">
                        <Input placeholder={string.name} />
                      </div>
                    </div>
                  )}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  className="login-item-wrapper"
                >
                  {getFieldDecorator('address', {
                    rules: [{ message: string.addressRule, whitespace: true }],
                  })(
                    <div className="login-field-wrapper">
                      <div className="login-field-icon">
                        <img src={require('../img/login/address.png')} />
                      </div>
                      <div className="login-field-input">
                        <Input onClick={this.onClickFindAddress} value={this.state.address} placeholder={string.address} />
                      </div>
                    </div>
                  )}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  className="login-item-wrapper"
                >
                  {getFieldDecorator('addressDetail', {
                    rules: [{ message: string.addressDetailRule, whitespace: true }],
                  })(
                    <div className="login-field-wrapper">
                      <div className="login-field-icon">
                        <img src={require('../img/login/address.png')} />
                      </div>
                      <div className="login-field-input">
                        <Input placeholder={string.addressDetail} />
                      </div>
                    </div>
                  )}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  className="login-item-wrapper"
                >
                  {getFieldDecorator('tel', {
                    rules: [{ message: string.contactRule }],
                  })(
                    <div className="login-field-wrapper">
                      <div className="login-field-icon">
                        <img src={require('../img/login/call.png')} />
                      </div>
                      <div className="login-field-input">
                        <Input placeholder={string.contact} />
                      </div>
                    </div>
                  )}
                </FormItem>
                <div className="menu-line"></div>
                <div className='signup-agreement-wrapper'>
                  <div className='signup-agreement-title'>
                    {string.agreementAgree}
                  </div>
                  <div>
                    <div style={{ float: 'left' }}>
                      <FormItem
                        style={{ marginBottom: '0px'}}>
                        {getFieldDecorator('checkAgree1', {
                          rules: [{
                            required: true,
                            message: string.agreementAgreeMsg
                          }, {
                            validator: this.validateChecked,
                          }],
                        })(
                          <Checkbox onChange={this.onChangeAgree1} style={{}}>
                            <span className='signup-agreement-content'>{string.blobsAgreement}</span>
                          </Checkbox>
                        )}
                      </FormItem>
                    </div>
                    <div className='signup-agreement-link' style={{ float: 'right' }}>
                      <a style={{}} onClick={this.agreement1}>
                        {string.showAgreement}
                      </a>
                    </div>
                  </div>
                  <div style={{ clear: 'both' }} />
                  <div>
                    <div style={{ float: 'left' }}>
                      <FormItem
                        style={{ marginBottom: '0px'}}>
                        {getFieldDecorator('checkAgree2', {
                          rules: [{
                            required: true,
                            message: string.signUpRuleMsg06
                          }, {
                            validator: this.validateChecked,
                          }],
                        })(
                          <Checkbox onChange={this.onChangeAgree2} style={{}}>
                            <span className='signup-agreement-content'>{string.pursonalInfoAgree}</span>
                          </Checkbox>
                        )}
                      </FormItem>
                    </div>
                    <div className='signup-agreement-link' style={{ float: 'right' }}>
                      <a style={{}} onClick={this.agreement2}>
                        {string.showAgreement}
                      </a>
                    </div>
                  </div>
                  <div style={{ clear: 'both' }} />
                </div>

                <div className="signup-btn-wrapper">
                  <div className="">
                    <Button className="signup-btn signup-btn-ok" htmlType="submit">{string.signUp}</Button>
                  </div>
                  <div className="">
                    <Button className="signup-btn signup-btn-cancel" onClick={this.cancel}>{string.cancle}</Button>
                  </div>
                  <div style={{ clear: 'both' }} />
                </div>
                <div className="menu-line"></div>

                <div className="signup-other-login-wrapper">
                  <div className="signup-other-login-text" dangerouslySetInnerHTML={{__html:string.signUpOtherLoginMsg}}>
                  </div>
                  <div className="signup-other-login-btn-wrapper01">
                    <LoginNaver/>
                  </div>
                  <div className="signup-other-login-btn-wrapper02">
                    <img className="kakao-login-btn-size" onClick={() => this.loginWithKakao()}
                      src={require('../img/login/kakao.png')} />
                  </div>
                </div>

              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    modalOpen: (title, content, buttons) => dispatch(modalOpen(title, content, buttons)),
    modalClose: () => dispatch(modalClose()),
    loadingOpen: () => dispatch(loadingOpen()),
    loadingClose: () => dispatch(loadingClose()),
    onLogin: (userinfo) => dispatch(login(userinfo)),
  }
}

let SignUpForm = Form.create()(SignUp);
SignUpForm = connect(undefined, mapDispatchToProps)(SignUpForm);
export default SignUpForm;