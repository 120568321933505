import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import { modalOpen, modalClose, loadingOpen, loadingClose, login } from '../actions';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import httpClient from '../api/httpclient';
import LoginNaver from '../components/LoginNaver';
import * as qs from 'query-string';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../const';
import queryString from 'query-string';

class kspayResult extends React.Component {

  constructor(props) {
    super(props);
    this.query = queryString.parse(this.props.location.search);
    console.log(this.query);
  }
  componentDidMount() {
    if (global.top.opener == null || global.top.opener.eparamSet == null || global.top.opener.goResult == null) {
      global.self.close();
      return;
    }
    let result = this.query.result;
    let ordno = this.query.ordno;
    let token = this.query.token;
    let trno = this.query.trno;
    let cbtrno = this.query.cbtrno;
    let msg1 = this.query.msg1;
    let msg2 = this.query.msg2;

    if (result != "success") {
      alert("결제에 실패하였습니다!!\n메시지1:"+msg1+"\n메시지2:"+msg2);
      global.self.close();
      return;
    }
    else {
      global.top.opener.location.href='/purchaseResult?token='+token+'&ordno='+ordno+'&trno='+trno+'&cbtrno='+cbtrno;
    }
    setTimeout(global.self.close(), 1000);
  }
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
      </div>
    );
  }
}

export default kspayResult;