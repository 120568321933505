import React, { Component } from 'react';
import Header from '../components/Header';
import string from '../String';
import PurchaseSummary from './sub/PurchaseSummary';
export default class BlobsCardPurchase extends Component {
  constructor(props){
    super(props)

    this.goods = {
      images: [
        require('../img/blobscard/purchase01.png'),
        require('../img/blobscard/purchase02.jpg'),
        require('../img/blobscard/purchase03.jpg'),
      ],
      id: 1,
      name: string.blobscard.purchaseTitle,
      nameEng: string.blobscard.purchaseTitleEng,
      nameExplain: string.blobscard.purchaseTitleExplain,
      price: 110000,
      detail01: string.blobscard.purchaseDetail01,
      detail02: string.blobscard.purchaseDetail02,
      detail03: string.blobscard.purchaseDetail03,
      detail04: string.blobscard.purchaseDetail04,
    }
    this.state = {
      tab: 0
    }
  }
  
  renderSupprotCoin() {
    const supportCoin = [
      {name: string.coin.coinName01, symbol: 'BLB', img: require('../img/symbol/blb.png')},
      {name: string.coin.coinName02, symbol: 'BTC', img: require('../img/symbol/btc.png')},
      {name: string.coin.coinName03, symbol: 'ETH', img: require('../img/symbol/eth.png')},
      {name: string.coin.coinName04, symbol: 'XRP', img: require('../img/symbol/xrp.png')},
      // {name: string.coinName05, symbol: 'EOS', img: require('../img/symbol/eos.png')},
      {name: string.coin.coinName06, symbol: 'LTC', img: require('../img/symbol/ltc.png')},
      // {name: string.coinName07, symbol: 'TRX', img: require('../img/symbol/trx.png')},
      // {name: string.coinName08, symbol: 'VET', img: require('../img/symbol/vet.png')},
      {name: string.coinName09, symbol: 'OMG', img: require('../img/symbol/omg.png')},
      // {name: string.coinName10, symbol: 'ICX', img: require('../img/symbol/icx.png')},
      {name: string.coin.coinName11, symbol: 'BNB', img: require('../img/symbol/bnb.png')},
      {name: string.coin.coinName12, symbol: 'ZIL', img: require('../img/symbol/zil.png')},
      {name: string.coin.coinName13, symbol: 'ZRX', img: require('../img/symbol/zrx.png')},
      {name: string.coin.coinName14, symbol: 'AE', img: require('../img/symbol/ae.png')},
      {name: string.coin.coinName15, symbol: 'REP', img: require('../img/symbol/rep.png')},
      {name: string.coin.coinName16, symbol: 'GNT', img: require('../img/symbol/gnt.png')},
      {name: string.coin.coinName17, symbol: 'SNT', img: require('../img/symbol/snt.png')},
      {name: string.coin.coinName18, symbol: 'MCO', img: require('../img/symbol/mco.png')},
      {name: string.coin.coinName19, symbol: 'LOOM', img: require('../img/symbol/loom.png')},
      {name: string.coin.coinName20, symbol: 'KNC', img: require('../img/symbol/knc.png')},
      // {name: string.coinName21, symbol: 'XTZ', img: require('../img/symbol/xtz.png')},
      {name: string.coin.coinName22, symbol: 'ETHOS', img: require('../img/symbol/ethos.png')},
      {name: string.coin.coinName23, symbol: 'POWR', img: require('../img/symbol/powr.png')},
      {name: string.coin.coinName24, symbol: 'STORM', img: require('../img/symbol/storm.png')},
      {name: string.coin.coinName25, symbol: 'PAY', img: require('../img/symbol/pay.png')},
      {name: string.coin.coinName26, symbol: 'SALT', img: require('../img/symbol/salt.png')},
      {name: string.coin.coinName27, symbol: 'CVC', img: require('../img/symbol/cvc.png')},
      {name: string.coin.coinName28, symbol: 'NPXS', img: require('../img/symbol/npxs.png')},
      {name: string.coin.coinName29, symbol: 'BAT', img: require('../img/symbol/bat.png')},
      {name: string.coin.coinName30, symbol: 'HT', img: require('../img/symbol/ht.png')},
      {name: string.coin.coinName31, symbol: 'CS', img: require('../img/symbol/cs.png')},
      {name: string.coin.coinName32, symbol: 'GBP', img: require('../img/symbol/gbp.png')},
      {name: string.coin.coinName33, symbol: 'CRC', img: require('../img/symbol/crc.png')},
      {name: string.coin.coinName34, symbol: 'XIS', img: require('../img/symbol/xis.png')},
      {name: string.coin.coinName35, symbol: 'FK', img: require('../img/symbol/fk.png')},
      {name: string.coin.coinName36, symbol: 'MVL', img: require('../img/symbol/mvl.png')},
      {name: string.coin.coinName37, symbol: 'MD', img: require('../img/symbol/md.png')},
      {name: string.coin.coinName38, symbol: 'KC', img: require('../img/symbol/kc.png')},
      {name: string.coin.coinName39, symbol: 'SC', img: require('../img/symbol/sc.png')},
      {name: string.coin.coinName40, symbol: 'MTT', img: require('../img/symbol/mtt.png')},
      {name: string.coin.coinName41, symbol: 'TRAM', img: require('../img/symbol/tram.png')},
    ];
    let result = [];
    supportCoin.forEach((coin) => {
      result.push(
      <div className="support-coin-wrapper">
        <img className="support-coin-image" src={coin.img} />
        <div className="support-coin-name">{coin.name + '(' + coin.symbol + ')'}</div>
      </div>);
    })
    return (<div className="support-coin-container">{result}</div>);
  }
  render() {
    return (
      <div className="">
        <div className="menu-line"></div>
        <div className="container">
          <div className="breadcrumb">
            Home <span className="breadcrumb-divider">></span> <span className="pointer" onClick={()=>{global.location.href='/blobscard'}}>{string.common.menu01}</span> <span className="breadcrumb-divider">></span> <span className="breadcrumb-current">{string.common.submenu01}</span>
          </div>
        </div>
        <div className="container">
          <PurchaseSummary goods={this.goods}/>
        </div>
        <div className="container" style={{clear: 'both', paddingTop: '65px'}}>
          <div className={"blobscard-purchase-content-tab" + (this.state.tab==0 ? "-over" : "")} onClick={()=>{this.setState({tab: 0})}}>{string.blobscard.purchaseContentTab01}</div>
          <div className={"blobscard-purchase-content-tab" + (this.state.tab==1 ? "-over" : "")} onClick={()=>{this.setState({tab: 1})}}>{string.blobscard.purchaseContentTab02}</div>
        </div>
        <div className="container">
          <div className="purchase-content-line" style={{marginTop: '-1px'}}></div>
        </div>
        {this.state.tab==0 && (
        <div className="container">
          <div className="blobscard-purchase-content01">
            <div className="blobscard-purchase-content01-title">{string.blobscard.purchaseContent01Title}</div>
            <div className="blobscard-purchase-content01-explain">{string.blobscard.purchaseContent01Explain01}</div>
            <div className="blobscard-purchase-content01-explain">{string.blobscard.purchaseContent01Explain02}</div>
            <div className="blobscard-purchase-content01-box-wrapper">
              <div className="blobscard-purchase-content01-box">
                <div className="blobscard-purchase-content01-box-number">01</div>
                <div className="blobscard-purchase-content01-box-title">{string.blobscard.purchaseContent01Box01Title}</div>
                <div className="blobscard-purchase-content01-box-detail">{string.blobscard.purchaseContent01Box01Explain}</div>
              </div>
              <div className="blobscard-purchase-content01-box">
                <div className="blobscard-purchase-content01-box-number">02</div>
                <div className="blobscard-purchase-content01-box-title">{string.blobscard.purchaseContent01Box02Title}</div>
                <div className="blobscard-purchase-content01-box-detail">{string.blobscard.purchaseContent01Box02Explain}</div>
              </div>
              <div className="blobscard-purchase-content01-box">
                <div className="blobscard-purchase-content01-box-number">03</div>
                <div className="blobscard-purchase-content01-box-title">{string.blobscard.purchaseContent01Box03Title}</div>
                <div className="blobscard-purchase-content01-box-detail">{string.blobscard.purchaseContent01Box03Explain}</div>
              </div>
              <div className="blobscard-purchase-content01-box">
                <div className="blobscard-purchase-content01-box-number">04</div>
                <div className="blobscard-purchase-content01-box-title">{string.blobscard.purchaseContent01Box04Title}</div>
                <div className="blobscard-purchase-content01-box-detail">{string.blobscard.purchaseContent01Box04Explain}</div>
              </div>
            </div>
          </div>

          <div className="ethereumcard-purchase-slash">
            <div className="ethereumcard-purchase-content02-title">Detail</div>
          </div>

          <div className="ethereumcard-purchase-content02-content-wrapper">
            <div className="blobscard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/blobscard/purchase_content02_icon01.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain01}}>
              </div>
            </div>
            <div className="blobscard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/blobscard/purchase_content02_icon02.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain02}}>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain03}}>
              </div>
            </div>
            <div className="blobscard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/blobscard/purchase_content02_icon03.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain04}}>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain05}}>
              </div>
            </div>
            <div className="blobscard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/blobscard/purchase_content02_icon04.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain06}}>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain07}}>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain08}}>
              </div>
            </div>
            <div className="blobscard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/blobscard/purchase_content02_icon05.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain09}}>
              </div>
            </div>
            <div className="blobscard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/blobscard/purchase_content02_icon06.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain10}}>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent02Explain11}}>
              </div>
            </div>
          </div>
          <div className="blobscard-purchase-content03-wrapper">
            <div className="blobscard-purchase-content03-title01">{string.blobscard.purchaseContent03Title01}</div>
            <div className="blobscard-purchase-content03-title02">{string.blobscard.purchaseContent03Title02}</div>
            <div className="blobscard-purchase-content03-explain">{string.blobscard.purchaseContent03Explain01}</div>
            <div className="blobscard-purchase-content03-explain">{string.blobscard.purchaseContent03Explain02}</div>
            <div className="blobscard-purchase-content03-explain">{string.blobscard.purchaseContent03Explain03}</div>
          </div>

          <div className="ethereumcard-purchase-slash">
            <div className="ethereumcard-purchase-content02-title ">Why Blobs</div>
          </div>

          <div className="blobscard-purchase-content04-title01">{string.blobscard.purchaseContent04Title01}</div>
          <div className="blobscard-purchase-content04-title02" dangerouslySetInnerHTML={{__html: string.blobscard.purchaseContent04Title02}}></div>
          <div className="blobscard-purchase-content04-image desk"><img src={require('../img/blobscard/purchase_content04_table.png')}/></div>
          <div className="blobscard-purchase-content04-image mobile"><img src={require('../img/blobscard/purchase_content04_table_m.png')}/></div>

          <div className="ethereumcard-purchase-slash" style={{height: '20px'}}>
          </div>

          <div className='blobscard-purchase-content05-wrapper'>
            <div className="support-sub-title">{string.blobscard.purchaseContent05Title}</div>
            <div className='blobscard-purchase-content05'>
              <div className='blobscard-purchase-content05-item'>
                <div><img src={require('../img/blobscard/purchase_content05_image01.png')}/></div>
                <div>{string.blobscard.purchaseContent05Explain01}</div>
              </div>
              <div className='blobscard-purchase-content05-item'>
                <div><img src={require('../img/blobscard/purchase_content05_image02.png')}/></div>
                <div>{string.blobscard.purchaseContent05Explain02}</div>
              </div>
              <div className='blobscard-purchase-content05-item'>
                <div><img src={require('../img/blobscard/purchase_content05_image03.png')}/></div>
                <div>{string.blobscard.purchaseContent05Explain03}</div>
              </div>
              <div className='blobscard-purchase-content05-item'>
                <div><img src={require('../img/blobscard/purchase_content05_image04.png')}/></div>
                <div>{string.blobscard.purchaseContent05Explain04}</div>
              </div>
            </div>

          </div>

          <div className='blobscard-purchase-content06-wrapper'>
            <div className="support-sub-title">{string.blobscard.purchaseContent06}</div>
            <div className='blobscard-purchase-content06-container'>
              <div className='blobscard-purchase-content06-item'><img src={require('../img/blobscard/purchase_content06_image.png')}/></div>
              <div className='blobscard-purchase-content06-item'>
                <li>{string.blobscard.purchaseContent06Explain01}</li>
                <div>{string.blobscard.purchaseContent06Explain01Detail01}</div>
                <div>{string.blobscard.purchaseContent06Explain01Detail02}</div>
                <li>{string.blobscard.purchaseContent06Explain02}</li>
                <div>{string.blobscard.purchaseContent06Explain02Detail01}</div>
                <div>{string.blobscard.purchaseContent06Explain02Detail02}</div>
                <li>{string.blobscard.purchaseContent06Explain03}</li>
                <div>{string.blobscard.purchaseContent06Explain03Detail01}</div>
                <div>{string.blobscard.purchaseContent06Explain03Detail02}</div>
                <div>{string.blobscard.purchaseContent06Explain03Detail03}</div>
                <div>{string.blobscard.purchaseContent06Explain03Detail04}</div>
                <div>{string.blobscard.purchaseContent06Explain03Detail05}</div>
                <div>{string.blobscard.purchaseContent06Explain03Detail06}</div>
              </div>
            </div>
          </div>

          <div className='blobscard-purchase-content07-wrapper'>
            <div className="support-sub-title">{string.blobscard.purchaseContent07}<span class="blobscard-purchase-content07-explain">{string.blobscard.purchaseContent07Explain}</span></div>
            <div className='blobscard-purchase-content07-container'>
              <img src={require('../img/blobscard/purchase_content07_image.png')}/>
            </div>
          </div>

        </div>
        )}
        {this.state.tab==1 && (
        <div className="container" style={{textAlign: 'center'}}>
          <div className="blobscard-purchase-coin-title">
            <div className="blobscard-purchase-coin-title-01">{string.blobscard.purchaseCoinTitle01}</div>
            <div className="blobscard-purchase-coin-title-02">{string.blobscard.purchaseCoinTitle02}</div>
          </div>
          <div className="blobscard-purchase-coin-explain-01" dangerouslySetInnerHTML={{__html: string.blobscard.purchaseCoinExplain01}}></div>
          <div className="blobscard-purchase-coin-explain-02">{string.blobscard.purchaseCoinExplain02}</div>
          {this.renderSupprotCoin()}
        </div>
        )}
      </div>
    );
  }
}
