const string = {
  common: {
    menu01: "블랍스 카드",
    menu02: "이더리움 카드",
    menu03: "고객지원",
    submenu01: "블랍스 카드 구매",
    submenu02: "이더리움 카드 구매",
    footer01: "(주) 블랍스   |   대표 유형석",
    footer02:
      "서울시 서초구 남부순환로 2469 도요빌딩 5층   |   사업자등록번호 883-88-01187   |   2018-서울송파-2167",
    footer03:
      "Email: support@blobs.kr   |   고객센터 : 02-423-4261 (운영시간 : 10:00~18:00)",

    purchaseDetail01: "국내/해외배송",
    purchaseDetail02: "배송방법",
    purchaseDetail03: "배송비",
    purchaseDetail04: "결제수단",
    purchaseDetail05: "수량선택",
    purchaseDetail06: "총 상품 금액",
    purchaseDetail07: "(수량)",
    purchaseDetail08: "원",
    purchaseDetail09: "개",
    purchaseDetailButton: "결제하기",
  },
  coin: {
    coinName01: "블랍스",
    coinName02: "비트코인",
    coinName03: "이더리움",
    coinName04: "리플",
    coinName05: "이오스-메인넷전환중",
    coinName06: "라이트코인",
    coinName07: "트론-메인넷전환중",
    coinName08: "비체인-메인넷전환중",
    coinName09: "오미세고",
    coinName10: "아이콘-메인넷전환중",
    coinName11: "비앤비",
    coinName12: "질리카",
    coinName13: "제로엑스",
    coinName14: "애터니티",
    coinName15: "어거",
    coinName16: "골렘",
    coinName17: "스테이터스네트워크토큰",
    coinName18: "모나코",
    coinName19: "룸네트워크",
    coinName20: "카이버네트워크",
    coinName21: "테조스-오픈예정",
    coinName22: "에쏘스",
    coinName23: "파워렛저",
    coinName24: "스톰",
    coinName25: "텐엑스페이토큰",
    coinName26: "솔트",
    coinName27: "시빅",
    coinName28: "펀디엑스",
    coinName29: "베이직어텐션토큰",
    coinName30: "후오비토큰",
    coinName31: "크레딧",
    coinName32: "겜블팡",
    coinName33: "채린",
    coinName34: "자이러스",
    coinName35: "에프케이",
    coinName36: "엠블",
    coinName37: "마다",
    coinName38: "케나프",
    coinName39: "썬코인",
    coinName40: "밋토큰",
    coinName41: "테라리움",
  },
  support: {
    mainTitle: "블랍스 고객지원",
    mainTitleEng: "Blobs Customer Support",
    categoryNotice: "공지사항",
    categoryNoticeEng: "Announcement",
    categoryFaq: "자주 묻는 질문",
    categoryFaqEng: "FAQ",
    categoryContact: "문의하기",
    categoryContactEmail: "support@blobs.kr",
    categoryTel: "전화문의",
    categoryTelExplain01: "02-423-4261",
    categoryTelExplain02: "운영시간 : 10:00~18:00",

    faqTableCol01: "번호",
    faqTableCol02: "제목",
    faqTableCol03: "등록일",

    noticeTableCol01: "번호",
    noticeTableCol02: "제목",
    noticeTableCol03: "등록일",
    noticeTableCol04: "조회",

    noticeCategory01: "공지",
    noticeCategory02: "이벤트",
    noticeCategory03: "뉴스",
  },
  blobscard: {
    mainTitle: "Blobs Card Wallet",
    mainTitleExplain:
      "블랍스 카드 월렛에 암호화된 <b>Private Key</b>를 저장해 <br/>안전하게 코인을 보관하세요.",
    mainTitleButton01: "블랍스 카드 월렛 구매",
    mainTitleButton02: "블랍스 앱 다운로드",

    mainContent01Title: "<b>해킹 위험 없이 안전하게</b><br/>코인을 지키세요.",
    mainContent01Explain:
      "서버에 데이터를 저장하지 않고 암호화된 Private Key를 카드에 저장해 해킹 위험에서 해방됩니다.",
    mainContent02Title: "<b>저렴한 수수료</b>로<br/>빠르게 코인을 전송하세요.",
    mainContent02Explain:
      "별도의 인증절차 없이 자동으로 최적의 코인 전송 속도를 찾아내어 수 초만에 전송되며, 전송 시간에 따른 최적의 수수료를 계산해 매우 저렴합니다.",
    mainContent03Title: "<b>스마트폰으로 손쉽게</b><br/>사용하세요.",
    mainContent03Explain:
      "사용자 중심의 인터페이스로 스마트폰을 이용해 누구나 손쉽게 사용할 수 있습니다.",
    mainContent04Title: "<b>언제 어디서나<br/>간편하게</b> 보관하세요.",
    mainContent04Explain:
      "신용카드와 동일한 크기와 디자인으로 휴대성과 보관성이 뛰어납니다.",
    mainContent05Title: "<b>하나의 카드에<br/>다양한 코인을</b>보관하세요.",
    mainContent05Explain:
      "향후 200여종의 코인을 지원할 예정으로 하나의 카드에 다양한 코인을 보관하세요.",

    purchaseTitle: "블랍스 카드",
    purchaseTitleEng: "Blobs Card Wallet",
    purchaseTitleExplain: "백업카드 포함 총 2장",

    purchaseDetail01: "국내배송",
    purchaseDetail02: "우체국등기",
    purchaseDetail03: "무료",
    purchaseDetail04: "무통장 입금, 카드 결제, 실시간 계좌이체",

    appDownAlert: "모바일에서 다운로드 할 수 있습니다.",

    purchaseContentTab01: "제품상세",
    purchaseContentTab02: "지원코인",

    purchaseCoinTitle01: "블랍스 카드",
    purchaseCoinTitle02: "지원 코인",
    purchaseCoinExplain01:
      "블랍스 하이브리드 콜드월렛 카드는 <b>하나의 카드로 지원하는 모든 코인의 자산을 관리</b>할 수 있고<br/><b>현재 30종의 코인을 지원</b>하고 있으며 <b>차후 200여종의 코인을 지원할 예정</b>입니다. ",
    purchaseCoinExplain02:
      "(카드 구매 이후 추가되는 코인은 앱의 업데이트만으로 카드지갑에 적용가능 합니다.)",

    purchaseContent01Title: "Blobs Card Wallet",
    purchaseContent01Explain01: "최근 빈번하게 발생하고 있는 거래소 해킹과",
    purchaseContent01Explain02: "개인 PC 해킹의 위협으로 부터 ",
    purchaseContent01Explain03:
      "코인을 안전하게 보호하고, 쉽고 빠르게 거래합니다.",
    purchaseContent01Box01Title: "안전하다",
    purchaseContent01Box01Explain: "암호화된 Private Key를 카드에 저장",
    purchaseContent01Box02Title: "간편하다",
    purchaseContent01Box02Explain: "스마트폰에서 터치 한번에 작동",
    purchaseContent01Box03Title: "빠르다",
    purchaseContent01Box03Explain: "블록체인 상태에 따른 최적의 수수료",
    purchaseContent01Box04Title: "저렴하다",
    purchaseContent01Box04Explain: "타사 대비 경제적인 구매 가격",

    purchaseContent02Explain01:
      '<b><span class="point-text">블록체인 기술</span></b>로 <b>Private Key</b>는 <b>카드에 보관</b>되기 때문에 서버의 보안문제가 발생하여도 <b>지갑의 자산은 안전</b>합니다.',

    purchaseContent02Explain02:
      '스마트폰의 <b><span class="point-text">지문인식 기능</span></b>을 활용하여 더 <b>강력한 보안</b>을 제공합니다.',
    purchaseContent02Explain03:
      '카드에 저장된 개인키 등은 <b><span class="point-text">암호화</span></b>되어 분실하더라도 <b>안전하게 보호</b>됩니다.',

    purchaseContent02Explain04:
      '<b><span class="point-text">NFC 방식</span></b>으로 스마트폰 터치만으로 <b>간편하게 작동</b>합니다. ',
    purchaseContent02Explain05:
      '<b>신규 암호화폐 추가</b> 시 <b><span class="point-text">무료 앱 업데이트</span></b>로 간편하게 지갑에 추가할 수 있습니다.',

    purchaseContent02Explain06:
      '<b><span class="point-text">실시간 트랜잭션 분석</span></b>을 통해 <b>최적의 수수료</b>를 찾아줍니다.',
    purchaseContent02Explain07:
      '거래소와 달리 <b>블랍스</b>에서는 <b><span class="point-text">거래 수수료</span></b>를 받지 않습니다.',
    purchaseContent02Explain08: "타사 대비 <b>단가가 저렴</b>합니다.",

    purchaseContent02Explain09:
      '<b><span class="point-text">블록체인의 트랜잭션 과부하</span></b> 시에도 <b>빠른 전송</b>을 할 수 있도록 지원합니다.',

    purchaseContent02Explain10:
      '<b><span class="point-text">일반 신용카드와 동일한 형태</span></b>로 지갑에 <b>편리하게 휴대</b>가 가능합니다.',
    purchaseContent02Explain11:
      '배터리를 사용하지 않아 <b><span class="point-text">반영구적으로 사용</span></b>이 가능합니다.',

    purchaseContent03Title01: "안전하고 편리한",
    purchaseContent03Title02: "Blobs Card Wallet",
    purchaseContent03Explain01: "안전을 최우선으로 하여",
    purchaseContent03Explain02:
      "누구나 쉽고 편하게 이용할 수 있는 블랍스 카드.",
    purchaseContent03Explain03: "여러분의 라이프를 업그레이드 하세요.",

    purchaseContent04Title01:
      "기존 핫 월렛의 해킹 위험성과 콜드 월렛의 불편함을 보완한",
    purchaseContent04Title02: "3세대 카드형 지갑, <b>블랍스 카드</b>",

    purchaseContent05Title: "블랍스 카드 구성품",
    purchaseContent05Explain01: "카드 (백업카드 포함 2장)",
    purchaseContent05Explain02: "카드케이스",
    purchaseContent05Explain03: "매뉴얼",
    purchaseContent05Explain04: "복구단어 기입장",

    purchaseContent06: "블랍스 카드 사양서",
    purchaseContent06Explain01: "카드규격",
    purchaseContent06Explain01Detail01: "신용카드와 동일",
    purchaseContent06Explain01Detail02: "85.5mm(w) x 54mm(h) x 0.8mm(t)",
    purchaseContent06Explain02: "지원하는 스마트폰",
    purchaseContent06Explain02Detail01:
      "Android Marshmallow 이상 및 NFC 지원 필수",
    purchaseContent06Explain02Detail02: "iOS 7 이상 및 NFC 지원 필수",
    purchaseContent06Explain03: "적용 암호 알고리즘",
    purchaseContent06Explain03Detail01: "Public Key Cryptography Standards",
    purchaseContent06Explain03Detail02:
      "Password-based Key Derivation Function 2",
    purchaseContent06Explain03Detail03: "ISO/IEC 18033-3",
    purchaseContent06Explain03Detail04: "ISO/IEC 9797-2",
    purchaseContent06Explain03Detail05: "ISO/IEC 10118-3",
    purchaseContent06Explain03Detail06: "ISO/IEC 18033-2",

    purchaseContent07: "인증 및 특허",
    purchaseContent07Explain: "인증 및 특허 ISO-9001 Q280718",
  },
  ethereumcard: {
    mainTitle: "Ethereum Card",
    mainTitleExplain:
      "블랍스 이더리움 카드로 암호화폐와 함께<br/>새로운 라이프를 시작하세요.",
    mainTitleButton01: "이더리움 카드 구매",
    mainTitleButton02: "카드 앱 다운로드",

    mainContent01Title:
      "<b>암호화폐</b>를 <b>다양하게</b><br/><b>활용</b>하세요.",
    mainContent01Explain:
      "이더리움이 담긴 카드로 암호화폐를 편리하게 사용하세요.",
    mainContent01Explain2:
      "※ 한 장의 카드에는 1이더리움(1ETH)이 담겨 있습니다.",
    mainContent02Title:
      "<b>누구나 쉽게 암호화폐</b>를<br/> <b>전송</b>할 수 있어요.",
    mainContent02Explain:
      "카드에는 NFC칩이 내장되어 있어, 스마트폰 태깅만으로도 앱 설치부터 전송까지 한번에 가능합니다.",
    mainContent03Title:
      "<b>암호화폐</b>가 담긴 <b>카드</b>로<br/> <b>특별한 마음</b>을 전하세요.",
    mainContent03Explain:
      "별도의 작업 없이도 암호화폐를 누구에게나 쉽게 전달할 수 있습니다. ",
    mainContent03Explain2:
      "※ 암호화폐 월렛이 없는 지인에게도 암호화폐를 선물하는 ‘새로운 트렌드’를 제시합니다.  ",
    mainContent04Title:
      "<b>해킹 위험 없이 안전하게</b><br/>자산을 관리하세요. ",
    mainContent04Explain:
      "서버에 데이터를 저장하지 않고 암호화된 Private key를 카드에 저장해 해킹의 위험에서 해방됩니다. 자산을 소유하고 관리하는 새로운 세계를 열어드립니다. ",

    purchaseTitle: "이더리움 카드",
    purchaseTitleEng: "Ethereum Card",
    purchaseTitleExplain: "1 이더리움(ETH) 포함",

    purchaseDetail01: "국내배송",
    purchaseDetail02: "우체국등기",
    purchaseDetail03: "무료",
    purchaseDetail04: "무통장 입금",

    purchaseContent01Title: "Ethereum Card",
    purchaseContent01Explain01:
      "자산(암호화폐)을 소유하고 관리하는 새로운 트렌드",
    purchaseContent01Explain02: "개인 소장용, 선물용으로 활용하세요.",
    purchaseContent01Box01Title: "안전하다",
    purchaseContent01Box01Explain: "이더리움을 암호화하여 실물 카드에 저장",
    purchaseContent01Box02Title: "간편하다",
    purchaseContent01Box02Explain: "스마트폰 태깅으로 이더리움 전송",
    purchaseContent01Box03Title: "새롭다",
    purchaseContent01Box03Explain: "국내 최초의 이더리움 선불카드",
    purchaseContent01Box04Title: "특별하다",
    purchaseContent01Box04Explain: "암호화폐를 선물하는 특별함",

    purchaseContent02Explain01:
      '<b>이더리움카드에는</b> 암호화된<br/> <b><span class="point-text">1 이더리움(ETH)</span></b>이 담겨 있습니다.',
    purchaseContent02Explain02:
      '<b><span class="point-text">이더리움 월렛 앱</span></b>을 <b>다운</b> 받아<br/> 이더리움을 옮겨 담으세요.',
    purchaseContent02Explain03:
      '<b>이더리움 월렛 앱</b>에서 <b><span class="point-text">다른 이더리움<br/> 월렛 주소로 전송</span></b> 가능합니다.',
    purchaseContent02Explain04:
      '<b><span class="point-text">스마트폰에 카드 태깅</span></b>만으로<br/> <b>손쉽게 이용</b>할 수 있습니다.',
    purchaseContent02Explain05:
      '<b><span class="point-text">선불카드의 개념</span></b>으로<br/> <b>다양하게 활용</b>하실 수 있습니다.',
    purchaseContent02Explain06:
      '<b><span class="point-text">신용카드와 동일한 사이즈</span></b>로<br/> <b>휴대가 간편</b>합니다. ',

    purchaseContent03Title: "이더리움 카드 구성품",
    purchaseContent03Explain01: "카드 (1이더리움(ETH) 포함 1장)",
    purchaseContent03Explain02: "카드케이스",

    purchaseContent04: "이더리움 카드 사양서",
    purchaseContent04Explain01: "카드규격",
    purchaseContent04Explain01Detail01: "신용카드와 동일",
    purchaseContent04Explain01Detail02: "85.5mm(w) x 54mm(h) x 0.8mm(t)",
    purchaseContent04Explain02: "지원하는 스마트폰",
    purchaseContent04Explain02Detail01:
      "Android Marshmallow 이상 및 NFC 지원 필수",
    purchaseContent04Explain02Detail02: "iOS 7 이상 및 NFC 지원 필수",
  },

  /*********공통**********/
  hybridColdWallet: "하이브리드 콜드월렛",
  blobs: "블랍스",
  membership: "맴버쉽",
  currencyUnit: "원",
  confirm: "확인",
  cancle: "취소",
  modify: "수정",
  index: "번호",
  status: "상태",
  delete: "삭제",
  list: "목록",
  close: "닫기",
  year: "년",
  month: "월",
  day: "일",
  complete: "완료",
  nonMembers: "비회원",
  name: "이름",
  nameRule: "이름을 입력해 주세요!",
  contact: "연락처",
  contactRule: "연락처를 입력해 주세요!",
  email: "이메일",
  id: "아이디(이메일)",
  idSave: "아이디저장",
  emailExtra: "배송정보를 안내받을 이메일을 입력해주세요.",
  emailRule01: "유효하지 않은 이메일입니다.",
  emailRule02: "이메일을 입력해 주세요!",
  emailRule03: "이메일 형식을 입력해주세요.",
  emailRule04: "이메일 주소를 입력해주세요.",
  address: "주소",
  addressRule: "주소를 입력해 주세요!",
  addressRuleMsg01: "주소 형식이 잘못되었습니다.",

  addressDetail: "상세주소",
  addressDetailRule: "상세주소를 입력해 주세요!",
  passWord: "비밀번호",
  passWordRule: "비밀번호를 입력해 주세요.",
  passWordRule01: "현재 비밀번호를 입력해 주세요.",
  passWordConfirm: "비밀번호 확인",
  priceUnit: "원",
  quantityUnit: "개",
  quantity: "수량",
  orderNumber: "주문번호",
  date: "날짜",
  productInfo: "상품정보",
  deliveryAddress: "배송지",
  memo: "메모",
  reciever: "수취인",
  blobsAgreement: "(필수) 블랍스 이용약관",
  pursonalInfoAgree: "(필수) 개인정보 수집 및 이용",
  agreement: "이용약관",
  showAgreement: "약관보기",
  logIn: "로그인",
  logOut: "로그아웃",
  logOutMsg: "로그아웃 하시겠습니까?",
  welcome: "블랍스 방문을 환영합니다.",
  welcomeMsg: "블랍스에서 제공하는 서비스를 만나보세요.",
  thanksForUse: "블랍스를 이용해 주셔서 감사합니다.",
  agreementAgree: "약관동의",
  agreementAgreeMsg: "약관에 동의해 주세요.",
  sir: "님",
  /*********signUp**********/
  signUp: "회원가입",
  signUpMsg01: "회원가입 요청이 완료되었습니다.",
  signUpMsg02: "가입하신 아이디(이메일)로 인증메일이 발송되었습니다.",
  signUpMsg03: "메일인증을 완료 후 로그인 하실 수 있습니다.",
  signUpRuleMsg01: "이미 가입되어 있는 이메일 주소입니다.",
  signUpRuleMsg02: "이메일 형식이 잘못되었습니다.",
  signUpRuleMsg03: "패스워드 복잡도를 만족하지 못하였습니다.",
  signUpRuleMsg04: "가입 처리 중 알수없는 오류가 발생하였습니다.",
  signUpRuleMsg05: "입력한 두개의 비밀번호가 다릅니다.",
  signUpRuleMsg06: "약관에 동의해 주세요.",
  signUpRuleMsg07:
    "비밀번호는 영문, 숫자, 특수문자를 조합하여 8자~15자로 만들 수 있습니다.",
  signUpFail: "회원가입에 실패하였습니다.",
  signUpCancleMsg: "회원가입을 취소하고 메인페이지로 이동하시겠습니까?",
  signUpAgreement: "개인정보 수집 및 이용",
  signUpOtherLoginMsg:
    '<span style="color:#00aab6; font-weight:bold;">네이버</span>나 <span style="color:#00aab6; font-weight:bold;">카카오</span> 로그인을 사용하시면 <span style="color:#00aab6; font-weight:bold;">별도의 회원가입 없이 이용</span>하실 수 있습니다.',
  /*********logIn**********/
  logIn: "로그인",
  logOut: "로그아웃",
  logOutMsg: "로그아웃 하시겠습니까?",
  logInFailMsg01: "로그인에 실패하였습니다.",
  logInFailMsg02: "로그인 중 알수없는 오류가 발생하였습니다.",
  logInFailMsg03: "잠시 후 다시 시도해주세요.",
  logInInvalidLogInInfo: "아이디 또는 비밀번호를 잘못 입력하였습니다.",
  logInEmailUnverified01: "메일인증이 완료되지 않았습니다.",
  logInEmailUnverified02: "이메일을 확인한 후 메일인증을 먼저 진행해 주세요.",
  logInFindPassword: "비밀번호 찾기",
  logInFindPasswordMsg01:
    "가입하신 아이디(이메일)로 비밀번호를 변경할 수 있는 링크를 발송해 드립니다.",
  logInFindPasswordMsg02: "가입하신 아이디를 입력해주세요.",
  logInPasswordModifyMsg01: "비밀번호 변경 메일이 발송되었습니다.",
  logInPasswordModifyMsg02: "메일을 확인해주세요.",
  logInPasswordModifyFailMsg01: "비밀번호 변경 메일이 발송에 실패하였습니다.",
  logInPasswordModifyFailMsg02:
    "메일 주소가 정확한지 확인한 후 다시 시도해주세요.",

  /*********AgreementApp**********/
  agreementApp01: "제1장 총칙",
  agreementApp02: "제1조 (목적)",
  agreementApp03:
    "이 약관은 주식회사 블랍스(이하: 회사)가 제공하는 앱 및 카드에서 제공하는 서비스(이하: 서비스)의 이용조건 및 절차에 관한 회사와 사용자 간의 권리 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.",
  agreementApp04: "제2조 (약관의 명시, 설명과 개정)",
  agreementApp05:
    "①이 약관의 내용은 회사의 서비스 등 기타의 방법으로 사용자에게 공지하고, 사용자가 이 약관에 동의함으로써 효력을 발생합니다.",
  agreementApp06:
    "②회사는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.",
  agreementApp07:
    "③회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 회사 사이트의 초기화면이나 팝업화면 또는 공지사항으로 그 적용일자 7일 이전부터 공지합니다. 다만, 사용자에게 불리하거나 중대한 내용의 변경의 경우에는 적용일자 30일 이전에 사용자에게 알립니다.",
  agreementApp08:
    "④회사가 전항에 따라 개정약관을 공지하면서 사용자에게 7일간의 기간 내에 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 사용자가 명시적으로 거부의 의사표시를 하지 아니한 경우 사용자가 개정약관에 동의한 것으로 봅니다.",
  agreementApp09:
    "⑤사용자가 개정약관의 적용에 동의하지 않는다는 명시적 의사를 표명한 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 사용자는 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.",
  agreementApp10: "제3조 (약관 외 준칙)",
  agreementApp11:
    "회사는 서비스에 대하여 이 약관 외에 별도의 공지사항, 개별 약관, 개별 동의 사항, 이용안내, 운영정책, 세부지침 등(이하 통틀어 “세부지침”이라 합니다)을 정하여 이를 사용자에게 알리거나 동의를 받을 수 있으며, 세부지침 중 사용자의 동의를 받은 내용이 이 약관과 상충할 경우에는 “세부지침”이 우선하여 적용됩니다.",
  agreementApp12:
    "이 약관에서 정하지 아니한 사항이나 해석에 대해서는 “세부지침” 및 관련 법령 또는 상관례에 따릅니다.",
  agreementApp13: "제4조 (용어의 정의)",
  agreementApp14: "①이 약관에서 사용하는 용어의 정의는 다음과 같습니다.",
  agreementApp15:
    "1.암호화폐: 비트코인(BTC), 이더리움(ETH) 등 블록체인에서 전자적으로 존재하는 화폐를 말합니다.",
  agreementApp16:
    "2.카드: 회사가 제공하는 암호화폐를 담을 수 있는 신용카드 형태의 카드를 말합니다.",
  agreementApp17:
    "3.사용자: 이 약관을 승인하고 회사가 제공하는 카드를 등록한 이용자를 말합니다.",
  agreementApp18:
    "4.앱: 암호화폐를 저장하거나 송수신 할 수 있는 스마트폰 온라인 전용 프로그램을 말합니다.",
  agreementApp19:
    "5.지갑: 카드에 암호화폐를 저장 할 수 있는 가상의 공간을 말합니다.",
  agreementApp20:
    "6.비밀번호: 사용자와 카드간의 동일성 확인과 암호화폐 보안을 위하여 사용자가 설정한 숫자의 조합을 말합니다.",
  agreementApp21:
    "7.복구단어: 카드 분실시 복구할 수 있는 24개의 암호화된 단어를 말합니다.",
  agreementApp22: "제2장 서비스 이용 신청 및 승낙 (사용자가입 및 해지)",
  agreementApp23: "제5조 (이용계약의 성립)",
  agreementApp24:
    "①이용자는 회사가 제공하는 카드를 수령한 후 앱에 카드 등록시 이 약관에 동의한다는 의사표시를 함으로서 카드 사용을 신청합니다.",
  agreementApp25:
    "③이용 계약은 카드를 등록한 단위로 체결합니다. 이용계약이 성립되면, 이용 신청자는 카드 사용자으로 등록됩니다.",
  agreementApp26: "제6조 (이용신청)",
  agreementApp27:
    "①이용신청은 회사가 제공하는 카드를 수령한 후 앱에 카드 등록시 자동으로 이용신청이 승인됩니다.",
  agreementApp28: "제7조 (사용자정보 사용에 대한 동의 및 이용신청의 승낙)",
  agreementApp29: "①사용자정보 사용에 대한 동의",
  agreementApp30:
    "1.회사는 사용자의 개인정보를 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용합니다.",
  agreementApp31:
    "2.사용자가 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 사용자 정보는 회사와 제휴한 업체에 제공될 수 있습니다. 단, 회사는 사용자 정보 제공 이전에 제휴 업체, 제공 목적, 제공할 사용자 정보의 내용 등을 사전에 공지하고 사용자의 동의를 얻어야 합니다.",
  agreementApp32:
    "3.사용자는 사용자정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.",
  agreementApp33:
    "4.사용자가 이용신청서에 사용자정보를 기재하고, 회사에 본 약관에 따라 이용신청을 하는 것은 회사가 본 약관에 따라 이용신청서에 기재된 사용자정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.",
  agreementApp34: "제8조 (이용계약의 중지 및 해지)",
  agreementApp35:
    "①이용계약 및 카드사용의 해지는 사용자가 카드 등록시 인증한 메일주소로 메일 인증 후 해지가 가능합니다.",
  agreementApp36: "제9조 (사용자정보의 변경)",
  agreementApp37:
    "①사용자는 카드 보안을 위하여 임의로 개인정보(이메일주소)를 변경 할 수 없습니다.",
  agreementApp38: "제3장 사용자의 의무",
  agreementApp39: "제10조 (사용자 카드와 비밀번호 관리에 대한 사용자의 의무)",
  agreementApp40:
    "①카드와 비밀번호에 관한 모든 관리책임은 사용자에게 있습니다. 사용자가 등록한 카드와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 사용자에게 있으며, 회사는 이에 대한 책임을 지지 않습니다. 다만, 회사의 고의로 인한 경우에는 그러하지 아니합니다.",
  agreementApp41:
    "②24개의 복구 단어는 반드시 종이에 기입 해 놓아야 하며 카드를 분실하여 복구 할때 24개의 복구 단어도 분실하면 회사는 이 정보를 가지고 있지 않기 때문에 복구가 불가능한데 이때 모든 책임은 사용자에게 있으며 회사는 이에 대한 책임을 지지 않습니다. 또한 본인의 실수로 인해 24개의 복구 단어가 유출되어 입은 손실도 모든 책임은 사용자에게 있으며 회사는 이에 대한 책임을 지지 않습니다.",
  agreementApp42: "제11조 (정보의 제공)",
  agreementApp43:
    "회사는 유∙무선전화, 전자우편, 문자서비스(LMS/SMS), SNS 등의 방법으로 다음과 같은 서비스에 대해서 이용편의를 위한 이용안내 및 상품에 관한 정보를 제공할 수 있습니다. 이 경우 사용자는 언제든지 수신을 거부할 수 있습니다.",
  agreementApp44: "①이벤트 및 행사관련 등의 서비스",
  agreementApp45: "②기타 회사가 수시로 결정하여 사용자에게 제공하는 서비스",
  agreementApp46: "제4장 서비스 이용 총칙",
  agreementApp47: "제12조 (서비스의 종류)",
  agreementApp48:
    "①회사에서 제공하는 서비스에는 암호화폐를 저장하는 지갑기능, 다른 사람에게 암호화폐를 송수신 할수 있는 기능 등이 있습니다.",
  agreementApp49:
    "②회사가 제공하는 서비스의 종류는 회사의 사정에 의하여 수시로 변경될 수 있으며, 제공되는 서비스에 대한 저작권 및 지식재산권은 회사에 귀속됩니다.",
  agreementApp50:
    "③회사는 서비스와 관련하여 사용자에게 회사가 정한 이용조건에 따라 계정, 카드, 서비스, 포인트 등을 이용할 수 있는 이용권한만을 부여하며, 사용자는 이를 활용한 유사 서비스 제공 및 상업적 활동을 할 수 없습니다.",
  agreementApp51: "제13조 (서비스 내용의 공지 및 변경)",
  agreementApp52:
    "①회사는 서비스의 종류에 따라 각 서비스의 특성, 절차 및 방법에 대한 사항을 서비스 화면을 통하여 공지하며, 사용자는 회사가 공지한 각 서비스에 관한 사항을 이해하고 서비스를 이용해야 합니다.",
  agreementApp53:
    "②회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 개별 서비스의 전부 또는 일부를 변경할 수 있습니다. 이 경우 회사는 최소 7일전에 해당 내용을 이용자에게 알립니다.",
  agreementApp54: "제14조 (서비스의 유지 및 중지)",
  agreementApp55:
    "①서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 사전에 고지하여 정한 날이나 시간은 그러하지 않습니다.",
  agreementApp56:
    "②회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.",
  agreementApp57:
    "③회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다. 이 경우 회사는 불가피한 경우가 아닌 한 서비스 제공 중지를 사전 고지합니다.",
  agreementApp58: "1.서비스용 설비의 보수 등 공사로 인한 부득이한 경우",
  agreementApp59:
    "2.전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우",
  agreementApp60:
    "3.회사가 직접 제공하는 서비스가 아닌 제휴업체 등의 제3자를 이용하여 제공하는 서비스의 경우 제휴 업체등의 제3자가 서비스를 중지했을 경우",
  agreementApp61: "4.기타 불가항력적 사유가 있는 경우",
  agreementApp62:
    "④회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.",
  agreementApp63: "제7장 개인정보 보호",
  agreementApp64: "제15조 (개인정보의 보호)",
  agreementApp65:
    "회사는 관계 법령이 정하는 바에 따라 사용자의 개인정보를 보호하기 위해 노력합니다. 사용자의 개인정보는 서비스의 원활한 제공을 위하여 사용자가 동의한 목적과 범위 내에서만 이용됩니다. 회사는 법령에 의하거나 사용자가 별도로 동의하지 아니하는 한, 사용자의 개인정보를 제3자에게 제공하지 아니하며, 이에 대한 자세한 사항은 개인정보 처리방침에서 정합니다.",
  agreementApp66: "제8장 손해배상 및 면책조항",
  agreementApp67: "제16조 (회사의 면책사항 및 손해배상)",
  agreementApp68:
    "①회사는 서비스와 관련하여 이 약관에 명시되지 않은 어떠한 사항에 대하여 보증을 하지 않습니다. 또한, 회사는 회사가 발행하거나 지급을 보증하지 아니한 암호화폐의 가치를 보증하지 않습니다.",
  agreementApp69:
    "②회사는 천재지변, 디도스(DDos) 공격, IDC장애, 서비스 접속의 폭등으로 인한 서버 다운, 기간통신사업자의 회선 장애 등 기타 불가항력적인 사유로 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.",
  agreementApp70:
    "③회사는 블록체인의 문제, 암호화폐 발행 관리 시스템 자체의 하자 또는 기술적 문제, 통신서비스 업체의 불량, 정기적인 서버점검 등으로 인하여 불가피하게 장애가 발생하였을 경우에 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.",
  agreementApp71:
    "④회사는 사용자의 귀책사유로 인한 서비스 이용 장애나 그 결과에 대하여는 책임을 지지 않습니다. 다만, 사용자에게 정당한 사유가 있는 경우에는 그러하지 아니합니다.",
  agreementApp72:
    "⑤회사는 사용자간 또는 사용자과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.",
  agreementApp73:
    "⑥회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.",
  agreementApp74:
    "⑦회사는 안정적인 서비스 제공을 위해 정기적, 비정기적 또는 긴급한 서버 점검을 실시할 수 있습니다.",
  agreementApp75:
    "⑧누구든지 서비스 오류, 전산장애 또는 기타 사유로 인하여 제3자 소유의 암호화폐를 권한 없이 취득하거나 전송 받게 될 경우, 회사는 당사자에게 사전 통지 후 해당 암호화폐를 회수하거나 원상회복 시키는 등 필요한 조치를 취할 수 있습니다.",
  agreementApp76: "제23조 (관할법원 및 준거법)",
  agreementApp77:
    "①본 서비스 이용 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사의 요금체계 등 서비스 이용과 관련하여 회사와 사용자 간에 발생한 분쟁으로 소송이 제기되는 경우 대한민국 법률이 적용됩니다.",
  agreementApp78:
    "②서비스 이용과 관련하여 회사와 사용자 간에 발생한 소송의 경우 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.",
  agreementApp79: "부칙",
  agreementApp80: "①이 약관은 2018년 7월 28일부터 적용됩니다.",

  /*********AgreementSub1**********/
  agreementSub1Content001: "제 1 조 (목적)",
  agreementSub1Content002:
    "본 약관은 쇼핑몰을 운영하는 회사 (이하 ‘사이트’이라 한다)에서 제공하는 인터넷 관련 서비스(이하 ‘서비스’라 하며, 접속 가능한 유,무선 단말기의 종류와 상관없이 이용 가능한 사이트가 제공하는 모든 서비스를 의미합니다.)를 이용함에 있어 회원의 권리•의무 및 책임사항을 규정함을 목적으로 합니다.",
  agreementSub1Content003: "제 2 조 (정의)",
  agreementSub1Content004:
    "1. ‘사이트’ 란 ‘회사’가 상품을 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 ‘상품 등’을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 사이버몰을 운영하는 사업자의 의미로도 사용합니다. 현재 ‘회사’가 운영하는 ‘사이트’는 http://blobs.kr 이며, 더불어 서비스 하는 안드로이드, iOS 환경의 서비스를 포함한 모바일웹과 앱을 포함 합니다.",
  agreementSub1Content005:
    "2. ‘회원’이라 함은 ‘사이트’에 개인정보를 제공하여 회원등록을 한 자로서, ‘사이트’에 정해진 회원 가입 방침에 따라 ‘사이트’의 정보를 지속적으로 제공받으며, ‘사이트’가 제공하는 ‘서비스’를 계속적으로 이용할 수 있는 자를 말합니다.",
  agreementSub1Content006:
    "3. ‘비밀번호’라 함은 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 ‘사이트’에 등록한 영문과 숫자의 조합을 말합니다.",
  agreementSub1Content007:
    "4. 본 약관에서 정의되지 않은 용어는 관계법령이 정하는 바에 따르며, 그 외에는 일반적인 상관례에 의합니다.",
  agreementSub1Content008: "제 3 조 (약관의 명시와 설명 및 개정)",
  agreementSub1Content009:
    "1. ‘회사’는 이 약관의 내용과 상호 및 대표자 이름, 소재지 주소, 전화번호, 전자우편주소, 사업자등록번호 등을 회원이 쉽게 알 수 있도록 ‘사이트’의 초기 ‘서비스’ 화면에 게시합니다. 다만, 약관의 구체적 내용은 회원이 연결화면을 통하여 볼 수 있습니다.",
  agreementSub1Content010:
    "2. ‘회사’는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제 등에 관한 법률』, 『전자거래 기본법』, 『전자 서명법』, 『정보통신망 이용촉진 등에 관한 법률』, 『소비자기본법』 등 관계법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.",
  agreementSub1Content011:
    "3. ‘회사’가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 ‘사이트’의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.",
  agreementSub1Content012:
    "4. ‘회사’가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 회원이 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 ‘회사’에 송신하여 ‘회사’의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.",
  agreementSub1Content013:
    "5. 제3항에 따라 공지된 적용일자 이후에 회원이 ‘회사’의 ‘서비스’를 계속 이용하는 경우에는 개정된 약관에 동의하는 것으로 봅니다. 개정된 약관에 동의하지 아니하는 회원은 언제든지 자유롭게 ‘서비스’ 이용계약을 해지할 수 있습니다.",
  agreementSub1Content014: "제 4 조 (약관 외 준칙)",
  agreementSub1Content015:
    "이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 『전자상거래 등에서의 소비자 보호에 관한 법률』 등 관련법령의 규정과 일반 상관례에 따릅니다.",
  agreementSub1Content016: "제 5 조 (이용계약의 성립)",
  agreementSub1Content017:
    "1. 이용계약은 회원이 되고자 하는 자(이하 ‘가입신청자’)가 약관의 내용에 동의를 하고, ‘회사’가 정한 가입양식에 따라 회원정보(전자우편주소, 비밀번호, or 소셜네트워크를 통한 회원가입 정보 등)를 기입하여 회원가입신청을 하고 ‘회사’가 이러한 신청에 대하여 승인함으로써 체결됩니다.",
  agreementSub1Content018:
    "2. ‘회사’는 다음 각 호에 해당하는 신청에 대하여는 승인을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.",
  agreementSub1Content019:
    "가. ‘가입신청자’가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 다만, 회원자격 상실 후 3개월이 경과한 자로서 ‘회사’의 회원 재가입 승낙을 얻은 경우에는 예외로 함",
  agreementSub1Content020:
    "나. 등록내용에 허위의 정보를 기재하거나, 기재누락, 오기가 있는 경우",
  agreementSub1Content021: "다. 회원가입일 현재 만 14세 미만인 경우",
  agreementSub1Content022:
    "라. 이미 가입된 회원과 이름 및 전자우편주소가 동일한 경우",
  agreementSub1Content023:
    "마. 부정한 용도 또는 영리를 추구할 목적으로 본 ‘서비스’를 이용하고자 하는 경우",
  agreementSub1Content024:
    "바. 회원의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우",
  agreementSub1Content025:
    "사. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 ‘회사’가 합리적인 판단에 의하여 필요하다고 인정하는 경우",
  agreementSub1Content026:
    "3. ‘회사’는 ‘서비스’ 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.",
  agreementSub1Content027:
    "4. 제2항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 이 경우 ‘회사’는 원칙적으로 이를 ‘가입신청자’에게 알리도록 합니다.",
  agreementSub1Content028:
    "5. 이용계약의 성립시기는 ‘회사’가 가입완료를 신청절차상에서 표시한 시점으로 합니다.",
  agreementSub1Content029:
    "6. ‘회사’는 회원에 대해 ‘회사’정책에 따라 등급별로 구분하여 이용시간, 이용횟수, ‘서비스’ 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.",
  agreementSub1Content030:
    "7. ‘회사’는 회원에 대하여 『영화 및 비디오물의 진흥에 관한 법률』 및 『청소년 보호법』 등에 따른 등급 및 연령준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.",
  agreementSub1Content031: "제 6 조 (개인정보의 변경)",
  agreementSub1Content032:
    "회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.",
  agreementSub1Content033: "제 7 조 (개인정보의 보호)",
  agreementSub1Content034:
    "1. ‘회사’는 회원의 개인정보를 보호하기 위하여 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 등 관계법령에서 정하는 바를 준수합니다.",
  agreementSub1Content035:
    "2. ‘회사’는 회원의 개인정보를 보호하기 위하여 개인정보취급방침을 제정, ‘서비스’ 초기화면에 게시합니다. 다만, 개인정보취급방침의 구체적 내용은 연결화면을 통하여 볼 수 있습니다.",
  agreementSub1Content036:
    "3. ‘회사’는 개인정보취급방침에 따라 회원의 개인정보를 최대한 보호하기 위하여 노력합니다.",
  agreementSub1Content037:
    "4. ‘회사’의 공식 ‘사이트’ 이외의 링크된 사이트에서는 ‘회사’의 개인정보취급방침이 적용되지 않습니다. 링크된 사이트 및 ‘상품 등’을 제공하는 제3자의 개인정보 취급과 관련하여는 해당 ‘사이트’ 및 제3자의 개인정보취급방침을 확인할 책임이 회원에게 있으며, ‘회사’는 이에 대하여 책임을 부담하지 않습니다.",
  agreementSub1Content038:
    "5. ‘회사’는 다음과 같은 경우에 법이 허용하는 범위 내에서 회원의 개인정보를 제3자에게 제공할 수 있습니다.",
  agreementSub1Content039:
    "가. 수사기관이나 기타 정부기관으로부터 정보제공을 요청 받은 경우",
  agreementSub1Content040:
    "나. 회원의 법령 또는 약관의 위반을 포함하여 부정행위확인 등의 정보보호업무를 위해 필요한 경우",
  agreementSub1Content041: "다. 기타 법률에 의해 요구되는 경우",
  agreementSub1Content042:
    "라. 판매자 또는 배송업체 등에게 거래 및 배송 등에 필요한 최소한의 회원의 정보(성명, 주소, 전화번호)를 제공하는 경우",
  agreementSub1Content043:
    "마. 구매가 성사된 때에 그 이행을 위하여 필요한 경우와 구매가 종료된 이후에도 반품, 교환, 환불, 취소 등을 위하여 필요한 경우",
  agreementSub1Content044: "제 8 조 (이용계약의 종료)",
  agreementSub1Content045: "1. 회원의 해지",
  agreementSub1Content046:
    "가. 회원은 언제든지 ‘회사’에게 해지의사를 통지함으로써 이용계약을 해지할 수 있습니다.",
  agreementSub1Content047:
    "나. 이용계약은 회원의 해지의사가 ‘회사’에 도달한 때에 종료됩니다.",
  agreementSub1Content048:
    "다. 본 항에 따라 해지를 한 회원은 이 약관이 정하는 회원가입절차와 관련조항에 따라 회원으로 다시 가입할 수 있습니다.",
  agreementSub1Content049: "2. 회사의 해지",
  agreementSub1Content050:
    "가. ‘회사’는 다음과 같은 사유가 있는 경우, 이용계약을 해지할 수 있습니다. 이 경우 ‘회사’는 회원에게 전자우편주소, 전화 등 기타의 방법을 통하여 해지사유를 밝혀 해지의사를 통지합니다. 다만 ‘회사’는 해당 회원에게 사전에 해지사유에 대한 의견진술의 기회를 부여할 수 있습니다.",
  agreementSub1Content051:
    "① 제5조 제2항에서 정하고 있는 이용계약의 승낙거부사유가 있음이 확인된 경우",
  agreementSub1Content052:
    "② 회원이 ‘회사’나 다른 회원 기타 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 행위를 한 경우",
  agreementSub1Content053:
    "③ 기타 회원이 이 약관에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우",
  agreementSub1Content054:
    "나. 이용계약은 ‘회사’가 해지의사를 회원에게 통지함으로써 종료됩니다. 이 경우 ‘회사’는 해지의사를 회원이 등록한 전자우편주소로 발송하거나 ‘회사’의 게시판에 게시함으로써 통지에 갈음합니다.",
  agreementSub1Content055:
    "다. 이용계약이 종료되는 경우 회원의 적립금 및 쿠폰은 소멸되며, 환불 등의 처리에 관하여는 ‘회사’의 환불규정에 의합니다.",
  agreementSub1Content056:
    "라. 이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 회원이 책임을 부담하고 ‘회사’는 일체의 책임을 지지 않습니다.",
  agreementSub1Content057: "제 9 조 (회원탈퇴 및 자격 상실)",
  agreementSub1Content058:
    "1. 회원은 ‘회사’에 언제든지 탈퇴를 요청할 수 있으며 ‘회사’는 회원탈퇴에 관한 규정에 따라 이를 처리합니다.",
  agreementSub1Content059:
    "2. 회원이 다음 각 호의 사유에 해당하는 경우, ‘회사’는 회원자격을 제한 및 정지시킬 수 있습니다.",
  agreementSub1Content060:
    "가. 다른 사람의 ‘사이트’ 이용을 방해하거나 그 정보를 도용하는 등 전자상거래질서를 위협하는 경우",
  agreementSub1Content061:
    "나. ‘사이트’를 이용하여 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우",
  agreementSub1Content062:
    "다. 기타 다음과 같은 행위 등으로 ‘사이트’의 건전한 운영을 해하거나 ‘사이트’의 업무를 방해하는 경우",
  agreementSub1Content063:
    "① ‘사이트’의 운영과 관련하여 근거 없는 사실 또는 허위의 사실을 적시하거나 유포하여 ‘회사’의 명예를 실추시키거나 ‘사이트’의 신뢰성을 해하는 경우",
  agreementSub1Content064:
    "② ‘사이트’의 이용과정에서 직원에게 폭언, 협박 또는 음란한 언행 등으로 ‘사이트’의 운영을 방해하는 경우",
  agreementSub1Content065:
    "③ ‘사이트’를 통해 ‘상품 등’을 구매한 후 정당한 이유 없이 상습 또는 반복적으로 취소∙반품하여 ‘회사’의 업무를 방해하는 경우",
  agreementSub1Content066:
    "④ ‘사이트’를 통해 구입한 ‘상품 등’에 특별한 하자가 없는데도 불구하고 일부 사용 후 상습적인 취소∙전부 또는 일부 반품 등으로 ‘회사’의 업무를 방해하는 경우",
  agreementSub1Content067:
    "3. ‘회사’가 회원자격을 제한∙정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 ‘회사’는 회원자격을 상실시킬 수 있습니다.",
  agreementSub1Content068:
    "4. 재판매의 목적으로 ‘사이트’에서 ‘상품 등’을 중복 구매하는 등 ‘사이트’의 거래질서를 방해하는 경우 ‘회사’는 당해 회원의 회원자격을 상실시킬 수 있습니다.",
  agreementSub1Content069:
    "5. ‘회사’가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 제8조 제2항 (나)목에 따라 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.",
  agreementSub1Content070: "제 10 조 (회원의 ID 및 비밀번호에 대한 의무)",
  agreementSub1Content071:
    "1. ID(전자우편번호 및 소셜네트워크 연동으로 인한 ID)와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 소홀히 하여 발생한 모든 민∙형사상의 책임은 회원 자신에게 있습니다.",
  agreementSub1Content072:
    "2. 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.",
  agreementSub1Content073:
    "3. 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 ‘회사’에 통보하고 ‘회사’의 조치가 있는 경우에는 그에 따라야 합니다.",
  agreementSub1Content074:
    "4. 회원이 제3항에 따른 통지를 하지 않거나 ‘회사’의 조치에 응하지 아니하여 발생하는 모든 불이익에 대한 책임은 회원에게 있습니다.",
  agreementSub1Content075: "제 11 조 (회원의 의무)",
  agreementSub1Content076:
    "1. 회원은 관계법령, 이 약관의 규정, 이용안내 등 ‘회사’가 통지하는 사항을 준수하여야 하며, 기타 ‘회사’ 업무에 방해되는 행위를 하여서는 안됩니다.",
  agreementSub1Content077:
    "2. 회원은 ‘서비스’ 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.",
  agreementSub1Content078: "가. ‘서비스’ 신청 또는 변경 시 허위내용의 등록",
  agreementSub1Content079: "나. 타인의 정보도용",
  agreementSub1Content080: "다. ‘사이트’에 게시된 정보의 허가 받지 않은 변경",
  agreementSub1Content081:
    "라. ‘회사’가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시",
  agreementSub1Content082:
    "마. ‘회사’ 및 기타 제3자의 저작권 등 지적재산권에 대한 침해",
  agreementSub1Content083:
    "바. ‘회사’ 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위",
  agreementSub1Content084:
    "사. 외설 또는 폭력적인 메시지∙화상∙음성 기타 공서양속에 반하는 정보를 ‘회사’에 공개 또는 게시하는 행위",
  agreementSub1Content085:
    "아. ‘회사’의 동의 없이 영리를 목적으로 ‘서비스’를 사용하는 행위",
  agreementSub1Content086:
    "자. 기타 관계법령이나 ‘회사’에서 정한 규정에 위배되는 행위",
  agreementSub1Content087: "제 12 조 (회원의 게시물)",
  agreementSub1Content088:
    "1. 회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있으며, ‘회사’는 회원이 게시하거나 등록하는 ‘서비스’의 내용물이 다음 각 항에 해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있고, 이에 대하여 ‘회사’는 어떠한 책임도 지지 않습니다.",
  agreementSub1Content089:
    "2. 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우",
  agreementSub1Content090: "3. 공서양속에 위반되는 내용일 경우",
  agreementSub1Content091: "4. 범죄적 행위에 결부된다고 인정되는 경우",
  agreementSub1Content092:
    "5. ‘회사’의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우",
  agreementSub1Content093:
    "6. 회원이 ‘사이트’와 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우",
  agreementSub1Content094:
    "7. ‘회사’로부터 사전 승인 받지 아니한 상업광고, 판촉내용을 게시하는 경우",
  agreementSub1Content095: "8. 해당 상품과 관련 없는 내용인 경우",
  agreementSub1Content096:
    "9. 정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 경우",
  agreementSub1Content097: "10. 기타 관계법령에 위반된다고 판단되는 경우",
  agreementSub1Content098: "제 13 조 (회원에 대한 통지)",
  agreementSub1Content099:
    "1. ‘회사’가 회원에 대한 통지를 하는 경우, 회원이 가입신청 시 ‘회사’에 제출한 전자우편주소나 SMS, PUSH 등으로 할 수 있습니다.",
  agreementSub1Content100:
    "2. ‘회사’는 불특정다수 회원에 대한 통지의 경우, 1주일 이상 ‘사이트’에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래에 관하여 중대한 영향을 미치는 사항에 대하여는 개별 통지합니다.",
  agreementSub1Content101: "제 14 조 (‘회사’의 의무)",
  agreementSub1Content102:
    "1. ‘회사’는 관계법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 ‘상품 등’을 제공하는데 최선을 다하여야 합니다.",
  agreementSub1Content103:
    "2. ‘회사’는 회원이 안전하게 ‘서비스’를 이용할 수 있도록 회원의 개인정보(신용정보 포함)보호를 위한 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.",
  agreementSub1Content104:
    "3. ‘회사’는 회원으로부터 제기되는 의견이나 불만이 정당하고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보하여야 합니다.",
  agreementSub1Content105: "제 15 조 (개별 서비스에 대한 약관 및 이용조건)",
  agreementSub1Content106:
    "‘회사’는 제공하는 ‘서비스’내의 개별 서비스에 대한 별도의 약관 및 이용조건을 둘 수 있으며 개별 서비스에서 별도로 적용되는 약관에 대한 동의는 회원이 개별 서비스를 최초로 이용할 경우 별도의 동의절차를 거치게 됩니다. 이 경우 개별 서비스에 대한 이용약관 등이 본 약관에 우선합니다.",
  agreementSub1Content107: "제 16 조 (‘서비스’ 이용시간)",
  agreementSub1Content108:
    "‘서비스’의 이용은 ‘회사’의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기점검 등의 필요로 ‘회사’가 정한 날이나 시간은 제외됩니다. 정기점검시간은 ‘서비스’제공화면에 공지한 바에 따릅니다.",
  agreementSub1Content109: "제 17 조 (‘서비스’ 이용책임)",
  agreementSub1Content110:
    "회원은 ‘회사’가 서명한 명시적인 서면에 구체적으로 허용한 경우를 제외하고는 ‘서비스’를 이용하여 상품을 판매하는 영업활동을 할 수 없으며 특히 해킹, 돈벌이 광고, 음란사이트 등을 통한 상업행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 어겨 발생한 영업활동의 결과 및 손실, 관계기관에 의한 법적 조치 등에 관해서는 ‘회사’가 책임을 지지 않습니다.",
  agreementSub1Content111: "제 18 조 (‘서비스’ 제공의 중지)",
  agreementSub1Content112:
    "1. ‘회사’는 다음 각 호에 해당하는 경우 ‘서비스’ 제공을 중지할 수 있습니다.",
  agreementSub1Content113:
    "가. 컴퓨터 등 정보통신설비의 보수점검∙교체 및 고장, 통신의 두절 등의 사유가 발생한 경우",
  agreementSub1Content114:
    "나. 『전기통신사업법』에 규정된 기간통신사업자가 전기통신 ‘서비스’를 중지했을 경우",
  agreementSub1Content115: "다. 기타 불가항력적 사유가 있는 경우",
  agreementSub1Content116:
    "2. ‘회사’는 국가비상사태, 정전, ‘서비스’ 설비의 장애 또는 ‘서비스’ 이용의 폭주 등으로 정상적인 ‘서비스’ 이용에 지장이 있는 때에는 ‘서비스’의 전부 또는 일부를 제한하거나 정지할 수 있습니다.",
  agreementSub1Content117:
    "3. ‘회사’가 ‘서비스’ 제공을 일시적으로 중단할 경우 서비스 일시 중단사실과 그 사유를 ‘사이트’ 초기화면에 통지합니다.",
  agreementSub1Content118: "제 19 조 (정보의 제공 및 광고의 게재)",
  agreementSub1Content119:
    "1. ‘회사’는 회원이 ‘서비스’ 이용 중 필요하다고 인정되는 다양한 정보를 공지사항 또는 전자우편이나, SMS, 전화 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편 등에 대해서 수신 거절을 할 수 있습니다.",
  agreementSub1Content120:
    "2. ‘회사’는 ‘서비스’의 운영과 관련하여 ‘서비스’ 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을 수신한 회원은 수신거절을 ‘회사’에게 할 수 있습니다.",
  agreementSub1Content121: "제 20 조 (구매신청)",
  agreementSub1Content122:
    "1. 회원은 본 약관 및 ‘회사’가 정한 규정에 따라 아래 또는 이와 유사한 방법에 의하여 구매를 신청하여야 합니다.",
  agreementSub1Content123: "가. ‘상품 등’의 선택",
  agreementSub1Content124:
    "나. 구매자 성명, 주소, 전화번호, 전자우편주소, 수취인의 성명, 배송지 정보, 전화번호 입력",
  agreementSub1Content125: "다. 결제 시 유의사항에 대한 확인",
  agreementSub1Content126: "라. 이 약관에 동의한다는 표시(예, 마우스 클릭)",
  agreementSub1Content127:
    "2. ‘회사’는 회원의 구매신청이 있는 경우 회원에게 수신확인통지를 합니다.",
  agreementSub1Content128:
    "3. 전 항의 수신확인통지를 받은 회원은 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있습니다. 다만, 이미 대금을 지불한 경우에는 제24조 청약철회 등에 관한 규정에 따릅니다.",
  agreementSub1Content129: "제 21 조 (대금지급방법)",
  agreementSub1Content130:
    "1. ‘회사’에서 구매한 상품 또는 쿠폰에 대한 대금지급방법은 다음 각 호의 하나로 할 수 있습니다.",
  agreementSub1Content131: "가. 신용카드 결제",
  agreementSub1Content132: "나. 실시간 계좌이체",
  agreementSub1Content133: "다. 에스크로 결제",
  agreementSub1Content134: "라. 무통장입금",
  agreementSub1Content135:
    "마. 기타 ‘회사’가 추가 지정하는 결제 수단(할인쿠폰, 스마트폰 앱 등)",
  agreementSub1Content136:
    "2. 회원이 구매대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담합니다.",
  agreementSub1Content137: "제 22 조 (‘상품 등’의 공급)",
  agreementSub1Content138:
    "1. ‘회사’는 회원의 ‘상품 등’의 공급에 관하여 별도의 약정이 없는 이상 회원이 ‘상품 등’을 구매한 날부터 빠른 시일 이내에 ‘상품 등’을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다.",
  agreementSub1Content139:
    "2. ‘회사’는 회원이 ‘상품 등’의 공급절차 및 진행사항, ‘상품 등’에 대한 구매 결제내역을 확인할 수 있도록 적절한 조치를 하여야 하며, ‘상품 등’의 취소방법 및 절차를 안내하여야 합니다.",
  agreementSub1Content140:
    "3. ‘회사’는 회원이 구매한 ‘상품 등’에 대해 배송수단, 수단별 배송비용, 배송비용 부담자, 수단별 배송기간 등을 명시합니다.",
  agreementSub1Content141:
    "4. ‘회사’와 고객간에 상품의 인도시기 및 쿠폰의 제공시기에 관하여 별도의 약정이 있는 경우에는 본 약관에 우선합니다.",
  agreementSub1Content142: "제 23 조 (환급)",
  agreementSub1Content143:
    "‘회사’는 회원이 구매 신청한 ‘상품 등’이 품절 등의 사유로 인도 또는 제공할 수 없을 때에는 지체 없이 그 사유를 회원에게 통지하여야 하고, 사전에 ‘상품 등’의 대금을 받은 경우에는 대금을 받은 날로부터 7영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.",
  agreementSub1Content144: "제 24 조 (청약철회 등)",
  agreementSub1Content145:
    "1. ‘회사’의 ‘상품 등’의 구매취소 및 환불 규정은 『전자상거래 등에서의 소비자보호에 관한 법률』 등 관련 법령을 준수합니다.",
  agreementSub1Content146:
    "2. ‘회사’와 ‘상품 등’의 구매에 관한 계약을 체결한 회원은 수신확인의 통지를 받은 날(전자우편 또는 SMS 문자통지 등을 받은 날) 또는 ‘상품 등’의 공급을 받은 날로부터 7일 이내에는 청약을 철회할 수 있습니다.",
  agreementSub1Content147:
    "3. 회원은 ‘상품 등’을 배송 받은 경우 아래 각 호에 해당하는 경우에는 교환 및 반품, 환불을 할 수 없습니다.",
  agreementSub1Content148:
    "가. 회원에게 책임 있는 사유로 ‘상품 등’이 멸실 또는 훼손된 경우(다만, ‘상품 등’의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다.)",
  agreementSub1Content149:
    "나. 회원의 사용 또는 일부 소비에 의하여 ‘상품 등’의 가치가 현저히 감소한 경우",
  agreementSub1Content150:
    "다. 시간의 경과에 의하여 재판매가 곤란할 정도로 ‘상품 등’의 가치가 현저히 감소한 경우",
  agreementSub1Content151:
    "라. 같은 성능을 지닌 ‘상품 등’으로 복제가 가능한 경우 그 원본인 ‘상품 등’의 포장을 훼손한 경우",
  agreementSub1Content152:
    "마. 회사가 특정 쿠폰 또는 상품 등에 대하여 청약철회 제한에 관해 사전에 고지한 경우",
  agreementSub1Content153:
    "4. 회원은 제3항 및 제4항의 규정에 불구하고 ‘상품 등’의 내용이 표시∙광고 내용과 다르거나 계약 내용과 다르게 이행된 때에는 당해 ‘상품 등’을 공급받은 날부터 3일 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 10일 이내에 청약철회 등을 할 수 있습니다.",
  agreementSub1Content154: "제 25 조 (청약철회 등의 효과)",
  agreementSub1Content155:
    "1. 회원이 구매한 ‘상품 등’을 취소 또는 환불하는 경우 ‘회사’는 그 처리 결과에 대해 제13조(회원에 대한 통지)에 정한 방법으로 통지합니다.",
  agreementSub1Content156:
    "2. ‘회사’는 회원으로부터 쿠폰취소 요청 또는 상품 등을 반환 받은 경우 7영업일 이내에 이미 지급받은 대금을 환급합니다. 이 경우 ‘회사’가 회원에게 재화 등의 환급을 지연할 때에는 그 지연기간에 대하여 공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.",
  agreementSub1Content157:
    "3. 청약철회 등의 경우 공급받은 ‘상품 등’의 반환에 필요한 비용은 회원이 부담합니다. 다만, ‘상품 등’의 내용이 표시∙광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 ‘상품 등’에 필요한 비용은 ‘회사’가 부담합니다.",
  agreementSub1Content158:
    "4. 회원의 단순변심에 의한 취소일 경우 환불처리에 발생하는 수수료와 기타 제반 비용은 회원이 부담합니다.",
  agreementSub1Content159:
    "5. 기타 본 약관 및 ‘사이트’의 이용안내에 규정되지 않은 취소 및 환불에 대한 사항에 대해서는 소비자 피해보상규정에서 정한 바에 따릅니다.",
  agreementSub1Content160: "제 26 조 (‘상품 등’의 취소 및 환불의 특칙)",
  agreementSub1Content161:
    "상품의 반품에 따른 환불은 반품하는 상품이 판매자에게 도착되고, 반품 사유 및 반품 배송비 부담자가 확인된 이후에 이루어집니다. ",
  agreementSub1Content162: "제 27 조 (게시물의 관리)",
  agreementSub1Content163:
    "1. 회원의 게시물이 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 및 『저작권』등 관계법령에 위반되는 내용을 포함하는 경우, 권리자는 관계법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, ‘회사’는 관계법령에 따라 조치를 취하여야 합니다.",
  agreementSub1Content164:
    "2. ‘회사’는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 ‘회사’ 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 ‘게시물’에 대해 임시조치 등을 취할 수 있습니다.",
  agreementSub1Content165:
    "3. 본 조에 따른 세부절차는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 및 『저작권법』이 규정한 범위 내에서 ‘회사’가 정한 ‘게시중단요청서비스’에 따릅니다.",
  agreementSub1Content166: "제 28 조 (면책조항)",
  agreementSub1Content167:
    "1. ‘회사’는 천재지변 또는 이에 준하는 불가항력으로 인하여 ‘서비스’를 제공할 수 없는 경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.",
  agreementSub1Content168:
    "2. ‘회사’는 회원의 귀책사유로 인한 ‘서비스’ 이용의 장애에 대하여 책임을 지지 않습니다.",
  agreementSub1Content169:
    "3. ‘회사’는 회원이 ‘서비스’를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 ‘서비스’를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.",
  agreementSub1Content170:
    "4. ‘회사’는 회원이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않습니다.",
  agreementSub1Content171:
    "5. 회원이 발송한 메일 내용에 대한 법적인 책임은 사용자에게 있습니다.",
  agreementSub1Content172:
    "6. ‘회사’는 회원간 또는 회원과 제3자 상호간에 ‘서비스’를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.",
  agreementSub1Content173:
    "7. ‘회사’는 무료로 제공되는 ‘서비스’ 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.",
  agreementSub1Content174: "제 29 조 (분쟁해결)",
  agreementSub1Content175:
    "1. ‘회사’는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위해서 피해보상처리 기구를 설치, 운영합니다.",
  agreementSub1Content176:
    "2. ‘회사’는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통보합니다.",
  agreementSub1Content177: "제 30 조 (준거법 및 관할법원)",
  agreementSub1Content178:
    "1. 이 약관의 해석 및 ‘회사’와 회원간의 분쟁에 대하여는 대한민국의 법을 적용합니다.",
  agreementSub1Content179:
    "2. ‘서비스’ 이용 중 발생한 회원과 ‘회사’간의 소송은 민사소송법에 의한 관할법원에 제소합니다.",
  agreementSub1Content180: "부칙",
  agreementSub1Content181: "1. 이 약관은 2018년 07월 28일부터 시행합니다.",
  agreementSub1Content182:
    "2. 이 개정약관은 시행일 이후에 체결되는 계약에만 적용되고 그 이전에 체결된 계약에 대해서는 개정전의 약관조항을 그대로 적용합니다.",

  /*********AgreementSub2**********/
  agreementSub2Content01: "[개인정보 수집이용 목적]",
  agreementSub2Content02:
    "회사의 개인정보 수집 목적은 최적화된 맞춤화 서비스를 제공하기 위함이며, 회사는 서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있습니다.",
  agreementSub2Content03:
    "서비스 이용에 따른 대금결제, 물품배송 및 환불 등에 필요한 정보를 추가로 수집할 수 있습니다.",
  agreementSub2Content04:
    "회사는 개인정보를 수집, 이용목적 이외에 다른 용도로 이용하거나 회원의 동의 없이 제3자에게 이를 제공하지 않습니다.",
  agreementSub2Content05: "[개인정보 수집항목]",
  agreementSub2Content06:
    "회사는 회원가입, 쇼핑몰 이용, 서비스 신청 및 제공 등을 위해 다음과 같은 개인정보를 수집하고 있습니다. 회사는 개인의 주민등록번호 및 아이핀 정보를 받지 않습니다.",
  agreementSub2Content07: "가. 개인정보 항목",
  agreementSub2Content08:
    "회원 필수항목: 전자우편주소, 페이스북 ID 및 페이스북에서 제공하는 정보, 트위터 ID 및 트위터에서 제공하는 정보, 구글+ ID 및 구글에서 제공하는 정보",
  agreementSub2Content09:
    "비회원 필수항목: 주문자 이름, 주문 결제자 주소, 수취인 이름, 배송지 정보, 연락처, 고객메모",
  agreementSub2Content10:
    "부가항목: 주문자 이름, 주문 결제자 주소, 수취인 이름, 배송지 정보, 연락처, 환불요청 시 환불계좌번호",
  agreementSub2Content11:
    "다만, 서비스 이용과정에서 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록 등이 생성되어 수집될 수 있습니다.",
  agreementSub2Content12:
    "나. 수집방법: 쇼핑몰 회원가입 시의 회원가입 정보 및 고객센터를 통한 전화 및 온라인 상담",
  agreementSub2Content13: "[개인정보 보유 및 이용기간]",
  agreementSub2Content14:
    "회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 개인정보를 지체 없이 파기합니다.",
  agreementSub2Content15:
    "단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원 개인정보를 보관합니다.",
  agreementSub2Content16: "가. 상법 등 법령에 따라 보존할 필요성이 있는 경우",
  agreementSub2Content17: "① 표시 • 광고에 관한 기록",
  agreementSub2Content18:
    "보존근거: 전자상거래 등에서의 소비자보호에 관한 법률",
  agreementSub2Content19: "보존기간: 6개월",
  agreementSub2Content20: "② 계약 또는 청약철회 등에 관한 기록",
  agreementSub2Content21:
    "보존근거: 전자상거래 등에서의 소비자보호에 관한 법률",
  agreementSub2Content22: "보존기간: 5년",
  agreementSub2Content23: "③ 대금결제 및 재화 등의 공급에 관한 기록",
  agreementSub2Content24:
    "보존근거: 전자상거래 등에서의 소비자보호에 관한 법률",
  agreementSub2Content25: "보존기간: 5년",
  agreementSub2Content26: "④ 소비자의 불만 또는 분쟁처리에 관한 기록",
  agreementSub2Content27:
    "보존근거: 전자상거래 등에서의 소비자보호에 관한 법률",
  agreementSub2Content28: "보존기간: 3년",
  agreementSub2Content29: "⑤ 신용정보의 수집, 처리 및 이용 등에 관한 기록",
  agreementSub2Content30: "보존근거: 신용정보의 이용 및 보호에 관한 법률",
  agreementSub2Content31: "보존기간: 3년",
  agreementSub2Content32: "⑥ 본인확인에 관한 기록보존",
  agreementSub2Content33:
    "보존근거: 정보통신망 이용촉진 및 정보보호에 관한 법률 제44조의5 및 시행령 제29조",
  agreementSub2Content34: "보존기간: 6개월",
  agreementSub2Content35: "⑦ 접속에 관한 기록보존",
  agreementSub2Content36: "보존근거: 통신비밀보호법 제15조의2 및 시행령 제41조",
  agreementSub2Content37: "보존기간: 3개월",
  agreementSub2Content38:
    "나. 기타, 회원의 개별적인 동의가 있는 경우에는 개별 동의에 따른 기간까지 보관합니다.",
  agreementSub2Content39: "[개인정보 제3자 제공]",
  agreementSub2Content40:
    "가. 회사는 회원들의 개인정보를 개인정보의 수집이용 목적에서 고지한 범위 내에서 사용하며, 회원의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를 제 3자에게 제공하지 않습니다. 단, 아래의 경우에는 예외로 합니다.",
  agreementSub2Content41:
    "① 회원들이 사전에 공개 또는 제3자 제공에 동의한 경우",
  agreementSub2Content42:
    "② 법령의 규정에 의거하나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독 당국의 요구가 있는 경우",
  agreementSub2Content43:
    "나. 회사가 제공하는 서비스를 통하여 주문 및 결제가 이루어진 경우, 상담 등 거래 당사자간 원활한 의사소통 및 배송 등 거래이행을 위하여 관련된 정보를 필요한 범위 내에서 거래 당사자에게 제공합니다.",
  agreementSub2Content44:
    "① OO택배사: 주문자 이름, 수취인 이름 배송지 정보, 연락처",
  agreementSub2Content45:
    "그 밖에 개인정보 제3자 제공이 필요한 경우에는 합당한 절차를 통한 회원의 동의를 얻어 제3자에게 개인정보를 제공할 수 있습니다.",

  /*********AgreementSub3**********/
  agreementSub3Content01: "1. 개인정보 수집목적 및 이용목적",
  agreementSub3Content02: "비회원 구매 서비스 제공",
  agreementSub3Content03: "2. 수집하는 개인정보 항목",
  agreementSub3Content04: "성명, 주소, 전화번호, 이메일, 결제 정보",
  agreementSub3Content05: "3. 개인정보의 보유기간 및 이용기간",
  agreementSub3Content06:
    "원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.",
  agreementSub3Content07: "가. 회사 내부 방침에 의한 정보 보유 사유",
  agreementSub3Content08: "부정거래 방지 및 쇼핑몰 운영방침에 따른 보관 : 5년",
  agreementSub3Content09: "나. 관련 법령에 의한 정보보유 사유",
  agreementSub3Content10: "① 표시 • 광고에 관한 기록",
  agreementSub3Content11:
    "보존근거: 전자상거래 등에서의 소비자보호에 관한 법률",
  agreementSub3Content12: "보존기간: 6개월",
  agreementSub3Content13: "② 계약 또는 청약철회 등에 관한 기록",
  agreementSub3Content14:
    "보존근거: 전자상거래 등에서의 소비자보호에 관한 법률",
  agreementSub3Content15: "보존기간: 5년",
  agreementSub3Content16: "③ 대금결제 및 재화 등의 공급에 관한 기록",
  agreementSub3Content17:
    "보존근거: 전자상거래 등에서의 소비자보호에 관한 법률",
  agreementSub3Content18: "보존기간: 5년",
  agreementSub3Content19: "④ 소비자의 불만 또는 분쟁처리에 관한 기록",
  agreementSub3Content20:
    "보존근거: 전자상거래 등에서의 소비자보호에 관한 법률",
  agreementSub3Content21: "보존기간: 3년",
  agreementSub3Content22: "⑤ 신용정보의 수집, 처리 및 이용 등에 관한 기록",
  agreementSub3Content23: "보존근거: 신용정보의 이용 및 보호에 관한 법률",
  agreementSub3Content24: "보존기간: 3년",
  agreementSub3Content25: "⑥ 본인확인에 관한 기록보존",
  agreementSub3Content26:
    "보존근거: 정보통신망 이용촉진 및 정보보호에 관한 법률 제44조의5 및 시행령 제29조",
  agreementSub3Content27: "보존기간: 6개월",
  agreementSub3Content28: "⑦ 접속에 관한 기록보존",
  agreementSub3Content29: "보존근거: 통신비밀보호법 제15조의2 및 시행령 제41조",
  agreementSub3Content30: "보존기간: 3개월",
  /*********NotFound**********/
  notFoundMsg01: "죄송합니다.",
  notFoundMsg02: "원하시는 페이지를 찾을 수가 없습니다.",
  notFoundMsg03: "방문 원하시는 페이지의 주소가 잘못 입력되었거나,",
  notFoundMsg04: "변경 혹은 삭제되어 요청하신 페이지를 찾을 수가 없습니다.",
  notFoundMsg05: "입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.",
  notFoundMsg06:
    "궁금하신 점 고객센터로 문의해 주시면 친절하게 안내해 드리겠습니다.",
  notFoundMsg07: "언제나 최선을 다하는 블랍스가 되겠습니다.",
  notFoundMsg08: "고객센터 : 02-423-4261 (운영시간 : 10:00~18:00)",
  notFoundMsg09: "홈으로",

  /*********구매**********/
  purchaseFailPopupMsg: "팝업이 차단되어 결제를 진행할 수 없습니다.",
  purchasebtn01: "상품주문",
  purchasebtn02: "비회원 구매",
  purchasebtn03: "로그인 하기",
  purchasebtn04: "코인결제",
  purchaseLoginmsg01: "현재 로그인이 되어있지 않습니다.",
  purchaseLoginmsg02:
    "비회원으로 구매하시는 경우 구매 진행내용은 이메일을 통해서만 안내됩니다.",
  purchaseLoginmsg03: "비회원으로 구매를 진행하시겠습니까?",
  purchaseAlert01: "서비스 준비중입니다. 오픈 예정일은 9/29일 입니다.",
  purchaseForCoinMsg01:
    '코인결제는 코인마켓캡의 <font color="#00aab6"><b>현재 시세를 기준으로 금액을 산정</b></font>합니다.<br/>원화로 결제하는 금액보다 <font color="#00aab6"><b>10% 할인된 금액</b></font>으로 상품을 구매할 수 있습니다. <br/><br/>',

  // purchaseForCoinMsg07:'주소생성',
  // purchaseForCoinMsg08:'을 클릭하시면 개인 전용 코인 주소가 발급됩니다.',
  purchaseForCoinMsg09:
    "코인 주소를 복사하거나 QR코드를 촬영하여 코인을 전송하시면 결제가 진행됩니다. 단, 유효시간이 지나면 결제는 자동취소 됩니다.",
  purchaseForCoinMsg10: "주소복사",
  purchaseForCoinMsg11: "주소가 복사되었습니다.",
  purchaseForCoinMsg12: "유효시간",
  purchaseForCoinMsg13:
    "주소가 복사되었습니다. 리플은 반드시 Destination Tag를 입력하여 송금해야합니다. Destinaion Tag가 없는 경우 입금확인이 불가능합니다.",

  purchaseDepositless: "무통장입금",
  purchaseDepositlessMsg01: "아래 계좌로 입금하시면 확인 후 결제처리 됩니다.",
  purchaseDepositlessMsg02: "까지 미입금 시 결제는 자동취소 됩니다.",
  purchaseDepositlessMsg03: "국민은행 (주)블랍스",
  purchaseDepositlessMsg04: "483901-01-225099",
  purchaseDepositlessMsg05:
    '<font color="#00aab6"><b>입금자명은 반드시 주문자명과 동일</b></font>해야 합니다.<br/>주문한 입금액이나 입금자명이 상이할 경우 결제처리가 되지 않습니다.',
  // purchaseDepositlessMsg06:'해야 합니다.',
  // purchaseDepositlessMsg07:'주문한 입금액이나 입금자명이 상이할 경우 결제처리가 되지 않습니다.',
  purchaseDepositlessMsg08: "계좌복사",
  purchaseDepositlessMsg09: "계좌번호가 복사되었습니다.",
  purchaseDepositlessMsg10: "48390101225099",
  purchaseOrderProduct: "상품주문",
  purchaseOrderProductMsg01: "주문정보 생성에 실패하였습니다.",
  purchaseOrderProductMsg02: "잠시 후 다시 시도해주세요.",
  purchasePasswordIncorrect: "비밀번호가 일치하지 않습니다!",
  purchasePolicyMsg01: "비회원 개인정보 수집 및 이용",
  purchasePolicyMsg02: "약관에 동의해 주세요.",
  purchasePolicyMsg03: "약관동의",
  purchasePolicyMsg04: "(필수) 비회원 개인정보 수집 및 이용",
  purchasePolicyMsg05: "약관보기",
  purchasePolicyMsg06: "결제하기",

  purchaseBuyerInfoMsg01: "주문자 정보",
  purchaseBuyerInfoMsg02:
    "주문 후 구매내역 및 배송현황을 조회하실 수 있습니다.",
  purchaseDeliveryInfo01: "주문자와 동일",
  purchaseDeliveryInfo02: "배송지 정보",
  purchaseDeliveryInfo03: "상품을 배송받을 주소지를 정확하게 입력해주세요.",
  purchaseDeliveryInfo04: "배송 메세지",
  purchaseProductInfo01: "블랍스 하이브리드 콜드월렛 (백업카드 포함 총2장)",
  purchasePayMsg01: "결제 방식을 선택해 주세요!",
  purchasePayMsg02: "코인 결제 (10% 추가 할인)",
  purchasePayMsg03: "신용/체크카드 결제",
  purchasePayMsg04: "실시간 계좌이체 결제",
  purchasePayMsg05: "가상계좌 결제",
  purchasePayMsg06: "무통장 입금",

  productQuantity: "수량",
  productTotalPrice: "총금액",
  productGoToPurchase: "구매하기",

  /*********PurchaseList**********/
  purchaseListStatus01: "시작",
  purchaseListStatus02: "결제대기",
  purchaseListStatus03: "결제완료",
  purchaseListStatus04: "배송중",
  purchaseListStatus05: "배송완료",
  purchaseListMyOrderList: "나의 구매내역",

  /*********purchaseResult**********/
  purchaseResultComplete: "구매완료",
  purchaseResultOrderList: "구매내역",
  purchaseResultThankyou: "블랍스 제품을 이용해 주셔서 감사합니다.",
  purchaseResultOrderNumber: "구매번호",
  purchaseResultPrintReceipt: "영수증 출력",
  purchaseResultMyShoppingList: "나의 쇼핑 목록",
};

export default string;
