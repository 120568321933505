import { Form, Input, Tooltip, Icon, Cascader, Select, Row, Col, Checkbox, Button, AutoComplete } from 'antd';
import React from 'react';
import string from '../String';

class AgreementSub3 extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="agreement-wrapper">
      <div className='agreement-title'>{string.agreementSub3Content01}</div>
      <div className='agreement-content'>{string.agreementSub3Content02}</div>
      <div className='agreement-title'>{string.agreementSub3Content03}</div>
      <div className='agreement-content'>{string.agreementSub3Content04}</div>
      <div className='agreement-title'>{string.agreementSub3Content05}</div>
      <div className='agreement-content'>{string.agreementSub3Content06}</div>
      <div className='agreement-content level1'>{string.agreementSub3Content07}</div>
      <div className='agreement-content level1-sub'>{string.agreementSub3Content08}</div>
      <div className='agreement-content level1'>{string.agreementSub3Content09}</div>
      <div className='agreement-content level2'>{string.agreementSub3Content10}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content11}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content12}</div>
      <div className='agreement-content level2'>{string.agreementSub3Content13}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content14}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content15}</div>
      <div className='agreement-content level2'>{string.agreementSub3Content16}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content17}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content18}</div>
      <div className='agreement-content level2'>{string.agreementSub3Content19}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content20}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content21}</div>
      <div className='agreement-content level2'>{string.agreementSub3Content22}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content23}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content24}</div>
      <div className='agreement-content level2'>{string.agreementSub3Content25}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content26}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content27}</div>
      <div className='agreement-content level2'>{string.agreementSub3Content28}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content29}</div>
      <div className='agreement-content level2-sub'>{string.agreementSub3Content30}</div>


      </div>
    );
  }
}
export default AgreementSub3;