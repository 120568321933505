import React from 'react';
import { Link } from 'react-router-dom';
import string from '../String';

const NotFound = () => {
    return (        
        <div className="notfount-wrapper">
            
          <div className="notfound-header" >
            <Link exact to="/">
            <img alt="logo" src={require('../img/common/logo.png')} className='blobs-header-logo' />
            </Link>
          </div>
          <div style={{ letterSpacing: '-1px', color: '#0a141d', textAlign: 'left'}}>
            <div className="notfount-title">{string.notFoundMsg01}</div>
            <div className="notfount-title">{string.notFoundMsg02}</div>
            <div style={{marginTop:'50px', marginLeft:'20px'}}>{string.notFoundMsg03}</div>
            <div style={{marginLeft:'20px'}}>{string.notFoundMsg04}</div>
            <div style={{marginTop:'20px', marginLeft:'20px'}}>{string.notFoundMsg05}</div>
            <div style={{marginTop:'20px', marginLeft:'20px'}}>{string.notFoundMsg06}</div>
            <div style={{marginLeft:'20px'}}>{string.notFoundMsg07}</div>
            <div style={{marginTop:'20px', marginLeft:'20px'}}>{string.notFoundMsg08}</div>
            <div style={{marginTop:'10px'}}/>
            <div style={{textAlign: 'center'}}>
              <div style={{ paddingTop: '50px', display: 'inline-block' }}>
                <div onClick={()=>{global.location.href='/'}} className='notfount-btn' style={{  }}>
                  {string.notFoundMsg09}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default NotFound;
