import React, { Component } from "react";
import string from "../String";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default class Footer extends Component {
  render() {
    return (
      <footer id="footer" className="footer">
        <div className="container">
          <div className="footer-left">
            <img src={require("../img/common/logo_gray.png")} />
          </div>
          <div className="footer-right">
            <div>{string.common.footer01}</div>
            <div className="desk">{string.common.footer02}</div>
            <div className="desk">{string.common.footer03}</div>
            <div className="mobile">
              {string.common.footer02.split("|").map((v) => {
                return <div>{v}</div>;
              })}
            </div>
            <div className="mobile">
              {string.common.footer03.split("|").map((v) => {
                return <div>{v}</div>;
              })}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
