import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import BlobsCard from "../pages/BlobsCard";
import BlobsCardPurchase from '../pages/BlobsCardPurchase';
import EthereumCard from '../pages/EthereumCard';
import EthereumCardPurchase from '../pages/EthereumCardPurchase';
import FAQ from '../pages/FAQ';
import Notice from '../pages/Notice';
import Contact from '../pages/Contact';
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import Purchase from '../pages/Purchase';
import PurchaseResult from '../pages/PurchaseResult';
import PurchaseList from '../pages/PurchaseList';

export default class Content extends Component {

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={BlobsCard} />
          <Route exact path="/blobscard" component={BlobsCard} />
          <Route exact path="/blobscardpurchase" component={BlobsCardPurchase} />
          {/* <Route exact path="/ethereumcard" component={EthereumCard} /> */}
          {/* <Route exact path="/ethereumcardpurchase" component={EthereumCardPurchase} /> */}
          <Route exact path="/notice" component={Notice} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/purchase" component={Purchase} />
          <Route exact path="/purchaseList" component={PurchaseList} />
          <Route exact path="/purchaseResult" component={PurchaseResult} />
          <Redirect to="/404" />
        </Switch>
      </div>
    );
  }
}
