import { Form, Input, Tooltip, Icon, Cascader, Select, Row, Col, Checkbox, Button, AutoComplete } from 'antd';
import React from 'react';
import string from '../String';

class AgreementSub1 extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="agreement-wrapper">

      <div className='agreement-title'>{string.agreementSub1Content001}</div>
      <div className='agreement-content'>{string.agreementSub1Content002}</div>
      <div className='agreement-title'>{string.agreementSub1Content003}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content004}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content005}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content006}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content007}</div>
      <div className='agreement-title'>{string.agreementSub1Content008}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content009}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content010}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content011}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content012}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content013}</div>
      <div className='agreement-title'>{string.agreementSub1Content014}</div>
      <div className='agreement-content'>{string.agreementSub1Content015}</div>
      <div className='agreement-title'>{string.agreementSub1Content016}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content017}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content018}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content019}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content020}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content021}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content022}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content023}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content024}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content025}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content026}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content027}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content028}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content029}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content030}</div>
      <div className='agreement-title'>{string.agreementSub1Content031}</div>
      <div className='agreement-content'>{string.agreementSub1Content032}</div>
      <div className='agreement-title'>{string.agreementSub1Content033}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content034}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content035}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content036}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content037}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content038}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content039}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content040}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content041}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content042}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content043}</div>
      <div className='agreement-title'>{string.agreementSub1Content044}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content045}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content046}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content047}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content048}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content049}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content050}</div>
      <div className='agreement-content level3'>{string.agreementSub1Content051}</div>
      <div className='agreement-content level3'>{string.agreementSub1Content052}</div>
      <div className='agreement-content level3'>{string.agreementSub1Content053}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content054}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content055}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content056}</div>
      <div className='agreement-title'>{string.agreementSub1Content057}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content058}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content059}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content060}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content061}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content062}</div>
      <div className='agreement-content level3'>{string.agreementSub1Content063}</div>
      <div className='agreement-content level3'>{string.agreementSub1Content064}</div>
      <div className='agreement-content level3'>{string.agreementSub1Content065}</div>
      <div className='agreement-content level3'>{string.agreementSub1Content066}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content067}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content068}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content069}</div>
      <div className='agreement-title'>{string.agreementSub1Content070}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content071}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content072}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content073}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content074}</div>
      <div className='agreement-title'>{string.agreementSub1Content075}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content076}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content077}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content078}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content079}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content080}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content081}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content082}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content083}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content084}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content085}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content086}</div>
      <div className='agreement-title'>{string.agreementSub1Content087}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content088}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content089}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content090}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content091}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content092}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content093}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content094}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content095}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content096}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content097}</div>
      <div className='agreement-title'>{string.agreementSub1Content098}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content099}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content100}</div>
      <div className='agreement-title'>{string.agreementSub1Content101}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content102}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content103}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content104}</div>
      <div className='agreement-title'>{string.agreementSub1Content105}</div>
      <div className='agreement-content'>{string.agreementSub1Content106}</div>
      <div className='agreement-title'>{string.agreementSub1Content107}</div>
      <div className='agreement-content'>{string.agreementSub1Content108}</div>
      <div className='agreement-title'>{string.agreementSub1Content109}</div>
      <div className='agreement-content'>{string.agreementSub1Content110}</div>
      <div className='agreement-title'>{string.agreementSub1Content111}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content112}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content113}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content114}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content115}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content116}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content117}</div>
      <div className='agreement-title'>{string.agreementSub1Content118}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content119}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content120}</div>
      <div className='agreement-title'>{string.agreementSub1Content121}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content122}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content123}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content124}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content125}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content126}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content127}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content128}</div>
      <div className='agreement-title'>{string.agreementSub1Content129}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content130}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content131}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content132}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content133}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content134}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content135}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content136}</div>
      <div className='agreement-title'>{string.agreementSub1Content137}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content138}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content139}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content140}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content141}</div>
      <div className='agreement-title'>{string.agreementSub1Content142}</div>
      <div className='agreement-content'>{string.agreementSub1Content143}</div>
      <div className='agreement-title'>{string.agreementSub1Content144}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content145}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content146}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content147}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content148}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content149}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content150}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content151}</div>
      <div className='agreement-content level2'>{string.agreementSub1Content152}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content153}</div>
      <div className='agreement-title'>{string.agreementSub1Content154}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content155}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content156}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content157}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content158}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content159}</div>
      <div className='agreement-title'>{string.agreementSub1Content160}</div>
      <div className='agreement-content'>{string.agreementSub1Content161}</div>
      <div className='agreement-title'>{string.agreementSub1Content162}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content163}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content164}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content165}</div>
      <div className='agreement-title'>{string.agreementSub1Content166}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content167}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content168}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content169}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content170}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content171}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content172}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content173}</div>
      <div className='agreement-title'>{string.agreementSub1Content174}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content175}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content176}</div>
      <div className='agreement-title'>{string.agreementSub1Content177}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content178}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content179}</div>
      <div className='agreement-title'>{string.agreementSub1Content180}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content181}</div>
      <div className='agreement-content level1'>{string.agreementSub1Content182}</div>

      </div>
    );
  }
}
export default AgreementSub1;