import React, { Component } from 'react';
import Header from '../components/Header';
import string from '../String';
import { Link, withRouter } from "react-router-dom";
export default class EthereumCard extends Component {

  onClickDownload = () => {
    if (/Android/i.test(navigator.userAgent)) {
      global.location.href = 'http://play.google.com/store/apps/details?id=com.jba';
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      global.location.href = 'https://itunes.apple.com/kr/app/%EB%B8%94%EB%9E%8D%EC%8A%A4/id1428926701?mt=8'
    } else {
      alert(string.blobscard.appDownAlert);
    }
  }

  render() {
    return (
      <div className="">
        <div className="ethereumcard-top">
          <div className="container" style={{height: '560px'}}>
            <div className="ethereumcard-title">{string.ethereumcard.mainTitle}</div>
            <div className="ethereumcard-title-explain" dangerouslySetInnerHTML={{__html: string.ethereumcard.mainTitleExplain}}></div>
            <div className="ethereumcard-title-button">
              <Link to="/ethereumcardpurchase"><div className="ethereumcard-title-button-01">{string.ethereumcard.mainTitleButton01}</div></Link>
              <div className="ethereumcard-title-button-02" onClick={this.onClickDownload}>{string.ethereumcard.mainTitleButton02}</div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mobile" style={{minHeight: '50px'}}></div>
          <div className="blobscard-content-left p-t-110">
            <img src={require('../img/ethereumcard/main_content01.png')} className="blobscard-content-image"/>
          </div>
          <div className="blobscard-content-right p-t-150">
            <div className="blobscard-content-number">01</div>
            <div className="blobscard-content-title" dangerouslySetInnerHTML={{__html: string.ethereumcard.mainContent01Title}}></div>
            <div className="blobscard-content-explain">{string.ethereumcard.mainContent01Explain}</div>
          </div>
          <div className="desk" style={{minHeight: '550px'}}></div>
          <div className="mobile" style={{minHeight: '50px'}}></div>
        </div>
        <div className="ethereumcard-content-gray-wrapper">
          <div className="container">
            <div className="mobile" style={{minHeight: '50px'}}></div>
            <div className="blobscard-content-right mobile p-t-30">
              <img src={require('../img/ethereumcard/main_content02.png')} className="blobscard-content-image"/>
            </div>
            <div className="blobscard-content-left p-t-110">
              <div className="blobscard-content-number">02</div>
              <div className="blobscard-content-title" dangerouslySetInnerHTML={{__html: string.ethereumcard.mainContent02Title}}></div>
              <div className="blobscard-content-explain">{string.ethereumcard.mainContent02Explain}</div>
            </div>
            <div className="blobscard-content-right desk p-t-30">
              <img src={require('../img/ethereumcard/main_content02.png')} className="blobscard-content-image"/>
            </div>
            <div className="desk" style={{minHeight: '550px'}}></div>
            <div className="mobile" style={{minHeight: '50px'}}></div>
          </div>
        </div>
        <div className="container">
          <div className="mobile" style={{minHeight: '50px'}}></div>
          <div className="blobscard-content-left p-t-110">
            <img src={require('../img/ethereumcard/main_content03.png')} className="blobscard-content-image"/>
          </div>
          <div className="blobscard-content-right p-t-150">
            <div className="blobscard-content-number">03</div>
            <div className="blobscard-content-title" dangerouslySetInnerHTML={{__html: string.ethereumcard.mainContent03Title}}></div>
            <div className="blobscard-content-explain">{string.ethereumcard.mainContent03Explain}</div>
          </div>
          <div className="desk" style={{minHeight: '650px'}}></div>
          <div className="mobile" style={{minHeight: '50px'}}></div>
        </div>
        <div className="ethereumcard-content-gray-wrapper">
          <div className="container">
            <div className="mobile" style={{minHeight: '50px'}}></div>
            <div className="blobscard-content-right mobile p-t-90">
              <img src={require('../img/ethereumcard/main_content04.png')} className="blobscard-content-image"/>
            </div>
            <div className="blobscard-content-left p-t-110">
              <div className="blobscard-content-number">04</div>
              <div className="blobscard-content-title" dangerouslySetInnerHTML={{__html: string.ethereumcard.mainContent04Title}}></div>
              <div className="blobscard-content-explain">{string.ethereumcard.mainContent04Explain}</div>
            </div>
            <div className="blobscard-content-right desk p-t-90">
              <img src={require('../img/ethereumcard/main_content04.png')} className="blobscard-content-image"/>
            </div>
            <div className="desk" style={{minHeight: '550px'}}></div>
            <div className="mobile" style={{minHeight: '50px'}}></div>
          </div>
        </div>
      </div>
    );
  }
}
