// import {
//     Alert
//   } from 'react-native';
import con from '../const';
import util from 'util';
// import string from '../res/string';
const errorString = {
  E10000: 'cannot connect to server',
  E10001: 'unknown error',
  E10002: 'invalid request',
  E10003: 'no auth',
}
const httpRequest = (_target, _params, _method) => {
  console.log('httpRequest...');
  if (_method == undefined || _method == '') _method = method.get;
  if (_params == undefined || _params == '') _params = [];
  if (_method == method.get) {
    _params.forEach(_param => {
      _target = util.format(_target, _param);
    });
  }
  let requestParam = {
    method: _method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  };
  if (_method == method.post) requestParam['body'] = JSON.stringify(_params);
  console.log('httpRequest=' + _target);
  return new Promise((resolve, reject) => {
    fetch(_target, requestParam).then((response) => {
      return response.json();
    }).then((responseJson) => {
      console.log('responseJson=' + responseJson); 
      if (responseJson.result == 'SUCCESS') resolve(responseJson.data);
      else {
        let errorCode = responseJson.data || 'E10000'; 
        if (responseJson.data.result == 'FAIL') errorCode = responseJson.data.data;
        
        if (errorCode == 'E10003') {
          if (global.logout instanceof Function) {
            global.logout();
          }
          global.location.href = "/Login?r="+escape(global.location.pathname+global.location.search);
        }
        else {
          alert('[' + errorCode + '] ' + errorString[errorCode]);
          reject(errorCode);
        }
      }
    }).catch(e => {
      alert('[E10000] ' + errorString['E10000']);
      reject('E10000');
    });
  });
}

const apiUrl = con.serverProtocol + '://' + con.serverIp + ':' + con.serverPort + '/home';

const target = {
  validate: apiUrl + '/login/validate',

  notices: apiUrl + '/support/notices?pageSize=%s&pageNum=%s',
  noticeDetail: apiUrl + '/support/notices/%s',
  faq: apiUrl + '/support/faq?pageSize=%s&pageNum=%s',

  qna: apiUrl + '/inquiry/list?pageSize=%s&pageNum=%s',
  qnaPostMsg: apiUrl + '/inquiry/insertQuestion',
  qnaDelete: apiUrl + '/inquiry/delete',
  qnaUpdate: apiUrl + '/inquiry/update',
  qnaGet: apiUrl + '/inquiry/get?idx=%s&password=%s',
  
  orderList: apiUrl + '/order/list?pageSize=%s&pageNum=%s',
  orderResult: apiUrl + '/order/result?orderId=%s&paymentToken=%s',
  orderRequest: apiUrl + '/order/request',
  orderEtc: apiUrl + '/order/etc',

  login: apiUrl + '/login',
  logout: apiUrl + '/logout',
  requestChangePasswordToken: apiUrl + '/signup/requestChangePasswordToken',
  naverLogin: apiUrl + '/login/naver',
  kakaoLogin: apiUrl + '/login/kakao',

  signUp: apiUrl + '/signup/signup',
  modifyInfo: apiUrl + '/signup/modify/info',

  couponList: apiUrl + '/coupon/list',
  couponUse: apiUrl + '/coupon/use',
  couponAdd: apiUrl + '/coupon/add',
  
  payAddress: apiUrl + '/pay/address/%s?orderId=%s',
  payPrice: apiUrl + '/pay/price',
  
  reviewCreate: apiUrl + '/review/create',
  reviewUpdate: apiUrl + '/review/update',
  reviewDelete: apiUrl + '/review/delete',
  reviewList: apiUrl + '/review/list?pageSize=%s&pageNum=%s',
  reviewWritable: apiUrl + '/review/writable',
  reviewCount: apiUrl + '/review/count',
  
  qnaCoinCreate: apiUrl + '/qna/create',
}

const method = {
  get: 'GET',
  post: 'POST'
}
export default {
  httpRequest,
  target,
  method
};
