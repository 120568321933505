import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import { modalOpen, modalClose, modalMessage, loadingOpen, loadingClose, login } from '../actions';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import httpClient from '../api/httpclient';
import LoginNaver from '../components/LoginNaver';
import * as qs from 'query-string';
import { reactLocalStorage } from 'reactjs-localstorage';
import string from '../String';

const FormItem = Form.Item;
class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
    };
    this.findPasswordValide = false;
  }
  componentDidMount() {
    console.log(this.props.location)
    let idRemember = reactLocalStorage.get('blobsidremember');
    if (idRemember == false || idRemember == 'false') idRemember = false;
    else idRemember = true;

    let id = idRemember ? reactLocalStorage.get('blobsid') : '';
    this.setState({
      id: id,
    });
    this.props.form.setFieldsValue({ id: id, remember: idRemember })
  }

  loginWithKakao = () => {
    global.Kakao.Auth.login({
      success: (authObj) => {

        httpClient.httpRequest(
          httpClient.target.kakaoLogin,
          { code: authObj.access_token },
          httpClient.method.post
        ).then((result) => {
          if (result.result) {
            this.props.onLogin(result.user);
            this.props.history.push('/');
          }
          else {
            this.props.modalOpen(string.logIn,
              (<div><div style={{ color: '#00aab6', paddingBottom: '10px' }}>{string.logInFailMsg01} </div><div>{string.logInFailMsg02}<br />{string.logInFailMsg03}</div></div>),
              [
                { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
              ]);
          }
        }).catch(e => {
        });

        // alert(authObj.access_token);

        // alert(JSON.stringify(authObj));
        // httpClient.httpRequest(httpClient.target.otherauth, { other: 'kakao', access_token: authObj.access_token }, httpClient.method.post).then((result) => {
        // });
      },
      fail: (err) => {
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const eTarget = e.target;
    reactLocalStorage.set('blobsidremember', this.props.form.getFieldValue('remember'));
    this.props.form.validateFields((err, values) => {
      if (!err) {

        this.props.loadingOpen();
        httpClient.httpRequest(httpClient.target.login, values, httpClient.method.post).then((result) => {
          this.props.loadingClose();
          if (result.result) {
            //로그인성공
            if (this.props.form.getFieldValue('remember')) {
              reactLocalStorage.set('blobsid', this.props.form.getFieldValue('id'));
              reactLocalStorage.set('blobsidremember', true);
            }
            else {
              reactLocalStorage.remove('blobsid');
              reactLocalStorage.set('blobsidremember', false);
            }

            //eTarget.reset();
            let userInfo = {
              type: 1,
              idx: result.user.idx,
              id: result.user.id,
              name: result.user.name,
              address: result.user.address,
              addressDetail: result.user.addressDetail,
              tel: result.user.tel,
              email: result.user.id
            }
            this.props.onLogin(userInfo);
            const location = {
              pathname: '/'
            }
            global.location.href = qs.parse(this.props.location.search).r || '/';
            //global.location.href = '/';
          }
          else {
            let reasonText = '';
            if (result.reason == 'INVALID_LOGIN_INFO') {
              reasonText = string.logInInvalidLogInInfo;
            }
            else if (result.reason == 'EMAIL_UNVERIFIED') {
              reasonText = (<div>{string.logInEmailUnverified01} <br />{string.logInEmailUnverified02}</div>);
            }
            else {
              reasonText = string.logInFailMsg02;
            }
            this.props.modalOpen(string.logIn,
              (<div><div style={{ color: '#00aab6', paddingBottom: '10px' }}>{string.logInFailMsg01} </div><div>{reasonText}</div></div>),
              [
                { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
              ]);
          }
        }).catch(e => {
          this.props.loadingClose();
        });

        console.log('Received values of form: ', values);
      }
    });
  }

  validateFindPasswordEmail = () => {
    //let emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    let emailRegExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (this.findPasswordId == '') {
      this.props.modalMessage(<div style={{ color: '#ff0000' }}>{string.emailRule02}</div>);
      this.findPasswordValide = false;
    }
    else if (!emailRegExp.test(this.findPasswordId)) {
      this.props.modalMessage(<div style={{ color: '#ff0000' }}>{string.emailRule03}</div>);
      this.findPasswordValide = false;
    }
    else {
      this.props.modalMessage('');
      this.findPasswordValide = true;
    }
  }

  findPassword = () => {
    this.props.modalOpen(string.logInFindPassword,
      (<div>
        <div style={{ color: '#000', paddingBottom: '10px' }}>
          {string.logInFindPasswordMsg01}
          {string.logInFindPasswordMsg02}<br />
        </div>
        <div className='ant-form-item-control'>
          <div className="login-field-wrapper">
            <div className="login-field-icon">
              <img src={require('../img/login/id.png')} />
            </div>
            <div className="login-field-input">
              <Input placeholder={string.id} onChange={(e) => { this.findPasswordId = e.target.value; this.validateFindPasswordEmail(); }} className="box height-48 pl-60 fs-15" />
            </div>
          </div>
        </div>
      </div>),
      [
        {
          text: string.confirm, callback: () => {
            if (this.findPasswordValide) {

              this.props.loadingOpen();
              httpClient.httpRequest(httpClient.target.requestChangePasswordToken, { email: this.findPasswordId }, httpClient.method.post).then((result) => {
                this.props.loadingClose();
                this.props.modalClose();
                this.props.modalOpen(string.logInFindPassword,
                  (<div>{string.logInPasswordModifyMsg01}<br />{string.logInPasswordModifyMsg02}</div>),
                  [
                    { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
                  ]);
              }).catch(e => {
                this.props.loadingClose();
                this.props.modalClose();
                this.props.modalOpen(string.logInFindPassword,
                  (<div>{string.logInPasswordModifyFailMsg01}<br />{string.logInPasswordModifyFailMsg02}</div>),
                  [
                    { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
                  ]);
              });
            }
          }, color: '#00aab6'
        },
        { text: string.cancle, callback: this.props.modalClose, color: '#000' },
      ]);

  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <div>
        <div className="menu-line"></div>
        <div className="container" style={{textAlign: 'center'}}>
          <div className="login-title-wrapper">
            <div className="login-title-text">
              <b>{string.blobs}</b> {string.membership}
            </div>
          </div>
          <div className="menu-line" />
          <div className="login-form-wrapper">
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <div className="login-text-login">
                {string.logIn}
              </div>
              <div className="login-text-sub">
                {string.welcomeMsg}
              </div>
            </div>

            <div className="login-form-left">

              <Form onSubmit={this.handleSubmit}>
                <FormItem
                  className="login-item-wrapper"
                >
                  {getFieldDecorator('id', {
                    rules: [{
                      type: 'email', message: string.emailRule03,
                    }, {
                      required: true, message: string.emailRule04,
                    }],
                  })(
                    <div className="login-field-wrapper">
                      <div className="login-field-icon">
                        <img src={require('../img/login/id.png')} />
                      </div>
                      <div className="login-field-input">
                        <Input placeholder={string.id} value={this.state.id} onChange={(e) => { this.setState({ id: e.target.value }) }}/>
                      </div>
                    </div>
                  )}
                </FormItem>
                <FormItem
                  className="login-item-wrapper"
                >
                  {getFieldDecorator('password', {
                    rules: [{
                      required: true, message: string.passWordRule,
                    }, {
                      validator: this.validateToNextPassword,
                    }],
                  })(
                    <div className="login-field-wrapper">
                      <div className="login-field-icon">
                        <img src={require('../img/login/password.png')} />
                      </div>
                      <div className="login-field-input">
                        <Input type="password" placeholder={string.passWord}/>
                      </div>
                    </div>
                  )}
                </FormItem>
                <div className="menu-line" />
                <div className="login-field-checkbox">
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                  })(
                    <Checkbox>{string.idSave}</Checkbox>
                  )}
                </FormItem>
                    </div>
                
                <div className="" >
                  <Button className="login-btn" htmlType="submit">{string.logIn}</Button>
                </div>

                <div style={{textAlign: 'left'}}>
                  <div className="login-btn-sub-wrapper">
                    <Link to="/signup">
                      <Button className="login-btn-sub" style={{ border: "0px", color: "rgb(93,93,93)", backgroundColor: "#cbced3" }}>{string.signUp}</Button>
                    </Link>
                  </div>
                  <div className="login-btn-sub-wrapper">
                    <Button onClick={this.findPassword} className="login-btn-sub" style={{ border: "0px", color: "rgb(93,93,93)" }}>{string.logInFindPassword}</Button>
                  </div>
                </div>
              </Form>
            </div>
            <div className="login-form-right">
              <div>
                <LoginNaver />
              </div>
              <div style={{ marginTop: "10px" }}>
                <a id="custom-login-btn">
                  <img className="kakao-login-btn-size" onClick={() => this.loginWithKakao()}
                    src={require('../img/login/kakao.png')} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    modalOpen: (title, content, buttons) => dispatch(modalOpen(title, content, buttons)),
    modalClose: () => dispatch(modalClose()),
    modalMessage: (msg) => dispatch(modalMessage(msg)),
    loadingOpen: () => dispatch(loadingOpen()),
    loadingClose: () => dispatch(loadingClose()),
    onLogin: (userinfo) => dispatch(login(userinfo)),
  }
}
let LoginForm = Form.create()(Login);
LoginForm = connect(undefined, mapDispatchToProps)(LoginForm);

export default LoginForm;