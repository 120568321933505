import React from 'react';
import uuidv4 from 'uuid/v4';
import con from '../const';

class LoginNaver extends React.Component {
    handleClick = () => {
        var state = uuidv4();
        var naverUrl = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=' + 'sq6ojwwsHPTt6xNeEMep' + '&redirect_uri=' + con.homeFrontUrl + '/navercallback' + '&state=' + state;
        window.location.href = naverUrl;
    }

    render() {
        return(
            <div>
                <a href="#" onClick={this.handleClick}><img className="naver-login-btn-size" src={require('../img/login/naver.png')}/></a>
            </div>
        )
    };
};

export default LoginNaver;