import React, { Component } from 'react';
import Header from '../components/Header';
import string from '../String';
import { Link, withRouter } from "react-router-dom";
export default class BlobsCard extends Component {

  onClickDownload = () => {
    if (/Android/i.test(navigator.userAgent)) {
      global.location.href = 'http://play.google.com/store/apps/details?id=com.jba';
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      global.location.href = 'https://itunes.apple.com/kr/app/%EB%B8%94%EB%9E%8D%EC%8A%A4/id1428926701?mt=8'
    } else {
      alert(string.blobscard.appDownAlert);
    }
  }

  render() {
    return (
      <div className="">
        <div className="blobscard-top">
          <div className="container" style={{height: '560px'}}>
            <div className="blobscard-title">{string.blobscard.mainTitle}</div>
            <div className="blobscard-title-explain" dangerouslySetInnerHTML={{__html: string.blobscard.mainTitleExplain}}></div>
            <div className="blobscard-title-button">
              <Link to="/blobscardpurchase"><div className="blobscard-title-button-01">{string.blobscard.mainTitleButton01}</div></Link>
              <div className="blobscard-title-button-02" onClick={this.onClickDownload}>{string.blobscard.mainTitleButton02}</div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mobile" style={{minHeight: '50px'}}></div>
          <div className="blobscard-content-left p-t-110">
            <img src={require('../img/blobscard/main_content01.png')} className="blobscard-content-image"/>
          </div>
          <div className="blobscard-content-right p-t-150">
            <div className="blobscard-content-number">01</div>
            <div className="blobscard-content-title" dangerouslySetInnerHTML={{__html: string.blobscard.mainContent01Title}}></div>
            <div className="blobscard-content-explain">{string.blobscard.mainContent01Explain}</div>
          </div>
          <div className="desk" style={{minHeight: '550px'}}></div>
          <div className="mobile" style={{minHeight: '50px'}}></div>
        </div>
        <div className="blobscard-content-02-wrapper">
          <div className="container">
            <div className="mobile" style={{minHeight: '50px'}}></div>
            <div className="blobscard-content-right p-t-150 mobile">
              <img src={require('../img/blobscard/main_content02.png')} className="blobscard-content-image"/>
            </div>
            <div className="blobscard-content-left p-t-110">
              <div className="blobscard-content-number">02</div>
              <div className="blobscard-content-title" dangerouslySetInnerHTML={{__html: string.blobscard.mainContent02Title}}></div>
              <div className="blobscard-content-explain">{string.blobscard.mainContent02Explain}</div>
            </div>
            <div className="blobscard-content-right p-t-150 desk">
              <img src={require('../img/blobscard/main_content02.png')} className="blobscard-content-image"/>
            </div>
            <div className="desk" style={{minHeight: '550px'}}></div>
            <div className="mobile" style={{minHeight: '50px'}}></div>
          </div>
        </div>
        <div className="container">
          <div className="mobile" style={{minHeight: '50px'}}></div>
          <div className="blobscard-content-left p-t-110">
            <img src={require('../img/blobscard/main_content03.png')} className="blobscard-content-image"/>
          </div>
          <div className="blobscard-content-right p-t-150">
            <div className="blobscard-content-number">03</div>
            <div className="blobscard-content-title" dangerouslySetInnerHTML={{__html: string.blobscard.mainContent03Title}}></div>
            <div className="blobscard-content-explain">{string.blobscard.mainContent03Explain}</div>
          </div>
          <div className="desk" style={{minHeight: '650px'}}></div>
          <div className="mobile" style={{minHeight: '50px'}}></div>
        </div>
        <div className="blobscard-content-04-wrapper">
          <div className="container">
            <div className="blobscard-content-right mobile">
              <img src={require('../img/blobscard/main_content04.png')} className="blobscard-content-image"/>
            </div>
            <div className="blobscard-content-left p-t-110">
              <div className="blobscard-content-number">04</div>
              <div className="blobscard-content-title" dangerouslySetInnerHTML={{__html: string.blobscard.mainContent04Title}}></div>
              <div className="blobscard-content-explain">{string.blobscard.mainContent04Explain}</div>
            </div>
            <div className="blobscard-content-right desk">
              <img src={require('../img/blobscard/main_content04.png')} className="blobscard-content-image"/>
            </div>
            <div className="desk" style={{minHeight: '550px'}}></div>
            <div className="mobile" style={{minHeight: '50px'}}></div>
          </div>
        </div>
        <div className="container">
          <div className="mobile" style={{minHeight: '50px'}}></div>
          <div className="blobscard-content-left p-t-110">
            <img src={require('../img/blobscard/main_content05.png')} className="blobscard-content-image"/>
          </div>
          <div className="blobscard-content-right p-t-150">
            <div className="blobscard-content-number">05</div>
            <div className="blobscard-content-title" dangerouslySetInnerHTML={{__html: string.blobscard.mainContent05Title}}></div>
            <div className="blobscard-content-explain">{string.blobscard.mainContent05Explain}</div>
          </div>
          <div className="desk" style={{minHeight: '550px'}}></div>
          <div className="mobile" style={{minHeight: '50px'}}></div>
        </div>
      </div>
    );
  }
}
