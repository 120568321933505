import React from 'react';
import { Table, Col, Radio, Checkbox, Breadcrumb, Form, Input, InputNumber, Select, AutoComplete } from 'antd';
import { Link, Route } from 'react-router-dom';
import httpClient from '../api/httpclient';
import string from '../String';
class PurchaseList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pagination: {},
      loading: false,
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
    
  }

  fetch = (params = {}) => {
    console.log('params:', params);
    this.setState({ loading: true });

    let page = params.page || 1;

    httpClient.httpRequest(
      httpClient.target.orderList,
      [10,page]
    ).then((result) => {
      console.log('result:', JSON.stringify(result));
      const pagination = { ...this.state.pagination };
      pagination.total = result.totalCount;
      this.setState({
        loading: false,
        data: result.map(value => {
          console.log('value:', JSON.stringify(value));
          let totalQuantity = 0;
          value.products.forEach((prod) => {
            totalQuantity += prod.quantity;
          });
          totalQuantity = totalQuantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          let stateString = "";
          if (value.orderStatus == 'REQUEST') stateString = string.purchaseListStatus01;
          else if (value.orderStatus == 'PAYING') stateString = string.purchaseListStatus02;
          else if (value.orderStatus == 'PAID') stateString = string.purchaseListStatus03;
          else if (value.orderStatus == 'DELIVER') stateString = string.purchaseListStatus04;
          else if (value.orderStatus == 'DONE') stateString = string.purchaseListStatus05;
          else if (value.orderStatus == 'CANCEL') stateString = string.cancle;
          return {
            key: value.orderId,
            date: value.createDate,
            content: totalQuantity + string.quantityUnit,
            address: value.targetAddress + ' ' + value.targetAddressDetail,
            state: stateString,
            productName: value.products[0].productName,
            image: value.products[0].productIdx == 1 ? require('../img/blobscard/purchase03.jpg') : require('../img/ethereumcard/purchase03.png')
          };
        }),
        pagination,
      });
    }).catch(e => {
      this.setState({ loading: false });
    });
  }

  componentDidMount() {
    this.fetch();
  }

  renderDetail(record, index) {
    return (
    <div>
      <img src={record.image} className='purchaselist-image'/>
      <div className='purchaselist-detail'>
        <ul style={{textAlign: 'left'}}>
          <li>{string.orderNumber} : {record.key}</li>
          <li>{string.date} : {record.date}</li>
          <li>{string.productInfo} : {record.content}</li>
          <li>{string.deliveryAddress} : {record.address}</li>
          <li>{string.status} : {record.state}</li>
        </ul>
      </div>
    </div>)
  }

  render() {

    const columns = [{
      title: string.orderNumber,
      dataIndex: 'key',
      key: 'key',
      className: 'desk'
    }, {
      title: string.date,
      dataIndex: 'date',
      key: 'date',
      className: 'desk'
    }, {
      title: string.productInfo,
      dataIndex: 'content',
      key: 'content',
      className: '',
      render: (text, record) => {
        return (
          <div>
            {record.productName} <font style={{ color: 'blue' }}>{text}</font>
          </div>
        );
      }
    }, {
      title: string.deliveryAddress,
      dataIndex: 'address',
      key: 'address',
      className: 'desk',
    }, {
      title: string.status,
      dataIndex: 'state',
      key: 'state',
      className: '',
    }];

    return (
      <div>
        <div className="menu-line"></div>
        <div className="container" style={{textAlign: 'center'}}>
          <div className="login-title-wrapper">
            <div className="login-title-text">
              {string.purchaseListMyOrderList}
              </div>
          </div>
          <div className="perchaselist-wrapper">
            <div style={{ width: "100%", height: "2px", backgroundColor: "#00aab6", margin: "auto" }} />
            <Table id="perchaselist-table"
              columns={columns}
              rowKey={record => record.key}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              expandedRowRender={this.renderDetail}
              expandRowByClick={true}
            />
          </div>
        </div>
      </div>

    );
  }
}
export default PurchaseList;