import React, { Component } from 'react';
import Main from './components/Main';
import { Route, Switch } from 'react-router-dom';

import NotFound from './pages/NotFound';
import NaverCallBack from './pages/NaverCallBack';
import KakaoCallBack from './pages/KakaoCallBack';
import AgreementApp from './pages/AgreementApp';
import kspayResult from './pg/kspayResult';
import kspayResultMobile from './pg/kspayResultMobile';

class App extends Component {
  constructor(props) {
      super(props);
      global.Kakao.init('38e855ad53eaa9c06f82d8f7f2223cd8');
  }
  render() {
    return (
      <Switch>
          <Route exact path="/navercallback" component={NaverCallBack} />
          <Route exact path="/kakaocallback" component={KakaoCallBack} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/App/Agreement" component={AgreementApp} />
          <Route exact path="/kspayResult" component={kspayResult} />
          <Route exact path="/kspayResultMobile" component={kspayResultMobile} />
          <Route path="/:page" component={Main} />
          <Route path="/" component={Main} />
      </Switch>
    );
  }
}

export default App;
