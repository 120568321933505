import { OPEN, CLOSE, MESSAGE, OPEN_LOADING, CLOSE_LOADING, MOVE, LOGIN, LOGOUT } from '../actions';
import { combineReducers } from 'redux';
import {reactLocalStorage} from 'reactjs-localstorage';

const modalInitialState = {
  isOpen: false,
  title: '',
  content: '',
  message: '',
  buttons: [{ text: '', callback: '' }]
};
const loadingInitialState = {
  openCount: 0,
};
const menuInitialState = {
  selectedItem: 0
};
const loginInitialState = {
  isLogin: false,
  loginInfo: {
    type: '',
    id: '',
    name: '',
    address: '',
    addressDetail: '',
    tel: '',
    email: '', 
    userType: ''
  }
};

const modal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN:
      return Object.assign({}, state, {
        isOpen: true,
        title: action.title,
        content: action.content,
        buttons: action.buttons,
      });
    case CLOSE:
      return Object.assign({}, state, {
        isOpen: false
      });
    case MESSAGE:
      return Object.assign({}, state, {
        message: action.message
      });
    default:
      return state;
  }
};
const loading = (state = loadingInitialState, action) => {
  switch (action.type) {
    case OPEN_LOADING:
      return Object.assign({}, state, {
        openCount: state.openCount + 1
      });
    case CLOSE_LOADING:
      return Object.assign({}, state, {
        openCount: state.openCount - 1
      });
    default:
      return state;
  }
};

const menu = (state = menuInitialState, action) => {
  switch (action.type) {
    case MOVE:
      return Object.assign({}, state, {
        selectedItem: action.item
      });
    default:
      return state;
  }
};

const login = (state = loginInitialState, action) => {
  switch (action.type) {
    case LOGIN:
      reactLocalStorage.setObject('blobsuser', action.loginInfo);
      return Object.assign({}, state, {
        isLogin: true,
        loginInfo: action.loginInfo
      });
    case LOGOUT:
      reactLocalStorage.remove('blobsuser');
      return Object.assign({}, state, {
        isLogin: false,
        loginInfo: {
          type: '',
          id: '',
          name: '',
          address: '',
          addressDetail: '',
          tel: '',
          email: '',
          userType: ''
        }
      });
    default:
      return state;
  }
};

const blobsHome = combineReducers({
  modal,
  loading,
  menu,
  login
});

export default blobsHome;