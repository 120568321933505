import React from 'react';
import { modalOpen, modalClose, loadingOpen, loadingClose } from '../actions';
import { connect } from 'react-redux';
import { Tabs, Button, Radio, Checkbox, Form, Input, Icon, Select, AutoComplete, Modal } from 'antd';
import { Link, Route } from 'react-router-dom';
import Agreement from '../pages/Agreement';
import axios from 'axios';
import queryString from 'query-string';
import AgreementSub3 from './AgreementSub3';
import con from '../const';
import httpClient from '../api/httpclient';
import * as copy from 'copy-to-clipboard';
import string from '../String';

var QRCode = require('qrcode.react');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const AutoCompleteOption = AutoComplete.Option;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};


class PurchaseForm extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.location.state == null || this.props.location.state.goods == null) {
      alert('상품정보가 없습니다.')
      global.location.href='/';
    }
    this.goods = this.props.location.state.goods;
    this.quantity = this.props.location.state.quantity;
    this.state = {
      products: [{
        productIdx: this.goods.id,
        price: this.goods.price,
        quantity: this.quantity || 1,
        totalPrice: (this.goods.price * (this.quantity || 1)),
        totalPriceString: (this.goods.price * (this.quantity || 1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }],
      coinPayAddress: [],
      coinPayPrice: [],
      coinPayType: 31
    }
    this.nonuserPurchase = false;
  }
  componentDidMount() {

    httpClient.httpRequest(httpClient.target.validate).then((result) => {
      if (result == true) {
      }
      else {
        if (global.logout instanceof Function) global.logout();
        if (!this.nonuserPurchase) {
          this.props.modalOpen(string.purchasebtn01,
          (<div style={{}}>
              {string.purchaseLoginmsg01}<br/><br/>
              {string.purchaseLoginmsg02}<br/><br/>
              {string.purchaseLoginmsg03}
          </div>),
          [
            { text: string.purchasebtn02, callback: ()=>{
              this.nonuserPurchase = true;
              this.props.modalClose();
            }, color: '#00aab6' },
            { text: string.purchasebtn03, callback: ()=>{ 
              this.props.modalClose();
              let callbackpage = '';
              if (this.goods.id == 1) callbackpage='/blobscardpurchase';
              if (this.goods.id == 2) callbackpage='/ethereumcardpurchase';
              this.props.history.push({ pathname: '/login', search: 'r='+callbackpage });
            }, color: '#000' },
          ]);
        }
      }
    }).catch(e => {
      if (global.logout instanceof Function) global.logout();
    });
  }
  coinPaymentTab(coinType) {
    let address = this.state.coinPayAddress.find(p=>p.coinType==coinType).address;
    let price = (Math.floor(this.state.products[0].totalPrice*0.9*10000/(this.state.coinPayPrice.find(p=>p.coinType==coinType).currency))/10000).toString();
    let expireDate = this.state.coinPayPrice.find(p=>p.coinType==coinType).expireDate;

    //리플인경우 destination tag 강조
    let addressDp = (<div>{address}</div>);
    if (address.indexOf('(Destination Tag)') > 0) {
      addressDp = (
        <div>
          {address.split('(Destination Tag)')[0].trim()}
          <span style={{color: 'rgb(255, 109, 36)', fontWeight: 'bold'}}>(Destination Tag) {address.split('(Destination Tag)')[1].trim()}</span>
        </div>
      );

    }

    return (
      <div style={{display: 'inline-block', width: '100%', padding: '10px', textAlign: 'center'}}>
        <div style={{paddingBottom: '10px', wordBreak: 'break-all'}}>{addressDp}</div>
        <QRCode value={address} />
        <div style={{color: 'rgb(255, 109, 36)', fontSize: '18px', fontWeight: 'bold', marginTop: '20px'}}>{price} {coinType}</div>
        <div style={{color:'#00aab6'}}>({string.purchaseForCoinMsg12}: {expireDate})</div>
      </div>
    );
  }
  coinPayment(orderId, token) {

    httpClient.httpRequest(httpClient.target.payAddress, ["NAN", orderId]).then((result) => {
      this.setState({coinPayAddress: result});
      httpClient.httpRequest(httpClient.target.payPrice).then((result) => {
        this.setState({coinPayPrice: result});

        this.props.modalOpen(string.purchasebtn04,
          (<div style={{}}>
            <div dangerouslySetInnerHTML={{__html:string.purchaseForCoinMsg01}}>
            </div>
            <Tabs onChange={(props)=>{this.setState({coinPayType: props});}} defaultActiveKey="31" tabPosition={'left'}>
              {/* <TabPane tab={<span><img src={require('../img/symbol/coin_blb.png')} width={20} style={{marginRight:'8px'}}/>{string.blobs}</span>} key="31">
                {this.coinPaymentTab('BLB')}
              </TabPane> */}
              <TabPane tab={<span><img src={require('../img/symbol/coin_btc.png')} width={20} style={{marginRight:'8px'}}/>{string.coin.coinName02}</span>} key="1">
                {this.coinPaymentTab('BTC')}
              </TabPane>
              <TabPane tab={<span><img src={require('../img/symbol/coin_eth.png')} width={15} style={{marginRight:'8px'}}/>{string.coin.coinName03}</span>} key="2">
                {this.coinPaymentTab('ETH')}
              </TabPane>
              <TabPane tab={<span><img src={require('../img/symbol/coin_xrp.png')} width={20} style={{marginRight:'8px'}}/>{string.coin.coinName04}</span>} key="3">
                {this.coinPaymentTab('XRP')}
              </TabPane>
            </Tabs>
            <div>
              <br/>
              {string.purchaseForCoinMsg09}
            </div>
          </div>),
          [
            { text: string.purchaseForCoinMsg10, callback: ()=>{ 
              try {
                let coinString = '';
                if (this.state.coinPayType == 31) coinString = 'BLB';
                if (this.state.coinPayType == 1) coinString = 'BTC';
                if (this.state.coinPayType == 2) coinString = 'ETH';
                if (this.state.coinPayType == 3) coinString = 'XRP';
                let address = this.state.coinPayAddress.find(p=>p.coinType==coinString).address;
                let alertString = string.purchaseForCoinMsg11;
                
                if (this.state.coinPayType == 3) {
                  address = address.split('(Destination Tag)')[0].trim();
                  alertString = string.purchaseForCoinMsg13;
                }

                copy(address);
                alert(alertString)
              }
              catch (e) {
              }
            }, color: '#00aab6' },
            { text: string.complete, callback: ()=>{ 

              let coinString = '';
              if (this.state.coinPayType == 31) coinString = 'BLB';
              if (this.state.coinPayType == 1) coinString = 'BTC';
              if (this.state.coinPayType == 2) coinString = 'ETH';
              if (this.state.coinPayType == 3) coinString = 'XRP';
              let price = (Math.floor(this.state.products[0].totalPrice*0.9*10000/(this.state.coinPayPrice.find(p=>p.coinType==coinString).currency))/10000).toString();
              let paymentEtc = price + ' ' + coinString + ' ' + this.state.coinPayAddress.find(p=>p.coinType==coinString).address;
              httpClient.httpRequest(httpClient.target.orderEtc, {orderId: orderId, paymentEtc: paymentEtc}, httpClient.method.post).then((result) => {
                this.props.modalClose();
                this.props.history.push({ pathname: '/purchaseResult', search: '?ordno='+orderId+'&token='+token });
              }).catch(e => {
                this.props.modalClose();
                this.props.history.push({ pathname: '/purchaseResult', search: '?ordno='+orderId+'&token='+token });
              });
              
            }, color: '#000' },
          ]);

      }).catch(e => {});
    }).catch(e => {});
  }

  bankPayment(orderId, token) {
    let expireDate = new Date();
    expireDate.setDate(expireDate.getDate()+7);

    this.props.modalOpen(string.purchaseDepositless,
    (<div style={{}}>
      <div>
        {string.purchaseDepositlessMsg01}<br/>
        <span style={{color:'#00aab6', fontWeight: 'bold'}}>{expireDate.getFullYear()}{string.year} {expireDate.getMonth()+1}{string.month} {expireDate.getDate()}{string.day}</span>{string.purchaseDepositlessMsg02} 
      </div>
      <div style={{textAlign: 'center', color: 'rgb(255, 109, 36)', fontSize: '18px', padding: '20px', lineHeight: '24px', fontWeight: 'bold'}}>
        {string.purchaseDepositlessMsg03}<br/>
        {string.purchaseDepositlessMsg04}<br/>
      </div>
      <div dangerouslySetInnerHTML={{__html:string.purchaseDepositlessMsg05}}>
      </div>
    </div>),
    [
      { text: string.purchaseDepositlessMsg08, callback: ()=>{ 
        try {
          copy(string.purchaseDepositlessMsg10);
          alert(string.purchaseDepositlessMsg09)
        }
        catch (e) {

        }
      }, color: '#00aab6' },
      { text: string.complete, callback: ()=>{ 
        let paymentEtc = string.purchaseDepositlessMsg03 + ' ' + string.purchaseDepositlessMsg04;
        httpClient.httpRequest(httpClient.target.orderEtc, {orderId: orderId, paymentEtc: paymentEtc}, httpClient.method.post).then((result) => {
          this.props.modalClose();
          this.props.history.push({ pathname: '/purchaseResult', search: '?ordno='+orderId+'&token='+token });
        }).catch(e => {
          this.props.modalClose();
          this.props.history.push({ pathname: '/purchaseResult', search: '?ordno='+orderId+'&token='+token });
        });
      }, color: '#000' },
    ]);

  }
  startPayment() {
    
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {

        let formData = { 
          email: this.props.form.getFieldValue('buyerEmail'),
          name: this.props.form.getFieldValue('buyerName'),
          password: this.props.form.getFieldValue('buyerPassword'),
          paymentToken: '',
          paymentType: this.props.form.getFieldValue('payType'),
          products: this.state.products,
          targetAddress: this.props.form.getFieldValue('destAddress'),
          targetAddressDetail: this.props.form.getFieldValue('destAddressDetail'),
          targetMemo: this.props.form.getFieldValue('deliverMsg'),
          targetName: this.props.form.getFieldValue('destName'),
          targetTel: this.props.form.getFieldValue('destTel'),
          tel: this.props.form.getFieldValue('buyerTel')
        };
    
        httpClient.httpRequest(httpClient.target.orderRequest, formData, httpClient.method.post).then((result) => {
          if (result.orderId != null && result.orderId.length >= 11) {
            let payType = this.props.form.getFieldValue('payType');
            if (payType == '1001') {
              this.coinPayment(result.orderId, result.token);
              return;
            }
            if (payType == '1002') {
              this.bankPayment(result.orderId, result.token);
              return;
            }

            if (payType == '3') global.document.KSPayWeb.sndPaymethod.value = '1000000000';
            if (payType == '5') global.document.KSPayWeb.sndPaymethod.value = '0010000000';
            if (payType == '6') global.document.KSPayWeb.sndPaymethod.value = '0100000000';
            
            global.document.KSPayWeb.sndOrdernumber.value = result.orderId;
            global.document.KSPayWeb.sndOrdername.value = this.props.form.getFieldValue('buyerName');
            global.document.KSPayWeb.sndEmail.value = this.props.form.getFieldValue('buyerEmail');
            global.document.KSPayWeb.sndMobile.value = this.props.form.getFieldValue('buyerTel');
            
            if (/Android/i.test(navigator.userAgent) || /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
              global.document.KSPayWeb.sndReply.value = con.billingRcvMobileUrl;
              global._payMobile(global.document.KSPayWeb);
            } else {
              global.document.KSPayWeb.sndReply.value = con.billingRcvUrl;
              global._pay(global.document.KSPayWeb);
            }
          }
          else {
            this.props.modalOpen(string.purchaseOrderProduct,
            (<div style={{}}>
                {string.purchaseOrderProductMsg01} <br/>
                {string.purchaseOrderProductMsg02}
            </div>),
            [
              { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
            ]);
          }
        }).catch(e => {
        });
      }
    })
  }
  handleSubmit = (e) => {

    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) 
      {
        //pay 시작 전 3,5,6인 경우(KSNET 결제) 팝업창 미리 띄움
        let payType = this.props.form.getFieldValue('payType');
        if ((payType == '3' || payType == '5' || payType == '6')
            && !/Android/i.test(navigator.userAgent) && !/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          let popFlag = global.prePopup();
          if (!popFlag) {
            alert(string.purchaseFailPopupMsg);
            return;
          }
        }

        this.startPayment();
      }
    });
  }
  onPressAddress = (e) => {
    e.preventDefault();
  }
  onChangeName = (e) => {
    if (this.state.checkSameDeliverInfo) {
      this.props.form.setFieldsValue({
        destName: e.target.value
      });
    }
  }
  onChangeTel = (e) => {
    if (this.state.checkSameDeliverInfo) {
      this.props.form.setFieldsValue({
        destTel: e.target.value
      });
    }
  }
  onChangeTel = (e) => {
    if (this.state.checkSameDeliverInfo) {
      this.props.form.setFieldsValue({
        destTel: e.target.value
      });
    }
  }
  onChangeTel = (e) => {
    if (this.state.checkSameDeliverInfo) {
      this.props.form.setFieldsValue({
        destTel: e.target.value
      });
    }
  }
  onChangeAddressDetail = (e) => {
    if (this.state.checkSameDeliverInfo) {
      this.props.form.setFieldsValue({
        destAddressDetail: e.target.value
      });
    }
  }
  onChangePrefix = (value) => {
    console.log("prefix" + this.props.form.getFieldValue('prefix'));
    if (this.state.checkSameDeliverInfo) {
      this.props.form.setFieldsValue({
        prefix2: value
      });
    }
  }
  onClickFindAddress = () => {
    const form = this.props.form;
    new global.daum.Postcode({
      oncomplete: function (data) {
        form.setFieldsValue({ destAddress: data.roadAddress });
        form.setFieldsValue({ buyerAddress: data.roadAddress });
      }
    }).open();
  }
  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('buyerPassword')) {
      callback(string.purchasePasswordIncorrect);
    } else {
      callback();
    }
  }
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }
  handleDeliverCheckChange = (e) => {
    this.setState({
      checkSameDeliverInfo: e.target.checked,
    }, () => {
      this.props.form.validateFields(['destName'], { force: true });
      this.props.form.setFieldsValue({
        destName: this.props.form.getFieldValue('buyerName'),
        destTel: this.props.form.getFieldValue('buyerTel'),
        prefix2: this.props.form.getFieldValue('prefix')
      });
      if (this.props.isLogin) {
        this.props.form.setFieldsValue({
          destAddress: this.props.form.getFieldValue('buyerAddress'),
          destAddressDetail: this.props.form.getFieldValue('buyerAddressDetail')
        })
      }
    });
  }
  onChangeAgree1 = (e) => {
    if (e.target.checked)
      this.agreement1();
  }
  agreement1 = () => {
    this.props.modalOpen(string.purchasePolicyMsg01,
      (<div style={{height: '300px', overflow: 'auto'}}>
          <AgreementSub3/>
      </div>),
      [
        { text: string.confirm, callback: this.props.modalClose, color: '#00aab6' },
      ]);
  }
  validateChecked = (rule, value, callback) => {
    const form = this.props.form;
    if (value == undefined || value) {
      callback();
    } else {
      callback(string.purchasePolicyMsg02);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div>
        <Form name='KSPayWeb' onSubmit={this.handleSubmit} style={{ margin: 'auto' }}>
        
          <input type="hidden" name="sndCharSet" value='utf-8'/>{/* 캐릭터셋 */}
          <input type="hidden" name="sndPaymethod" value=''/>{/* 결제방식 */}
          <input type='hidden' name='sndStoreid' value='2001106277'/>
          {/* <input type='hidden' name='sndStoreid' value='2999199999'/> */}
          <input type='hidden' name='sndOrdernumber' value=''/>{/* 주문번호 */}
          <input type='hidden' name='sndGoodname' value={this.goods.name}/>
          <input type='hidden' name='sndAmount' value={this.state.products[0].totalPrice}/>
          <input type='hidden' name='sndOrdername' value=''/>{/* 주문자명 */}
          <input type='hidden' name='sndEmail' value=''/>{/* 주문자메일 */}
          <input type='hidden' name='sndMobile' value='' />{/* 주문자연락처 */}
          <input type='hidden' name='sndAllregid' value=''/>{/* 주민번호 */}

          <input type='hidden'  name='sndServicePeriod'  value="-"/>

          {/* <!-- 0. 공통 환경설정 --> */}
          <input type='hidden'	name='sndReply' value=''/>
          <input type='hidden'  name='sndGoodType' value="1"/> 	{/* <!-- 상품유형: 실물(1),디지털(2) --> */}
          
          {/* <!-- 1. 신용카드 관련설정 --> */}
          
          {/* <!-- 신용카드 결제방법  --> */}
          {/* <!-- 일반적인 업체의 경우 ISP,안심결제만 사용하면 되며 다른 결제방법 추가시에는 사전에 협의이후 적용바랍니다 --> */}
          <input type='hidden'  name='sndShowcard' value="I,M"/> {/* <!-- I(ISP), M(안심결제), N(일반승인:구인증방식), A(해외카드), W(해외안심)-->*/}
          
          {/* <!-- 신용카드(해외카드) 통화코드: 해외카드결제시 달러결제를 사용할경우 변경 --> */}
          <input type='hidden'	name='sndCurrencytype' value="WON"/> {/* <!-- 원화(WON), 달러(USD) -->*/}
          
          {/* <!-- 할부개월수 선택범위 --> */}
          {/* <!--상점에서 적용할 할부개월수를 세팅합니다. 여기서 세팅하신 값은 결제창에서 고객이 스크롤하여 선택하게 됩니다 --> */}
          {/* <!--아래의 예의경우 고객은 0~12개월의 할부거래를 선택할수있게 됩니다. --> */}
          <input type='hidden'	name='sndInstallmenttype' value="ALL(0:2:3:4:5:6:7:8:9:10:11:12)"/>
          
          {/* <!-- 가맹점부담 무이자할부설정 --> */}
          {/* <!-- 카드사 무이자행사만 이용하실경우  또는 무이자 할부를 적용하지 않는 업체는  "NONE"로 세팅  --> */}
          {/* <!-- 예 : 전체카드사 및 전체 할부에대해서 무이자 적용할 때는 value="ALL" / 무이자 미적용할 때는 value="NONE" --> */}
          {/* <!-- 예 : 전체카드사 3,4,5,6개월 무이자 적용할 때는 value="ALL(3:4:5:6)" --> */}
          {/* <!-- 예 : 삼성카드(카드사코드:04) 2,3개월 무이자 적용할 때는 value="04(3:4:5:6)"--> */}
          {/* <!-- <input type=hidden	name=sndInteresttype value="10(02:03),05(06)"> --> */}
          <input type='hidden'	name='sndInteresttype' value="NONE"/>

          {/* <!-- 2. 온라인입금(가상계좌) 관련설정 --> */}
          <input type='hidden'	name='sndEscrow' value="1"/> 		{/* 	<!-- 에스크로사용여부 (0:사용안함, 1:사용) -->*/}
          
          {/* <!-- 3. 월드패스카드 관련설정 --> */}
          <input type='hidden'	name='sndWptype' value="1"/>  			{/* <!--선/후불카드구분 (1:선불카드, 2:후불카드, 3:모든카드) -->*/}
          <input type='hidden'	name='sndAdulttype' value="1"/>  	{/* 	<!--성인확인여부 (0:성인확인불필요, 1:성인확인필요) -->*/}
          
          {/* <!-- 4. 계좌이체 현금영수증발급여부 설정 --> */}
            <input type='hidden'  name='sndCashReceipt' value="0"/>          {/* <!--계좌이체시 현금영수증 발급여부 (0: 발급안함, 1:발급) -->*/}

          {/* <!-- 5. 상품권, 게임문화상품권 관련 설정 --> */}
          <input type='hidden'  name='sndMembId' value="userid"/> {/* <!-- 가맹점사용자ID (문화,게임문화 상품권결제시 필수) --> */}
          
        {/* <!----------------------------------------------- <Part 3. 승인응답 결과데이터>  -----------------------------------------------> */}
        {/* <!-- 결과데이타: 승인이후 자동으로 채워집니다. (*변수명을 변경하지 마세요) --> */}

          <input type='hidden' name='reWHCid' 	value=""/>
          <input type='hidden' name='reWHCtype' 	value=""/>
          <input type='hidden' name='reWHHash' 	value=""/>
        {/* <!---------------------------------------------------------------------------------------------------------------------------> */}

        {/* <!--업체에서 추가하고자하는 임의의 파라미터를 입력하면 됩니다.--> */}
        {/* <!--이 파라메터들은 지정된결과 페이지(kspay_result.jsp)로 전송됩니다.--> */}
          <input type='hidden' name='a'        value="a1"/>
          <input type='hidden' name='b'        value="b1"/>
          <input type='hidden' name='c'        value="c1"/>
          <input type='hidden' name='d'        value="d1"/>



          {/* <FormItem>
            {getFieldDecorator('sndPaymethod')(
              <Input type="hidden" name="sndPaymethod" />
            )}
          </FormItem> */}

          <div className="menu-line"></div>
          <div className="container" style={{textAlign: 'center'}}>
            <div className="login-title-wrapper">
              <div className="login-title-text">
                {string.purchaseOrderProduct} 
                {!this.props.isLogin && (<span style={{paddingLeft: '10px', fontWeight:"lighter"}}>({string.nonMembers})</span>)}
              </div>
            </div>
            <div className="menu-line" />

            <div className="order-container">
            
              <div className="order-left-container">

                <div className="order-form-wrapper">
                  <div>
                    <div className="order-component-title">
                      {string.purchaseBuyerInfoMsg01}
                    </div>
                    <div className="order-component-text" style={{  }}>
                    {string.purchaseBuyerInfoMsg02}
                    </div>
                  </div>
                  <div>
                    <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/id.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem>
                          {getFieldDecorator('buyerName', {
                            rules: [{ required: true, message: string.nameRule, whitespace: true }],
                            initialValue: this.props.isLogin ? this.props.loginInfo.name : ''
                          })(
                            <Input onChange={this.onChangeName} placeholder={string.name} />
                          )}
                        </FormItem>
                      </div>
                    </div>

                    <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/call.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem>
                          {getFieldDecorator('buyerTel', {
                            rules: [{ message: string.contactRule, whitespace: true }],
                            initialValue: this.props.isLogin ? this.props.loginInfo.tel : ''
                          })(
                            <Input onChange={this.onChangeTel} placeholder={string.contact} />
                          )}
                        </FormItem>
                      </div>
                    </div>

                    <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/name.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem
                          extra={string.emailExtra}
                          style={{textAlign: 'left'}}
                          className='purchase-component-text'
                        >
                          {getFieldDecorator('buyerEmail', {
                            rules: [{
                              type: 'email', message: string.emailRule01,
                            }, {
                              message: string.emailRule02,
                            }],
                            initialValue: this.props.isLogin ? this.props.loginInfo.email : ''
                          })(
                            <Input placeholder={string.email} />
                          )}
                        </FormItem>
                      </div>
                    </div>
                    { this.props.isLogin ? 
                      <div>
                      <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/address.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem>
                          {getFieldDecorator('buyerAddress', {
                            rules: [{ required: true, message: string.addressRule, whitespace: true}],
                            initialValue: this.props.loginInfo.address
                          })(
                            <Input onKeyPress={this.onPressAddress} onClick={this.onClickFindAddress} placeholder={string.address} />
                          )}
                        </FormItem>
                      </div>
                    </div>

                    <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/address.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem>
                          {getFieldDecorator('buyerAddressDetail', {
                            rules: [{ required: true, message: string.addressDetailRule, whitespace: true}],
                            initialValue: this.props.loginInfo.addressDetail
                          })(
                            <Input onChange={this.onChangeAddressDetail} placeholder={string.addressDetail} />
                          )}
                        </FormItem>
                      </div>
                    </div>
                    </div>: null}

                    {/* {!this.props.isLogin && (
                    <div className='form-item-wrapper'>
                      <div className='input-icon'>
                        <img src={require('../img/login/password.png')} />
                      </div>
                      <div className='input-box'>
                        <FormItem>
                          {getFieldDecorator('buyerPassword', {
                            rules: [{
                              required: true, message: '비밀번호를 입력해 주세요!',
                            }, {
                              validator: this.validateToNextPassword,
                            }],
                          })(
                            <Input type="password" placeholder="비밀번호"/>
                          )}
                        </FormItem>
                      </div>
                    </div>
                    )}
                    {!this.props.isLogin && (
                    <div className='form-item-wrapper'>
                      <div className='input-icon'>
                        <img src={require('../img/login/password_confirm.png')} />
                      </div>
                      <div className='input-box'>
                        <FormItem>
                          {getFieldDecorator('confirm', {
                            rules: [{
                              required: true, message: '비밀번호를 입력해 주세요!',
                            }, {
                              validator: this.compareToFirstPassword,
                            }],
                          })(
                            <Input type="password" onBlur={this.handleConfirmBlur} placeholder="비밀번호 확인" />
                          )}
                        </FormItem>
                      </div>
                    </div>
                    )} */}
                  </div>
                </div>

                <div className="order-form-wrapper">

                  <div className="order-component-check">
                    <FormItem style={{ position: 'relative', float: 'right', textAlign: 'right', marginRight: '10px', marginTop: '-15px', marginBottom: '5px' }}>
                      <Checkbox
                        value={this.state.checkSameDeliverInfo}
                        onChange={this.handleDeliverCheckChange}
                      >
                        <span className="order-component-text" style={{ }}>{string.purchaseDeliveryInfo01}</span>
                      </Checkbox>
                    </FormItem>
                    <div style={{ clear: 'both' }} />
                  </div>

                  <div style={{ maxWidth: '500px', margin: 'auto', textAlign: 'center' }}>
                    <div className="order-component-title">
                      {string.purchaseDeliveryInfo02}
                    </div>
                    <div className="order-component-text">
                      {string.purchaseDeliveryInfo03}
                    </div>
                  </div>
                  <div>
                    <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/id.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem>
                          {getFieldDecorator('destName', {
                            rules: [{ required: !this.state.checkSameDeliverInfo, message: string.nameRule, whitespace: true }],
                          })(
                            <Input disabled={this.state.checkSameDeliverInfo} placeholder={string.name} />
                          )}
                        </FormItem>
                      </div>
                    </div>

                    <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/address.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem>
                          {getFieldDecorator('destAddress', {
                            rules: [{ required: true, message: string.addressRule, whitespace: true }],
                          })(
                            <Input disabled={this.state.checkSameDeliverInfo && this.props.isLogin} onKeyPress={this.onPressAddress} onClick={this.onClickFindAddress} placeholder={string.address} />
                          )}
                        </FormItem>
                      </div>
                    </div>

                    <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/address.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem>
                          {getFieldDecorator('destAddressDetail', {
                            rules: [{ required: true, message: string.addressDetailRule, whitespace: true }],
                          })(
                            <Input disabled={this.state.checkSameDeliverInfo && this.props.isLogin} placeholder={string.addressDetail} />
                          )}
                        </FormItem>
                      </div>
                    </div>

                    <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/call.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem>
                          {getFieldDecorator('destTel', {
                            rules: [{ required: true, message: string.contactRule }],
                          })(
                            <Input disabled={this.state.checkSameDeliverInfo} placeholder={string.contact} />
                          )}
                        </FormItem>
                      </div>
                    </div>
    
                    <div className="login-field-wrapper">
                      <div className="order-field-icon">
                        <img src={require('../img/login/name.png')} />
                      </div>
                      <div className="login-field-input">
                        <FormItem>
                          {getFieldDecorator('deliverMsg', {
                            rules: [{ required: false }]
                          })(
                            <Input placeholder={string.purchaseDeliveryInfo04} />
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="order-right-container">
                <div>

                  <div className="order-goods-title">
                    <span className="order-goods-title-bold">{this.goods.name}</span> {this.goods.nameExplain}
                  </div>

                  <div className="order-goods-text-wrapper">
                    <div className="order-goods-text-left">{string.productQuantity}</div>
                    <div className="order-goods-text-right">{this.state.products[0].quantity}{string.quantityUnit}</div>
                  </div>
                  <div className="purchase-content-line"/>
                  <div className="order-goods-text-wrapper">
                    <div className="order-goods-text-left">{string.productTotalPrice}</div>
                    <div className="order-goods-text-right order-goods-text-bold">{this.state.products[0].totalPriceString}{string.priceUnit}</div>
                  </div>
                  <div>
                    <div className="order-pay-wrapper">

                      <FormItem
                        {...formItemLayout}
                        style={{ marginBottom: '0px' }}
                      >
                        {getFieldDecorator('payType', {
                          rules: [{
                            required: true, message: string.purchasePayMsg01
                          }
                          ],
                          initialValue: this.goods.id == 1 ? '3' : '1002'
                        })(
                          <RadioGroup style={{ width: '100%' }}>
                            {/* <div style={radioWrapperStyle}><Radio style={radioStyle} value={'1'}>카카오페이 결제</Radio></div>
                            <div style={radioWrapperStyle}><Radio style={radioStyle} value={'2'}>네이버페이 결제</Radio></div> */}
                            
                            {this.goods.id == 1 && (<div className='purchase-component-radio-wrapper'><Radio className='purchase-component-radio' value={'1001'}>{string.purchasePayMsg02}</Radio></div>)}
                            {this.goods.id == 1 && (<div className='purchase-component-radio-wrapper'><Radio className='purchase-component-radio' value={'3'}>{string.purchasePayMsg03}</Radio></div>)}
                            {/* <div style={radioWrapperStyle}><Radio style={radioStyle} value={'4'}>휴대폰 소액결제 결제</Radio></div> */}
                            {this.goods.id == 1 && (<div className='purchase-component-radio-wrapper'><Radio className='purchase-component-radio' value={'5'}>{string.purchasePayMsg04}</Radio></div>)}
                            {this.goods.id == 1 && (<div className='purchase-component-radio-wrapper'><Radio className='purchase-component-radio' value={'6'}>{string.purchasePayMsg05}</Radio></div>)}
                            <div className='purchase-component-radio-wrapper'><Radio className='purchase-component-radio' value={'1002'}>{string.purchasePayMsg06}</Radio></div>
                          </RadioGroup>
                        )}
                      </FormItem>
                    </div>
                  </div>
                  {!this.props.isLogin && (
                  <div className="order-agreement-wrapper">
                    <div className="order-component-text-bold">
                      {string.purchasePolicyMsg03}
                    </div>

                    <div style={{ float: 'left' }}>
                      <FormItem
                        style={{ marginBottom: '0px'}}>
                        {getFieldDecorator('checkAgree1', {
                          rules: [{
                            required: true,
                            message: string.purchasePolicyMsg02
                          }, {
                            validator: this.validateChecked,
                          }],
                        })(
                          <Checkbox onChange={this.onChangeAgree1} className="purchase-component-text">
                            <span className="purchase-component-text">{string.purchasePolicyMsg04}</span>
                          </Checkbox>
                        )}
                      </FormItem>
                    </div>
                    <div className="purchase-component-text purchase-component-text-agreement" style={{ float: 'right' }}>
                      <a style={{ marginLeft: '8px' }} onClick={this.agreement1}>
                      {string.purchasePolicyMsg05}
                      </a>
                    </div>
                  </div>
                  )}
                  <Button className='purchase-component-btn' style={{  }} htmlType="submit">
                    {string.purchasePolicyMsg06}
                  </Button>
                </div>
              </div>
              <div style={{ clear: 'both' }} />
            </div>
          </div>
        </Form>
      </div>


    );
  }
}
PurchaseForm.defaultProps = {
  loginType: '1',
  buyerIdx: '1',
  buyerName: '홍길동',
  orderPrice: '110000',
  productIdx: '1',
  quantity: '1',
};

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo,
  };
}
let mapDispatchToProps = (dispatch) => {
  return {
    modalOpen: (title, content, buttons) => dispatch(modalOpen(title, content, buttons)),
    modalClose: () => dispatch(modalClose()),
    loadingOpen: () => dispatch(loadingOpen()),
    loadingClose: () => dispatch(loadingClose()),
  }
}

let Purchase = Form.create()(PurchaseForm);
Purchase = connect(mapStateToProps, mapDispatchToProps)(Purchase);
export default Purchase;