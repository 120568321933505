import { Form, Input, Tooltip, Icon, Cascader, Select, Row, Col, Checkbox, Button, AutoComplete } from 'antd';
import React from 'react';
import queryString from 'query-string';
import AgreementSub1 from './AgreementSub1';
import AgreementSub2 from './AgreementSub2';
import AgreementSub3 from './AgreementSub3';
import string from '../String';
class Agreement extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let query = queryString.parse(this.props.location.search);
    const title = [
      '',
      string.agreement,
      string.agreementTitle01,
      string.agreementTitle02,
    ]
    return (
      <div className="">

        <div className="blobs-title-wrapper mt-80">
          <div className="blobs-title-text" >
            {string.blobs}
        </div>
          <div className="blobs-title-text" style={{ fontWeight: "lighter", paddingLeft: '20px' }}>
            {title[query.t]}
        </div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="title-divider mt-35" />
        <div style={{ padding: "10px 20px 80px 20px" }}>
          {query.t == 1 && (<AgreementSub1/>)}
          {query.t == 2 && (<AgreementSub2/>)}
          {query.t == 3 && (<AgreementSub3/>)}
        </div>
      </div>
    );
  }
}
export default Agreement;