import React, { Component } from 'react';
import Header from '../components/Header';
import { Table, Input, Button, Icon, Breadcrumb } from 'antd';
import string from '../String';
import { Link, withRouter } from "react-router-dom";
import Support from './sub/Support';
import httpClient from '../api/httpclient';
export default class FAQ extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  }

  fetch = (params = {}) => {
    console.log('params:', params);
    this.setState({ loading: true });

    let page = params.page || 1;

    httpClient.httpRequest(
      httpClient.target.faq,
      [10,page]
    ).then((result) => {
      console.log('result:', JSON.stringify(result));
      const pagination = { ...this.state.pagination };
      pagination.total = result.totalCount;
      this.setState({
        loading: false,
        data: result.data.map(value=>{ 
          value['key'] = value.idx;
          console.log('content='+value.content)
          value.content = value.content || "";
          value.content = value.content.split('\n').map(line => {
            return (<span>{line}<br/></span>)
          });
          return value;
        }),
        pagination,
      });
    }).catch(e => {
      this.setState({ loading: false });
    });
  }

  componentDidMount() {
    this.fetch();
  }
  render() {
    const columns = [{
      title: string.support.faqTableCol01,
      dataIndex: 'idx',
      key: 'idx',
      className: 'support-column-number desk'
    }, {
      title: string.support.faqTableCol02,
      dataIndex: 'title',
      key: 'title',
      className: 'support-column-title',
      render: (text, record) => <div>
        <div style={{ float: "left", paddingTop: "5px", textIndent: '-30px', marginLeft:'30px' }}>
          <img className="q-img-size"src={require('../img/support/q.png')} style={{marginRight: '10px'}}/>
          {text}
        </div>
      </div>,
    }, {
      title: string.support.faqTableCol03,
      dataIndex: 'createDateString',
      key: 'createDateString',
      className: 'support-column desk'
    }];
    return (
      <div className="">
        <Support/>
        <div className="container-support" style={{marginBottom: '50px'}}>
          <div className="support-sub-title">
          {string.support.categoryFaq}
          </div>
          <div className="support-table-top-line"></div>
          <Table id="faq"
            expandRowByClick="true" 
            columns={columns}
            expandedRowRender={record => <p style={{ margin: 0 }}>{record.content}</p>}
            rowKey={record => record.key}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}
