import React, { Component } from 'react';
import Header from '../components/Header';
import string from '../String';
import { Link, withRouter } from "react-router-dom";
import Support from './sub/Support';
export default class Contact extends Component {

  render() {
    return (
      <div className="">
        <Support/>
        문의
      </div>
    );
  }
}
