export default {
  serverProtocol: 'https',
  serverIp: 'blobs.kr',
  serverPort: '7001',
  serverContext: 'home',
  // serverProtocol: 'http',
  // serverIp: 'localhost',
  // serverPort: '7001',
  // serverContext: 'home',


  homeFrontUrl: 'https://card.blobs.co.kr',

  billingRcvUrl: 'https://card.blobs.kr:7001/home/billing/kspayRcv',
  billingRcvMobileUrl: 'https://card.blobs.kr:7001/home/billing/kspayRcvMobile'
};
