import React, { Component } from 'react';
import Header from '../components/Header';
import string from '../String';
import PurchaseSummary from './sub/PurchaseSummary';
export default class EthereumCardPurchase extends Component {
  constructor(props){
    super(props)

    this.goods = {
      images: [
        require('../img/ethereumcard/purchase01.png'),
        require('../img/ethereumcard/purchase02.png'),
        require('../img/ethereumcard/purchase03.png'),
      ],
      id: 2,
      name: string.ethereumcard.purchaseTitle,
      nameEng: string.ethereumcard.purchaseTitleEng,
      nameExplain: string.ethereumcard.purchaseTitleExplain,
      price: 330000,
      detail01: string.ethereumcard.purchaseDetail01,
      detail02: string.ethereumcard.purchaseDetail02,
      detail03: string.ethereumcard.purchaseDetail03,
      detail04: string.ethereumcard.purchaseDetail04,
    }
  }
  
  render() {
    return (
      <div className="">
        <div className="menu-line"></div>
        <div className="container">
          <div className="breadcrumb">
            Home <span className="breadcrumb-divider">></span> <span className="pointer" onClick={()=>{global.location.href='/ethereumcard'}}>{string.common.menu02}</span> <span className="breadcrumb-divider">></span> <span className="breadcrumb-current">{string.common.submenu02}</span>
          </div>
        </div>
        <div className="container">
          <PurchaseSummary goods={this.goods}/>
        </div>
        <div style={{clear: 'both'}}></div>
        <div className="container">
          <div className="ethereumcard-purchase-content01">
            <div className="ethereumcard-purchase-content01-title">{string.ethereumcard.purchaseContent01Title}</div>
            <div className="ethereumcard-purchase-content01-explain">{string.ethereumcard.purchaseContent01Explain01}</div>
            <div className="ethereumcard-purchase-content01-explain">{string.ethereumcard.purchaseContent01Explain02}</div>
            <div className="ethereumcard-purchase-content01-box-wrapper">
              <div className="ethereumcard-purchase-content01-box">
                <div className="ethereumcard-purchase-content01-box-number">01</div>
                <div className="ethereumcard-purchase-content01-box-title">{string.ethereumcard.purchaseContent01Box01Title}</div>
                <div className="ethereumcard-purchase-content01-box-detail">{string.ethereumcard.purchaseContent01Box01Explain}</div>
              </div>
              <div className="ethereumcard-purchase-content01-box">
                <div className="ethereumcard-purchase-content01-box-number">02</div>
                <div className="ethereumcard-purchase-content01-box-title">{string.ethereumcard.purchaseContent01Box02Title}</div>
                <div className="ethereumcard-purchase-content01-box-detail">{string.ethereumcard.purchaseContent01Box02Explain}</div>
              </div>
              <div className="ethereumcard-purchase-content01-box">
                <div className="ethereumcard-purchase-content01-box-number">03</div>
                <div className="ethereumcard-purchase-content01-box-title">{string.ethereumcard.purchaseContent01Box03Title}</div>
                <div className="ethereumcard-purchase-content01-box-detail">{string.ethereumcard.purchaseContent01Box03Explain}</div>
              </div>
              <div className="ethereumcard-purchase-content01-box">
                <div className="ethereumcard-purchase-content01-box-number">04</div>
                <div className="ethereumcard-purchase-content01-box-title">{string.ethereumcard.purchaseContent01Box04Title}</div>
                <div className="ethereumcard-purchase-content01-box-detail">{string.ethereumcard.purchaseContent01Box04Explain}</div>
              </div>
            </div>
          </div>
          <div className="ethereumcard-purchase-slash">
            <div className="ethereumcard-purchase-content02-title">Detail</div>
          </div>
          <div className="ethereumcard-purchase-content02-content-wrapper">
            <div className="ethereumcard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/ethereumcard/purchase_content02_icon01.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.ethereumcard.purchaseContent02Explain01}}>
              </div>
            </div>
            <div className="ethereumcard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/ethereumcard/purchase_content02_icon02.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.ethereumcard.purchaseContent02Explain02}}>
              </div>
            </div>
            <div className="ethereumcard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/ethereumcard/purchase_content02_icon03.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.ethereumcard.purchaseContent02Explain03}}>
              </div>
            </div>
            <div className="ethereumcard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/ethereumcard/purchase_content02_icon04.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.ethereumcard.purchaseContent02Explain04}}>
              </div>
            </div>
            <div className="ethereumcard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/ethereumcard/purchase_content02_icon05.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.ethereumcard.purchaseContent02Explain05}}>
              </div>
            </div>
            <div className="ethereumcard-purchase-content02-content">
              <div className="ethereumcard-purchase-content02-content-item01">
                <img src={require('../img/ethereumcard/purchase_content02_icon06.png')}/>
              </div>
              <div className="ethereumcard-purchase-content02-content-item02" 
                dangerouslySetInnerHTML={{__html: string.ethereumcard.purchaseContent02Explain06}}>
              </div>
            </div>
          </div>
          <div className="ethereumcard-purchase-slash" style={{height: '20px'}}>
          </div>
          <div className='ethereumcard-purchase-content03-wrapper'>
            <div className="support-sub-title">{string.ethereumcard.purchaseContent03Title}</div>
            <div className='ethereumcard-purchase-content03'>
              <div className='ethereumcard-purchase-content03-item'>
                <div><img src={require('../img/ethereumcard/purchase_content03_image01.png')}/></div>
                <div>{string.ethereumcard.purchaseContent03Explain01}</div>
              </div>
              <div className='ethereumcard-purchase-content03-item'>
                <div><img src={require('../img/ethereumcard/purchase_content03_image02.png')}/></div>
                <div>{string.ethereumcard.purchaseContent03Explain02}</div>
              </div>
            </div>

          </div>
          <div className='ethereumcard-purchase-content04-wrapper'>
            <div className="support-sub-title">{string.ethereumcard.purchaseContent04}</div>
            <div className='ethereumcard-purchase-content04-container'>
              <div className='ethereumcard-purchase-content04-item'><img src={require('../img/ethereumcard/purchase_content04_image.png')}/></div>
              <div className='ethereumcard-purchase-content04-item'>
                <li>{string.ethereumcard.purchaseContent04Explain01}</li>
                <div>{string.ethereumcard.purchaseContent04Explain01Detail01}</div>
                <div>{string.ethereumcard.purchaseContent04Explain01Detail02}</div>
                <li>{string.ethereumcard.purchaseContent04Explain02}</li>
                <div>{string.ethereumcard.purchaseContent04Explain02Detail01}</div>
                <div>{string.ethereumcard.purchaseContent04Explain02Detail02}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
